/**
 * Common evenue functions
 */
(function() {
	var DATA_TYPE = "dataGroup";
	var ADDRESS_BOOK_DATA = "A";
	var PHONE_BOOK_DATA = "P";
	var STORED_CC_DATA = "C";
	var STORED_BA_DATA = "B";
	var TKT_DON_DATA = "T";
	var RLTD_SITES_DATA = "R";
	var HTTP_GET = "GET";
	var HTTP_POST = "POST";
	var HTTP_PUT = "PUT";
	var HTTP_DELETE = "DELETE";
	var PAYMENT_ADD_MODE = "A";
	var PAYMENT_EDIT_MODE = "U";
	var HISTORY_SERVICES_URL = et.rootCtx + '/ws/history/';
	var DONATION_HISTORY_SERVICE_URL = '/donationhistory';
	var ORDER_HISTORY_SERVICE_URL = '/orderhistory';
	var LOAD_MORE_ORDER_HISTORY = '/loadMoreHistory';
	var USER_SERVICE_URL = et.rootCtx + '/rs/contexts';
	var PAC7_USER_SERVICE_URL = et.rootCtx + '/ws/contexts';
	var GUEST_SESSION_URL = et.rootCtx + '/rs/contexts/?action=guest';
	var PATRON_SERVICES_URL = et.rootCtx + "/ws/patron/";
	var ADDITIONAL_PATRON_DATA_SERVICE_URL = "/ptrnAddtnlData";
	var ORG_CHANN_SETTINGS_URL = et.rootCtx + '/rs/v1.0/client/clients/{clientId}/organizations/{organizationId}/channels/{channelId}/channelsettings/';
	var CHANNEL_RS = et.rootCtx + '/rs/channels/';
	var PATH_VALID_ACCESSING_SITE = et.rootCtx + '/rs/channels/{channelId}/?view=site';
	var PATH_GET_CONSUMER_TERMS_AND_CONDITIONS = et.rootCtx + '/rs/common/termsandconditions';
	var TOUCHPOINT_URL = et.rootCtx + '/rs/touchpoints';
	var m_payment_id = -1;

	var PAYMODE_CD = "paymodeCd";
	var MODAL_DLG_REGION = "#modal-dialog-region";
	var TRANSFER_DT = "transferDt";
	var TRANSFER_TO = "transferTo";
	var TRANSFER_PAID_BY = "transferFeePaidBy";
	var m_cvvContent;

	//overriding the defaults to show error for the input group rather than the input field when it apply
	$.validator.setDefaults({
		highlight : function(element) {
			$(element).parent().addClass('has-error');
		},
		unhighlight : function(element) {
			$(element).parent().removeClass('has-error');
		},
		errorPlacement : function(error, element) {
			if (element.parent('.input-group').length) {
				error.insertAfter(element.parent());
			} else {
				error.insertAfter(element);
			}
		}
	});

	String.Empty = "";
	et.evenue = {};
	et.evenue.apiVersion = "v1.0";
	et.evenue.apiBaseUri = et.rootCtx + "/rs/";
	et.evenue.imagesBaseUri = "common/images/";

	et.evenue.catalogPath = "/catalog";
	et.evenue.pricingPath = "/pricing";

	var BASE_CLIENT_URL = et.evenue.apiBaseUri + et.evenue.apiVersion + '/client/clients/{clientId}';
	var BASE_ORGANIZATION_URL = et.evenue.apiBaseUri + "/organizations/";

	var PATH_ACCOUNT = "accounts";
	var PATH_PERSON = "persons";
	var PATH_RLTN = "rltn";
	var PATH_DONOR = "/donor";

	et.evenue.urls = {
		PATH_PAYCODES : 'paycodes',
		PATH_POLICIES : 'policies',
		PATH_ACCOUNT : PATH_ACCOUNT,
		PATH_PERSON : PATH_PERSON,
		BASE_DONOR_URL : et.evenue.apiBaseUri + PATH_ACCOUNT + '/{accountId}' + PATH_DONOR,
		PATH_RLTN : PATH_RLTN,
		BASE_ACCOUNT_URL : et.evenue.apiBaseUri + PATH_ACCOUNT,
		BASE_PERSON_URL : et.evenue.apiBaseUri + PATH_ACCOUNT + '/' + PATH_PERSON,
		TRANSACTION_BASE_URL : et.evenue.apiBaseUri + "transactions/",
		CART_BASE_URL : et.evenue.apiBaseUri + "carts/"
	};

	et.evenue.resourceNames = {
		QUICKDONATE : "consumerDonationPages",
		ALLOCS : "allocations",
		CONSUMER_TRANS_DONATION_URL : "consumerDonation",
		SEARCH_TRANS : "search",
		CONSUMER_DONATION_PAGES : "consumerDonationPagesByChannel",
		CALC_PAYMENT_SCHEDULE : "calculatePaymentSchedule",
		CONSUMER_ALLOCS_MMD_URL : "allocations?path=MMD",
		CONSUMER_ALLOCS_DH_URL: "allocations?PATH=DH",
		SAVE_PAYMENT_SCHEDULE : "paymentSchedules",
		GET_PAYMENT_SCHEDULE : "paymentSchedule",
		PAYMENT_SCHEDULE_PAY_DOWN : "paymentSchedule/payDown",
		PAYMENT_SCHEDULE_LOAD :"loadPaySchedRegion"
	};

	et.evenue.namespaces = {
		TRANS_ORG : "transactions",
		PAYMENT_ORG : "payment/organizations"
	};

	et.evenue.forwards = {
		MYACCOUNT : "myaccount",
		CREATEACCOUNT : "createAccount",
		CREATEGUEST : "createGuest",
		FORGOTPASSWORD : "forgotPassword"

	};

	var BASE_COMMON_URL = et.evenue.apiBaseUri + 'common';
	var BASE_CONSUMER_LOGIN = et.rootCtx + "/rs/contexts?action=loginConsumer";
	var BASE_OAUTH_ACCOUNT = et.rootCtx + "/rs/account/{dbId}/oauth/channel/{channelId}/user/{userName}/account";

	var BASE_ACCOUNT_CHANNEL = et.rootCtx + '/rs/acctchnattrs/{accountId}';
	var BASE_CONSUMER_CHANNEL = et.rootCtx + "/rs/" + et.evenue.apiVersion + "/client/clients/{clientId}/channels/";

	/**
	 *eVenue constants, for global constants which would be used by other products as well please use et.constants in www/common/script/common.js
	 */
	et.evenue.constants = {
		CREATE : "CREATE",
		ACCT_INFO_FUND_MODE : "ACCT_INFO_FUND_MODE",
		ACCT_INFO_TKT_MODE : "ACCT_INFO_TKT_MODE",
		MINLENGTH_CVN : 3,
		MAXLENGTH_CVN : 4,
		SYMBOL : "S",
		DEFAULT_CURRENCY : "USD",
		/* TODO: have to be change in the future */
		GIFT_CARD_CODE : "EGV",
		PAYMENT_TYPE_CODE : "PAY.TY",
		EVENUE : "evenue",
		NO : "0",
		YES : "1",
		OPTIONAL : "2",
		OPTION : '<option>',
		INDIVIDUAL_ACC_TYPE : "I",
		GUEST_ACC_TYPE : "G",
		ORGANIZATION_ACC_TYPE : "O",
		ACCT_ALL : "ALL",
		ACCT_INFO : "AI",
		ACCT_EMAIL : "EMAIL",
		ACCT_PREFERRED_EMAIL : "PRFEMAIL",
		ACCT_ORG_INFO : "ORG",
		ACCT_PHONE : "PHONE",
		ACCT_ADDR : "ADDR",
		ACCT_ADDRESS : "ADDRESS",
		ACCT_ADDR_IDX : "ADDRIDX",
		EMAIL_PREFERENCES : "EPREF",
		CREDITCARD : "CC",
		PAYMENT_METHOD_MASK: "xxxx",
		OPTIN : "OPTIN",
		ACCT_BANK_ACCOUNT : "BA",
		ACCT_PREFERRED_PHONE : "PRFPHONE",
		PATH_MY_ACCT : "MA", //My Account path
		PATH_TRANSFER : "TR", //Transfers path
		TYPE : "type", //Field name
		SUB_TYPE : "subType",
		ID : "id", //Field name
		SITE_ID : 'siteId',
		ORG_CHANNEL : "ORG_CHANNEL",
		KEY : 'key',
		APP_PATH : 'app_path',
		QUERY_VIEW : "s_view",
		SEARCH_KEYWORD : "s_search", //Search query param
		START_TIMESTAMP : "start_timestamp",
		SEARCH_DATE : "event_date", //Search date param
		ERROR_CD : "errorCd", //Query parameter
		ERROR_MSG : "errorMsg", //Query parameter
		PATRON_URL : et.rootCtx + '/ws/patron/', //Patron service base url
		PAYMENT_SERVICE_URL : et.rootCtx + "/ws/payments/",
		CART_URL : et.rootCtx + "/ws/carts/timer",
		LOGOUT_URL : et.rootCtx + "/ws/authentication/logout",
		LOGIN_URL : et.rootCtx + "/ws/authentication/login",
		SSO_LOCAL_LOGOUT_URL : "/Shibboleth.sso/Logout",
		QUICK_DONATION_URL : "/fund/drives",
		QUICK_DONATION_DETAILS_URL : "/fund/details",
		QUICK_DONATION_POST_URL : "/fund/donation",
		SERVICE_PASSWORD_POLICY_RESOURCE_URL: et.rootCtx + '/rs/passwordPolicies',
		SERVICE_OAUTH_ACCOUNT_URL: et.rootCtx + '/rs/account/{dbId}/oauth/resetPassword',
		IAC_CTRL_SETTINGS_URL: et.rootCtx + '/rs/controlsettings/{type}/{channelId}/{orgId}',
		CONSUMER_DONATIONS_URL: "accounts/{accountId}/donor/donationdetails",
		CONSUMER_DONORGIFT_URL: "donorgifts/{accountId}",
		TB_LABEL_TYPE : "TB",
		TS_LABEL_TYPE : "TS",
		FB_LABEL_TYPE : "FB",
		FS_LABEL_TYPE : "FS",
		PHONE : "PHONE",
		ANIMATE_500 : 500,
		ANIMATE_200 : 200,
		OPACITY_50 : 0.5,
		OPACITY_100 : 1,
		PHONE1_LABEL_TYPE : "P1",
		PHONE2_LABEL_TYPE : "P2",
		PHONE3_LABEL_TYPE : "P3",
		PHONE4_LABEL_TYPE : "P4",
		PHONE5_LABEL_TYPE : "P5",
		PHONE6_LABEL_TYPE : "P6",
		MODE_UPDATE : "U",
		CHECKING : "C",
		SAVING : "S",
		COUNTRY_US : "US",
		SUCCESS : "success",
		CODE : 'CODE',
		ADDR : "ADDR",
		PATRON : "P",
		REGION: 'REGION',
		LANGUAGE : "LANGUAGE",
		PERSON_PI : "PI",
		PERSON_CNSMR_BASIC : "CNSMR_BASIC",
		VALUE : "value",

		//Additional Patron Data - Data Group constants
		DATAGRP_PRI_PTRN : "pp",
		DATAGRP_SEC_PTRN : "sp",
		DATAGRP_REL_PTRN : "rp",
		DATAGRP_ATTR : "ar",
		DATAGRP_INTRST : "it",
		DATAGRP_INSTITUTION : "is",
		DATAGRP_PTRN_UDEF : "pu",
		DATAGRP_CUST_UDEF : "cu",
		DATAGRP_DONR_UDEF : "du",
		DATAGRP_TCKT : "tk",
		DATAGRP_DEV : "dv",

		//Char values for the screen type, corresponds to constants of the same name in PatronConstants
		//Used to uniquely identify the correct UDEF to update
		PATRON_UDEF : 80,
		DONOR_UDEF : 68,
		CUSTOMER_UDEF : 67,
		//constants for Post Sale
		EXCHANGE : "E",
		TRANSFER : "T",
		RETURN : "R",
		REISSUE : "P",
		CANCEL_TRANSFER : "C",
		PRIORITY_PTS : "PP",
		PARENT_ITEM_GROUP : "parent",
		CHILD_ITEM_GROUP : "children",
		GA : "G",
		SECGA : "S",
		SEQ : 'sequence',
		CREDIT_CARD_LIST : {
			M : "MasterCard",
			V : "Visa",
			A : "American Express",
			C : "Diners Club",
			D : "Discover",
			J : "JCB",
			U : "China Union Pay"
		},
		ORDERS : "ORDERS",
		DFLT_SORT_ORDER : "getOrderDt",

		FUND_NAME_SPACE : "fundraising",
		DON_DC : "donationCd",
		ORG_ID : "orgId",
		CHANNEL_ID : "channelId",
		SEASON_ID : "seasonId",
		NO_SPACES_REGEX : /^\S*$/,
		ENTITY : "ENTITY",
		COLON_DELIMITER : ":",
		STORE_CC_FAILED : "-1",
		UPDATE_CC_FAILED : "-2",
		SORT_ORDER : {
			ASC : "asc",
			DESC : "desc"
		},
		VAR_THING : 'THING',
		PAYMENT_SCHEDULE_TYPE_PAYMENT : "P",
		PAYMENT_SCHEDULE_DELIVERY_METHOD_EMAIL : "E",
		PAY_SCHED_PAYMENT_STATUS_COMPLETE : 0,
		PAY_SCHED_PAYMENT_STATUS_PENDING : 1,
		PAY_SCHED_PAYMENT_STATUS_DECLINED : 2,
		AUTH_INFO_SALT : "CHANNEL##",
		NUMBER_OF_PAYMENTS : "numberOfPayments",
		PAYMENT_FREQUENCY : "paymentFrequency",
		PAYMENT_SCHEDULE : "paymentSchedule",
		MEDIA_MANAGE : et.rootCtx + '/rs/common/fileUpload'
	};

	var loadingModalCounter = 0;

	/**
	 * View mode for query params
	 */
	et.evenue.constants.viewMode = {
		detail : "detail",
		summary : "summary"
	};

	et.evenue.constants.TICKETING = {
		PROMO_ID : "promo_id"
	};

	et.evenue.constants.FUND = {
		context : "F",
		TRANS_TYPE_STD : "S"
	};

	/*
	 * Status constants that are used for values in modelStatus (s_stat)
	 */
	et.evenue.constants.STATUS = {
		INACTIVE : 0,
		ACTIVE : 1,
		PURGING : 2
	};

	et.evenue.constants.CVNOPTIONS = {
		REQUIRED : "R",
		HIDDEN : "H",
		OPTIONAL : "O"
	};

	et.evenue.constants.paycode = {
		STATUS : {
			ACTIVE : "active",
			INACTIVE : "inactive"
		},
		TOGGLE : {
			YES : "yes",
			NO : "no"
		}
	};

	et.evenue.constants.IAC_SETTING = {
		CONSUMER_DONATIONS : "CONSUMERDONATIONSETTINGS",
		CONSUMER_DONATIONS_MGMMYDONATIONS : "CONSUMERDONATIONMGMTMYDONATIONS",
		CONSUMER_DONATIONS_MGMMYDONATIONS_COMMENTS : "CONSUMERDONATIONMGMTMYDONATIONSCOMMENTS",
		CONSUMER_DONATIONS_MGMMYDONATIONS_DESCR : "CONSUMERDONATIONMGMTMYDONATIONSDESCR",
		CONSUMER_CREATE_PAY_SCHED : "CONSUMERDONATIONCREATEPAYMENTSCHEDULE",
		CONSUMER_CREATE_PAY_SCHED_DESCR : "CONSUMERDONATIONCREATEPAYMENTSCHEDULEDESCR",
		CONSUMER_DONATIONS_PAYMENT_OPTIONS_PLEDGE_EQUAL_DONATION: "CONSUMER_DONATIONS_PAYMENT_OPTIONS_PLEDGE_EQUAL_DONATION",
		CONSUMER_ALLOCATIONS_NEW_GIFTS: "CONSUMERALLOCATIONS_NEW_GIFTS",
		CONSUMER_ALLOCATIONS_PAYMENT_SCHEDULES: "CONSUMERALLOCATIONS_PAYMENT_SCHEDULES",
		CONSUMER_MGMT_MY_DONATIONS_NEW_GIFT_FLOW: "CONSUMER_DONATIONS_MMD_NEWGIFTNAVIGATE",
		CONSUMER_DONATIONS_ALLOW_GUEST_ACCOUNT: "CONSUMER_DONATIONS_ALLOW_GUEST_ACCOUNT",
		CONSUMER_PRIORITY_POINTS_LIVE: "CONSUMER_PRIORITY_POINTS_LIVE",
		CONSUMER_PRIORITY_POINTS_TOTAL: "CONSUMER_PRIORITY_POINTS_TOTAL"
	};

    et.evenue.constants.DIGITAL_DELIVERY_GROUPING_TYPE = {
        GROUP: "G",
        TICKET: "T"
    };

    et.evenue.constants.TRANSACTION_SOURCE = {
        SPECIAL_EVENT: "SE",
    	QUICK_DONATION : "QD",
    	CONFIRM_MY_DONATIONS : "CD",
    	PAYMENT_SCHEDULE: "PS"
    };

	et.evenue.constants.TRANSACTION_SOURCE = {
		SPECIAL_EVENT : "SE",
		QUICK_DONATION : "QD",
		CONFIRM_MY_DONATIONS : "CD"
	};

	et.evenue.constants.PAYMENT_SCHEDULE_SOURCE = {
		CONSUMER : "PS_CONSUMER"
	};
	
	
	 et.evenue.constants.touchpointStatus = {
        NEW: 'N',
        OPEN: 'O',
        CLOSED: 'C',
        WAITING: 'W',
        DEFERRED: 'D'
    };

	/**
	 * Determines the correct CVN option flag given consumer payment details.
	 *
	 * @param {Object} paymentDetails
	 *
	 * @author Dennis Trinh
	 * @since 8.0.0
	 */
	et.evenue.determineRequireCvnOption = function(requireCvn) {
		return (requireCvn) ? et.evenue.constants.CVNOPTIONS.REQUIRED : et.evenue.constants.CVNOPTIONS.OPTIONAL;
	};

	/**
	 * Convert from Payment credit card model to a Patron/Account credit card
	 * model to be stored.
	 *
	 * @param {Object} paymentCreditCard - Payment credit card.
	 * @return {Object} accountCreditCard - Account credit card.
	 *
	 * @author Dennis Trinh
	 * @since 8.0
	 */
	et.evenue.convertPaymentCreditCardToAccountCreditCard = function(paymentCreditCard) {
		if (paymentCreditCard) {
			var accountCreditCard = {
				expMonth : paymentCreditCard.expirationDateMonth,
				expYear : paymentCreditCard.expirationDateYear,
				nameOnCard : paymentCreditCard.name,
				nickName : paymentCreditCard.storeCreditCard.nickName,
				number : paymentCreditCard.cardNumber,
				preferredFl : paymentCreditCard.storeCreditCard.preferredPaymentMethod,
				type : paymentCreditCard.cardType,
				token : paymentCreditCard.token
			};
			return accountCreditCard;
		}
		return null;
	};
	
	
	et.evenue.convertPaymentECheckToAccountECheck= function(eCheck) {
		if (eCheck) {
			var accountECheck = {
				accountNumber : eCheck.accountNumber,
				firstName : eCheck.firstName,
				lastName : eCheck.lastName,
				routingNumber : eCheck.routingNumber,
				type : eCheck.type,
				preferredFl : eCheck.preferredFl,
				nickName : eCheck.nickName || "",
				token : eCheck.token,
				instName : eCheck.instName || ""
			};
			return accountECheck;
		}
		return null;
	};

	/**
	 * Function to wait for predicates.
	 * @param {function() : Boolean} predicate - A function that returns a bool
	 * @param {Number} [timeout] - Optional maximum waiting time in ms after rejected
	 */
	et.evenue.waitForGlobalVar = function(predicate, timeout) {
		return new Promise(function (resolve, reject)  {
			const check = function() {
			if (!predicate()) return;
			clearInterval(interval);
			resolve();
			};
			const interval = setInterval(check, 100);
			check();
		
			if (!timeout) return;
			setTimeout(function (){
			clearInterval(interval);
			reject();
			}, timeout);
		});
	};

	et.evenue.getValueForObjectString = function(obj, key) {
		return key.split(".").reduce(function(o, x) {
			return (typeof o == "undefined" || o === null) ? o : o[x];
		}, obj);
	};


	et.evenue.dataLayerLoadedPredicate = function() {
		return window['dataLayer'].filter(
			function(obj) {
				var found = false;
				var prop;
				
				for (prop in {"event": "data_layer_loaded"}) {
					if (obj[prop] == {"event": "data_layer_loaded"}[prop] || et.evenue.getValueForObjectString(obj, prop) == {"event": "data_layer_loaded"}[prop]) {
						found = true;
					}
				}
			return found;
		}).length > 0;
	};

	
	et.evenue.convertStoreCreditCardToRegionModel = function(ccModel) {
		var cc = {
			cardNumber : ccModel.number,
			cardType : ccModel.type,
			expirationDateMonth : ccModel.expMonth,
			expirationDateYear : ccModel.expYear,
			name : ccModel.nameOnCard,
			storeCreditCard : {
				active : true,
				preferredPaymentMethod : ccModel.preferredFl,
				nickName : ccModel.nickName
			},
			token : ccModel.token
		};
		if (ccModel.securityCode) {
			cc.securityCode = ccModel.securityCode;
		}
		return cc;
	};
	et.evenue.storedCreditCardInfo = function(creditcard, creditCardNameMap) {
		var creditCardInfoHeader = function(creditcard) {
			var creditCardIcon = function(creditcard) {
				return et.evenue.getCreditCardImage(creditcard.type);
			};
			return "<div class='col-sm-3'>" + creditCardIcon(creditcard) + "</div>";
		};
		var creditCardInfoBody = function(creditcard, creditCardNameMap) {
			var htmlPreferredPaymentMethod = function(creditcard) {
				var html = "";
				if (creditcard.preferredFl) {
					html += "<div><span class='badge-primary'>" + i18n.preferred_payment_method + "</span></div>";
				}
				return html;
			};
			var htmlCreditCardInfo = function(creditcard, creditCardNameMap) {
				var html = "<div class='text-major'>";
				if (creditcard.nickName) {
					html += creditcard.nickName;
				} else {
					html += creditCardNameMap[creditcard.type];
				}
				html += " - " + et.evenue.maskAccountNumber(creditcard.number) + "</div>";
				return html;
			};
			var htmlExpireDate = function(creditcard) {
				if (!creditcard.originalDate) {
					creditcard.originalDate = {
						expMonth : creditcard.expMonth,
						expYear : creditcard.expYear
					};
				}
				var isCCExpired = et.evenue.isCCExpired(creditcard.originalDate.expMonth, creditcard.originalDate.expYear);
				var html = "<div" + ((isCCExpired) ? " class='text-alert' " : "") + ">";
				if (isCCExpired) {
					html += i18n.expired + " " + i18n.lbl_on;
				} else {
					html += i18n.expires;
				}
				html += " " + creditcard.originalDate.expMonth + "/" + creditcard.originalDate.expYear + "</div>";
				return html;
			};
			var htmlNameOnCard = function(creditcard) {
				return "<div>" + creditcard.nameOnCard + "</div>";
			};
			return "<div class='col-sm-9'>" + htmlPreferredPaymentMethod(creditcard) + htmlCreditCardInfo(creditcard, creditCardNameMap) + htmlExpireDate(creditcard) + htmlNameOnCard(creditcard) + "</div>";
		};
		var html = creditCardInfoHeader(creditcard);
		html += creditCardInfoBody(creditcard, creditCardNameMap);
		return html;
	};
	et.evenue.storedBankAccountInfo = function(bankaccount) {
		var bankAccountInfoHeader = function(bankaccount) {
			var bankAccountIcon = function(bankaccount) {
				return '<span class="fa fa-university" style="font-size:40px"></span>';
			};
			return "<div class='col-sm-3'>" + bankAccountIcon(bankaccount) + "</div>";
		};
		var bankAccountInfoBody = function(bankaccount) {
			var htmlPreferredPaymentMethod = function(bankaccount) {
				var html = "";
				if (bankaccount.preferredFl) {
					html += "<div><span class='badge-primary'>" + i18n.preferred_payment_method + "</span></div>";
				}
				return html;
			};
			var htmlBankAccountInfo = function(bankaccount) {
				var html = "<div class='text-major'>";
				if (bankaccount.nickName) {
					html += bankaccount.nickName;
				} else {
					if (bankaccount.type === et.evenue.constants.SAVING) {
						html += i18n.option_savings;
					} else if (bankaccount.type === et.evenue.constants.CHECKING) {
						html += i18n.option_checking;
					}
				}
				html += " - " + et.evenue.maskAccountNumber(bankaccount.accountNumber) + "</div>";
				return html;
			};
			var htmlFormatName = function(bankaccount) {
				var html = "";
				if (bankaccount.firstName) {
					html += bankaccount.firstName;
				}
				if (bankaccount.lastName) {
					if (html) {
						html += " ";
					}
					html += bankaccount.lastName;
				}
				return "<div>" + html + "</div>";
			};
			var htmlInstName = function(bankaccount) {
				return "<div>" + bankaccount.instName + "</div>";
			};
			return "<div class='col-sm-9'>" + htmlPreferredPaymentMethod(bankaccount) + htmlBankAccountInfo(bankaccount) + htmlFormatName(bankaccount) + htmlInstName(bankaccount) + "</div>";
		};
		var html = bankAccountInfoHeader(bankaccount);
		html += bankAccountInfoBody(bankaccount);
		return html;
	};

	et.evenue.processPaymentMethod = function(paymentMethod, method, updateType, account) {

		var deferred = $.Deferred();
		//Set path based on updateType
		var pmtMthdType = et.evenue.constants.CREDITCARD == updateType ? 'creditcards' : 'bankaccounts';
		var url = et.evenue.urls.BASE_ACCOUNT_URL + '/' + account.key.id + "/" + pmtMthdType;
		//Modify url path based on service method
		url += method == et.constants.HTTP_DELETE ? '/' + paymentMethod.token : '';
		var svr = {
			url : url,
			type : method,
			data : method == et.constants.HTTP_DELETE ? '' : paymentMethod
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				et.cache.account = null;
				deferred.resolve(result.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	/* BEGIN ERROR PAGE CODE **************************************************************************************** */

	/*
	 * Display any js error, to disable this feature turn et.evenue.debugCode to false
	 */
	et.evenue.debugCode = true;
	et.evenue.errorPageHandler = function() {
		if (et.evenue.debugCode === true) {
			window.onerror = function(error, url, line) {
				var fileUrl = url.split("/"), fileName = fileUrl[fileUrl.length - 1].split(".js")[0];
				et.evenue.displayErrors(fileName + ".js " + i18n.line + " " + line + ": " + error, true);
			};
		}
	};

	/*
	 * validate if the given data, or pieces of it exist
	 * @param {String} additional msg to show on the error panel, eg: file, line, function name, etc
	 * @param {Object} data to be checked (required)
	 * @param {Array} fields to check in the object (Optional)
	 */
	et.evenue.validateDataIntegrity = function(msg, data, fields) {
		if (data) {
			if (fields && fields.length > 0) {
				$.each(fields, function(index, field) {
					if (!data.hasOwnProperty(field)) {
						et.evenue.displayErrors("The field: '" + field + "' do not exists. Message: " + msg, true);
					}
				});
			}
		} else {
			et.evenue.displayErrors(i18n.no_data + " " + msg, true);
		}
	};
	et.evenue.getUrlParameter = function(sParam) {
		var sPageURL = window.location.search.substring(1);
		var sURLVariables = sPageURL.split('&');
		for (var i = 0; i < sURLVariables.length; i++) {
			var sParameterName = sURLVariables[i].split('=');
			if (sParameterName[0] == sParam) {
				return sParameterName[1];
			}
		}
	};
	/*
	 * Display error on a service call
	 * @msg {string} details eg: function name, line
	 */
	et.evenue.serviceCallError = function(msg) {
		et.evenue.displayErrors(msg + ": " + i18n.error_web_service, false);
	};
	et.evenue.displayWarningPanel = function(p_messages) {
		var options = {
			msgs : p_messages,
			type : 'W'
		};
		this.displayWarningPanels(options);
	};
	et.evenue.displayWarningPanels = function(p_options) {
		var dfltOptions = {
			msgs : [],
			element : $("#warning-panel"),
			type : "W",
			scroll: true
		};
		var options = $.extend({}, dfltOptions, p_options);
		if (options.msgs.length > 0) {
			var msgs = options.msgs;
			var content;
			var panel = options.element;
			panel.empty();
			if (options.title) {
				panel.append($("<div></div>").addClass("text-major").text(options.title));
			}
			if (msgs.length == 1) {
				content = msgs[0];
			} else {
				content = $("<ul></ul>");
				var listItem = null;
				$.each(msgs, function(index, msg) {
					listItem = $("<li></li>").html(msg);
					content.append(listItem);
				});
			}

			panel.addClass(options.type == "E" ? "alert-danger" : "alert-warning");
			panel.addClass("alert-dismissable");
			panel.append($("<button></button>").text("x").addClass("close").click(function() {
				$(this).parent().fadeToggle();
			}));

			panel.append(content);

			if (!panel.is(":visible")) {
				panel.fadeToggle();
			}
			//$("html, body").scrollTop(0);
			if (options.scroll) {
				$("html, body").animate({
					scrollTop : 0
				});
			}
		}
	};
	/*
	 * Add and/or display custom errors, this also hides the entire page content
	 * @param {String} errorMsg
	 */
	et.evenue.displayErrors = function(errorMsg, hidePage) {
		var errorHtml = "<div>-&nbsp;" + errorMsg + "</div>", errorPanel = $("#error-panel");

		if (errorMsg) {
			/* this line waits for the last ajax call is being performed, avoid issues with the error panel */
			if (errorPanel.is(":visible")) {
				errorPanel.append("<div>" + errorHtml + "</div>");
			} else {
				$(document).ajaxStop(function() {
					et.displayMessagePanel({
						msgs : [errorHtml],
						element : $("#error-panel")
					});
				});
			}
			if (hidePage && hidePage === true) {
				$("#view-content").hide();
			}

		}
	};
	/* ENDS ERROR PAGE CODE ****************************************************************************************** */

	/**
	 *  eVenue API URLs
	 */
	et.evenue.constants.URL = {
		cart : et.rootCtx + "/rs/carts/{cartId}",
		cartLine : et.rootCtx + "/rs/carts{cartId}/lines/{lineId}",
		confirmation : et.rootCtx + "/rs/transactions/search",
		pdfTickets : et.rootCtx + "/rs/digitaldelivery/tickets",
		passbookTickets : et.rootCtx + "/rs/digitaldelivery/passbook"
	};

	et.evenue.constants.PAYMENT_SUBTYPE = {
		CASH : "CashPayment",
		CREDIT_CARD : "CreditCardPayment",
		CHECK : "CheckPayment",
		ECHECK : "ElectronicCheckPayment",
		TOKEN : "TokenPayment",
		STORED_VALUE_CARD : "StoredValueCardPayment"
	};
	/**
	 * eVenue transaction query types
	 */
	et.evenue.constants.TRANS_QUERY_TYPE = {
		TRANSACTION_SUMMARY : "TransactionSummary"
	};

	et.evenue.constants.CREDIT_CARD_TYPE = {
		VISA : "V",
		AMEX : "A",
		DCI : "C",
		DISCOVER : "D",
		JCB : "J",
		MASTERCARD : "M",
		CUP : "U"
	};
	et.evenue.constants.PAYMENT_TYPE = {
		CREDIT_CARD : "CC",
		ECHECK : "EC",
		OTHER : "OT",
		STORE_VALUE_CARD : "SV"
	};
	et.evenue.constants.CREDIT_CARD_IMAGES = {
		V : "fa-cc-visa",
		A : "fa-cc-amex",
		C : "fa-cc-diners-club",
		D : "fa-cc-discover",
		J : "fa-cc-jcb",
		M : "fa-cc-mastercard",
		U : "fa-credit-card-alt"
	};

	et.evenue.getCreditCardImage = function(creditCardType) {
		var image = null;
		var imageHTML = '';
		if (creditCardType) {
			image = et.evenue.constants.CREDIT_CARD_IMAGES[creditCardType];
		}
		if (image) {
			imageHTML = '<i class="credit-card-type fa ' + image + ' fa-2x"></i>';
		}
		return imageHTML;
	};
	var BASE_COMMON_URL = et.evenue.apiBaseUri + 'common';

	var C = et.constants, EC = et.evenue.constants;

	et.evenue.evLocaleMap = {
		"fr" : "fr_FR",
		"ru" : "ru",
		"cn" : "zh_CN"
	};

	/**
	 * eVenue errors
	 */
	et.evenue.errorCodes = {
		PAGE_NOT_AVAILABLE : 20301,
		NO_DONATIONS_EXIST : 20300,
		SITE_ID_REQUIRED : 20436,
		SITE_ID_INVALID : 20437,
		LOGIN_EMAIL_DUPLICATED : 20426,
		PARTIAL_ADDR_NOTALLWD : 20276
	};

	/**
	 * eVenue configuration, could be override in override.js per site.
	 */
	et.evenue.config = {
		template : "chocolate",
		hideEmailOptIn : true,
		seatmapUrl : "https://media.paciolan.com/seatmap/v3/script/seatmap.js",
		mobileThreshold : 50, // max line items loaded for a given page on
		desktopThreshold : 500, // mobile or desktop.
		hideNickName : false,
		hideReEnterRouting : true,
		hideFinancialName : true,
		pledgeValidation: false
	};

	et.evenue.createBinders = function() {
		binding.binders.toggle = {
			bind : function(el) {
				var self = this;

				this.callback = function(e) {
					if (el.className.indexOf("disabled") > -1) {
						e.stopPropagation();
						return false;
					}
					adapter = binding.adapters[self.observer.key.i];
					model = self.model;
					keypath = self.observer.key.path;
					value = adapter.get(model, keypath);
					var bindedValue = el.children[0].value;
					if (bindedValue === 'true') {
						bindedValue = true;
					}
					if (bindedValue === 'false') {
						bindedValue = false;
					}
					adapter.set(model, keypath, bindedValue);
					console.log(bindedValue);
				};
				el.addEventListener('click', this.callback);
			},

			unbind : function(el) {
				el.removeEventListener('click', this.callback);
			}
		};
	};

	/*
	 * Function for common formatters required by rivets to perform the binding.
	 */
	et.evenue.createFormatters = function() {
		binding.formatters.status = function(value) {
			return value === et.evenue.constants.STATUS.ACTIVE ? i18n.lbl_glob_active : i18n.lbl_glob_inactive;
		};
		binding.formatters.length = function(value) {
			return value ? (value.length || 0) : 0;
		};
		binding.formatters.locale = function(value) {
			return !$.isEmptyObject(value) ? value[et.locale] : String.Emtpy;
		};
		binding.formatters.currency = function(value) {
			if(isNaN(value)){
				return value;
			}
			return et.localeCurrency(value, et.locale);
		};
		binding.formatters.currencyNoSymbol = function(value) {
			return et.localeCurrencyNoSymbol(value, et.locale);
		};
		binding.formatters.driveYear = function(value) {
			return value == 0 ? i18n.lbl_allocation_annual_gift_drive_year : value;
		};
		binding.formatters.balanceDue = function(value) {
			return value < 0 ? "-" : et.localeCurrency(value, et.locale);
		};
		binding.formatters.gt = function(value, args) {
			return value > args;
		};
		binding.formatters.seatsFormat = function(value) {
			return et.evenue.createSeatsLine(value);
		};
		binding.formatters.subtract = function(value, args) {
			return value - args;
		};
		binding.formatters.eq = function(value, arg) {
			return value == arg;
		};
		binding.formatters.or = function(value, arg) {
			return value || arg;
		};
		binding.formatters.and = function(value, arg) {
			return value && arg;
		};
		binding.formatters.neq = function(value, arg) {
			return value != arg;
		};
		binding.formatters.isFalse = function(value) {
			return !value;
		};
		binding.formatters.driveYearConsumer = function(value) {
			if (value == 0) {
				return "";
			}
			return value;
		};
	};

	/**
	 * Get list items threshold, where threshold is the
	 * maximum amount of list items loaded on a page for each request.
	 */
	et.evenue.getLoadMoreThreshold = function() {
		return (et.isMobile() ? et.evenue.config.mobileThreshold : et.evenue.config.desktopThreshold);
	};

	/**
	 * Hide or show 'load more' button depending on whether
	 * there are more items or not.
	 * @param moreItems Boolean returned from server.
	 */
	et.evenue.setLoadMoreBtnVisible = function(moreItems, p_view) {
		var btnLoadmore = p_view.find(".btn-loadmore");
		moreItems ? btnLoadmore.show() : btnLoadmore.hide();
	};

	et.evenue.confirmOrder = function(orderRequest) {
		var svr = {
			url : et.evenue.apiBaseUri + et.evenue.namespaces.TRANS_ORG,
			type : et.constants.HTTP_POST,
			data : orderRequest
		};
		var promise = et.ajax(svr);
		return promise;
	};

	/*
	* Returns userContext from cache or server
	*/
	// et.evenue={
	// contextDeferred:null,
	// getUserContext : function() {
	// if (et.evenue.contextDeferred === null) {
	// et.evenue.contextDeferred = $.Deferred();
	// } else if (et.evenue.contextDeferred.state() === "pending") {
	// var def = $.Deferred();
	// et.evenue.contextDeferred.done(function(result) {
	// def.resolve(result);
	// }).fail(function(err) {
	// def.reject(err);
	// });
	// return def.promise();
	// } else {
	// et.evenue.contextDeferred = $.Deferred();
	// }
	//
	// var deferred = et.evenue.contextDeferred;
	// //check user context first from local cache
	// var userContext = et.cache.userContext;
	// if (!userContext) {
	// var svr = {
	// url : USER_SERVICE_URL
	// };
	// var jqxhr = et.ajax(svr);
	// jqxhr.done(function(p_result) {
	// if (et.handleException(p_result)) {
	// var userCtx = p_result.values.userCtx;
	// et.cache.userCtx = userCtx;
	// if (userCtx && userCtx.selectedChannel) {
	// setUserContextValues(userCtx);
	// deferred.resolve(et.cache.userCtx);
	// } else {
	// var siteId = et.getPageParameter("siteId");
	// et.evenue.setupUserContext(siteId).done(function(result){
	// if (et.handleException(result)) {
	// et.cache.userCtx = result.data;
	// setUserContextValues(et.cache.userCtx);
	// deferred.resolve(et.cache.userCtx);
	// } else {
	// deferred.reject();
	// }
	// }).fail(function() {
	// deferred.reject();
	// });
	// }
	// } else {
	// deferred.reject();
	// }
	// }).fail(function() {
	// deferred.reject();
	// });
	//
	// } else {
	// deferred.resolve(userContext);
	// }
	//
	// return deferred.promise();
	// }
	//
	// };
	et.evenue.contextDeferred = null;
	//et.evenue.createGuestSession = function (siteId) {
	//	var data={channelId:siteId};
	//	var svr = {
	//		type : et.constants.HTTP_POST,
	//		url : GUEST_SESSION_URL,
	//		data:data
	//	};
	//	var promise = et.ajax(svr);
	//	return promise;
	//};

	et.evenue.getPac7UserContext = function()
    {
        var deferred = $.Deferred();
        //check user context first from local cache
        var userContext = et.cache.pac7UserContext;
        if (!userContext)
        {
            var svr =
            {
                url : PAC7_USER_SERVICE_URL
            };
            var jqxhr = et.ajax(svr);
            jqxhr.done(function(data)
            {
                if (data.status.cd == 0)
                {
                    et.cache.pac7UserContext = data.value;
                    deferred.resolve(data.value);
                }
                else
                {
                    deferred.reject();
                }
            }).fail(function()
            {
                deferred.reject();
            });

        }
        else
        {
            deferred.resolve(userContext);
        }

        return deferred.promise();
    };

	et.evenue.getUserContext = function(p_refresh) {
		if (et.evenue.contextDeferred === null) {
			et.evenue.contextDeferred = $.Deferred();
		} else if (et.evenue.contextDeferred.state() === "pending") {
			var def = $.Deferred();
			et.evenue.contextDeferred.done(function(result) {
				def.resolve(result);
			}).fail(function(err) {
				def.reject(err);
			});
			return def.promise();
		} else {
			et.evenue.contextDeferred = $.Deferred();
		}

		var deferred = et.evenue.contextDeferred;

		//check user context first from local cache
		var userContext = et.cache.userCtx;
		if (!userContext || p_refresh) {
			var pacucTkn = et.getPageParameter("pacuctkn");
			if(!et.evenue.isNotNull(pacucTkn))
			{
			    if(!et.evenue.isNotNull(et.siteId)){
			        et.evenue.goToErrorPage();
			        deferred.reject();
			        return deferred.promise();
	
			    }
			}
			var svr = {
				url : USER_SERVICE_URL
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(p_result) {
				if (et.handleException(p_result)) {
					var userCtx = p_result.values.userCtx;
					if (et.siteId === '') {
						deferred.reject();
					}
					/* else if(!userCtx.selectedChannelId || userCtx.selectedChannelId!==et.siteId) {
					 et.evenue.createGuestSession(et.siteId).done(function (p_result) {
					 if (p_result.values) {
					 var userCtx = p_result.values.userCtx;
					 et.cache.userCtx = userCtx;
					 if (userCtx.selectedChannelId) {
					 setUserContextValues(userCtx);
					 deferred.resolve(et.cache.userCtx);
					 } else {
					 deferred.reject();
					 }
					 } else {
					 deferred.reject();
					 }
					 }).fail(function (err) {
					 deferred.reject(err);
					 });
					 }*/
					else {
						et.cache.userCtx = userCtx;
						setUserContextValues(userCtx);
						deferred.resolve(userCtx);
					}

				} else {
					deferred.reject();
				}
			}).fail(function() {
				deferred.reject();
			});
		} else {
			deferred.resolve(userContext);
		}
		return deferred.promise();
	};
	/*
	* Set values in UserContext based on channelId
	* Return userContext in data attribute of result
	*/
	// et.evenue.setupUserContext = function(channelId) {
	// var deferred = $.Deferred();
	// var svr = {
	// url : USER_SERVICE_URL + '/channels/' + channelId,
	// type: "POST"
	// };
	// var jqxhr = et.ajax(svr);
	// jqxhr.done(function(p_result) {
	// if (et.handleException(p_result)) {
	// deferred.resolve(p_result);
	// } else {
	// deferred.reject();
	// }
	// }).fail(function() {
	// deferred.reject();
	// });
	// return deferred.promise();
	// };

	et.evenue.getPac7UserContext = function() {
		var deferred = $.Deferred();
		//check user context first from local cache

		var svr =
		{
			url : "/app/ws/user/context"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data)
		{
			if (data.status.cd == 0)
			{
				et.cache.userContext = data.value;
				deferred.resolve(data.value);
			}
			else
			{
				deferred.reject();
			}
		}).fail(function()
		{
			deferred.reject();
		});

		return deferred.promise();
	};

	var setUserContextValues = function(userCtx) {
		et.evenue.clientId = userCtx.clientId;
		et.evenue.selectedOrganizationId = userCtx.selectedOrganizationId;
		et.evenue.selectedChannelId = userCtx.selectedChannelId;
		et.evenue.accountDatabaseId = userCtx.accountDbId;
	};

	et.evenue.userCtx = {
		//get : function(p_refresh) {
		//	var deferred = $.Deferred();
		//	var userCtx = et.cache.userCtx;
		//	if (!p_refresh && userCtx) {
		//		deferred.resolve(userCtx);
		//		return deferred.promise();
		//	}
		//	var clientid = 0;
		//	// todo obtain a valid reference
		//
		//	var svr = {
		//		url : et.rootCtx + "/rs/v1.0/authentication/" + clientid + "/sessions?view=ctx"
		//	};
		//	var jqxhr = et.ajax(svr);
		//	jqxhr.done(function(p_result) {
		//		if (et.handleException(p_result)) {
		//			et.cache.userCtx = p_result.values.userCtx;
		//
		//			et.evenue.clientId = et.cache.userCtx.clientId;
		//			et.evenue.selectedOrganizationId = et.cache.userCtx.selectedOrganizationId;
		//			et.evenue.selectedChannelId = et.cache.userCtx.selectedChannelId;
		//
		//			deferred.resolve(et.cache.userCtx);
		//		} else {
		//			deferred.reject();
		//		}
		//	}).fail(function() {
		//		deferred.reject();
		//	});
		//
		//	return deferred.promise();
		//},
		selectControls : function(channelId, locationId, orgId, controlId, policyId, notifyHeader) {
			var deferred = $.Deferred();
			var svr = {
				type : et.constants.HTTP_PATCH,
				url : et.rootCtx + "/rs/contexts",
				data : {
					channelId : channelId,
					locationId : locationId,
					orgId : orgId,
					controlId : controlId,
					policyId : policyId
				}
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(p_result) {
				if (et.handleException(p_result)) {
					et.cache.userCtx = p_result.values.userCtx;
					deferred.resolve(et.cache.userCtx);

					et.global.page.header.buildMenu();
				} else {
					deferred.reject();
				}
			}).fail(function() {
				deferred.reject();
			});

			return deferred.promise();
		},
		selectControlsByOrg : function() {
			var deferred = $.Deferred();
			var svr = {
				type : et.constants.HTTP_GET,
				url : et.rootCtx + "/rs/orgjobs/"

			};
			var promise = et.ajax(svr);
			// jqxhr.done(function(p_result) {
			// if (et.handleException(p_result)) {
			// et.cache.userCtx = p_result.values.userCtx;
			// deferred.resolve(et.cache.userCtx);
			//
			// et.global.page.header.buildMenu();
			// } else {
			// if (p_result.status.cd == 100000) {
			// window.location = "../auth/#SignIn";
			// // deferred.reject();
			// }
			// }
			// }).fail(function() {
			// deferred.reject();
			// });

			return promise;
		}
	};

	/*
	 * Returns system codes (dbId=-1) for the specified type and subtype
	 */
	et.evenue.getSystemCodeTypeSubtypes = function(type, subType) {
		var deferred = $.Deferred();
		var svr = {
			url : BASE_COMMON_URL + '/code/' + et.evenue.clientId + '/list/system/' + type + '/' + subType,
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	et.evenue.loadCreditCardCodes = function() {
		return et.evenue.getSystemCodeTypeSubtypes(et.evenue.constants.PAYMENT_TYPE_CODE, et.evenue.constants.PAYMENT_TYPE.CREDIT_CARD);
	};

	et.evenue.isParentPaymentType = function(code){
    	return (!code.key.id || !code.key.subType || code.key.id === code.key.subType);
    };

	et.evenue.isParentPaymentType = function(code) {
		return (!code.key.id || !code.key.subType || code.key.id === code.key.subType);
	};

	et.evenue.loadPayCodes = function(queryParams) {
		var deferred = $.Deferred();
		var svr = {
			url : et.evenue.apiBaseUri + et.evenue.urls.PATH_PAYCODES,
			type : et.constants.HTTP_GET
		};
		if (queryParams) {
			var queryString = "?" + $.param(queryParams);
			svr.url = svr.url.concat(queryString);
		}
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result) {
			if (et.handleException(p_result)) {
				deferred.resolve(p_result.values.payCodes);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};


	et.evenue.formatCurrencyDisplay = function(amount, home_currency, currency_display) {
		if (currency_display === et.evenue.constants.SYMBOL) {
			return et.localeCurrency(amount, et.locale);
			//TODO: waiting for Hans's method to pass currency_code
		}
		return et.localeCurrencyNoSymbol(amount, et.locale) + " " + home_currency;
	};

	/**
	 * Creates and stores the patron/account's credit card.
	 *
	 * @param {String} accountDbId
	 * @param {String} accountId
	 * @param {Object} accountCreditCard - Patron/Account credit card model.
	 * @return {Promise}
	 *
	 * @author Dennis Trinh
	 * @since 8.0
	 */
	et.evenue.storeAccountCreditCard = function storeAccountCreditCard(accountDbId, accountId, accountCreditCard) {
		var url = et.evenue.urls.BASE_ACCOUNT_URL + '/{accountId}/creditcards';
		url = url.replace('{dbId}', accountDbId);
		url = url.replace('{accountId}', accountId);

		var svr = {
			url : url,
			type : et.constants.HTTP_POST,
			data : accountCreditCard
		};
		var promise = et.ajax(svr);
		return promise;
	};

	/**
	 * Upserts the patron/account's bank account to be stored for electronic check.
	 *
	 * @param {String} accountDbId
	 * @param {String} accountId
	 * @param {Object} accountBankAccount - Patron/Account bank account model.
	 * @return {Promise}
	 *
	 * @author Dennis Trinh
	 * @since 8.0
	 */
	et.evenue.upsertAccountBankAccount = function upsertAccountBankAccount(accountDbId, accountId, accountBankAccount) {
		var url = et.evenue.urls.BASE_ACCOUNT_URL + '/{accountId}/bankaccounts';
		url = url.replace('{dbId}', accountDbId);
		url = url.replace('{accountId}', accountId);

		var svr = {
			url : url,
			type : et.constants.HTTP_POST,
			data : accountBankAccount
		};
		var promise = et.ajax(svr);
		return promise;
	};

	/**
	 * Updates the patron/account's credit card to be stored.
	 *
	 * @param {String} accountDbId
	 * @param {String} accountId
	 * @param {Object} accountCreditCard - Patron/Account credit card model.
	 * @return {Promise}
	 *
	 * @author Dennis Trinh
	 * @since 8.0
	 */
	et.evenue.updateAccountCreditCard = function updateAccountCreditCard(accountDbId, accountId, accountCreditCard) {
		var url = et.evenue.urls.BASE_ACCOUNT_URL + '/{accountId}/creditcards';
		url = url.replace('{dbId}', accountDbId);
		url = url.replace('{accountId}', accountId);

		var svr = {
			url : url,
			type : et.constants.HTTP_PATCH,
			data : accountCreditCard
		};
		var promise = et.ajax(svr);
		return promise;
	};

	/**
	 * Verifies a stored value card.
	 *
	 * @author Dennis Trinh
	 * @since 8.0
	 */
	et.evenue.verifyStoredValueCard = function verifyStoredValueCard(storedValueCard) {
		var svr = {
			url : et.evenue.apiBaseUri + et.evenue.apiVersion + '/' + et.evenue.namespaces.PAYMENT_ORG + '/' + et.evenue.selectedOrganizationId + '/verify/storedvaluecard',
			type : et.constants.HTTP_POST,
			data : storedValueCard
		};
		var promise = et.ajax(svr);
		return promise;
	};

	et.evenue.storeOrUpdateCreditCardOnAccount = function storeOrUpdateCreditCardOnAccount(paymentRegion, accountId) {
		var deferred = $.Deferred();
		var accountCreditCard = paymentRegion.getData().accountCreditCard;
		if (paymentRegion.shouldStorePayment()) {
			et.evenue.storeAccountCreditCard(et.evenue.accountDatabaseId, accountId, accountCreditCard).done(function(data, textStatus, jqXHR) {
				if (data && data.status && data.status.cd === 0) {
					et.cache.account = null;
					deferred.resolve(true);
				} else {
					deferred.resolve(et.evenue.constants.STORE_CC_FAILED);
				}
			}).fail(function(jqXHR, textStatus, errorThrown) {
				deferred.resolve(et.evenue.constants.STORE_CC_FAILED);
			});
		} else if (paymentRegion.isStoredPayment()) {
			if (accountCreditCard.isExpired) {
				et.evenue.updateAccountCreditCard(et.evenue.accountDatabaseId, accountId, accountCreditCard).done(function(data, textStatus, jqXHR) {
					if (data && data.status && data.status.cd === 0) {
						et.cache.account = null;
						deferred.resolve(true);
					} else {
						deferred.resolve(et.evenue.constants.UPDATE_CC_FAILED);
					}
				}).fail(function(jqXHR, textStatus, errorThrown) {
					deferred.resolve(et.evenue.constants.UPDATE_CC_FAILED);
				});
			} else {
				deferred.resolve(true);
			}
		} else {
			deferred.resolve(true);
		}
		return deferred.promise();
	};

	/*
	 * Adds options to select element select - select element values - list of
	 * objects that contain the value and text of the option valueField - field
	 * that contains the option value textField - field that contains the option
	 * text to be displayed
	 */
	et.evenue.addSelectOptions = function(select, values, valueField, textField) {
		if (select && values) {
			$.each(values, function(index, value) {
				select.append($(et.evenue.constants.OPTION).val(value[valueField]).text(value[textField]));
			});
		}
	};

	/*
	 * Adds options to select element. select - select element values - list of
	 * code objects that contain the value and text of the option showAll - if
	 * null or false, only values that are active (modelStatus == 1) will be
	 * added else all values will be added attr - key value for each select
	 */
	et.evenue.addSelectOptionsFromCodes = function(select, values, attr, showAll) {
		if (select && values) {
			$.each(values, function(index, value) {
				if (value.modelStatus == 1 || showAll)
					select.append($(et.evenue.constants.OPTION).val(value.key[attr]).text(value.name[et.locale]));
			});
		}
	};

	/*
	 * Retrieves client codes for specified type
	 */
	et.evenue.getCodesByType = function(type) {

		var deferred = $.Deferred();
		var svr = {
			url : BASE_COMMON_URL + '/code/' + et.evenue.accountDatabaseId + '/list/' + type
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	/*
	 * Retrieves codes by type and subtype
	 */
	et.evenue.getCodesBySubType = function(type, subtype) {

		var deferred = $.Deferred();

		var svr = {
			url : BASE_COMMON_URL + '/code/' + et.evenue.accountDatabaseId + '/list/' + type + "/" + subtype,
		};

        var jqxhr = et.ajax(svr);
        jqxhr.done(function(result) {
            if (et.handleException(result)) {
                deferred.resolve(result.data);
            } else {
                deferred.reject();
            }
        }).fail(function() {
            et.handleFailure();
        });
        return deferred.promise();
    };

	/*
	 * Returns map of account code lists of specified types
	 * key: codeType, value: list of codes
	 * @param types: comma seperated list of code types
	 * @param accessibleOnly: if has value and value is true,
	 *                        returns accessible addresses, phones, emails
	 * 						  based on organization settings
	 */
	et.evenue.getAccountCodes = function(types, accessibleOnly) {
		var deferred = $.Deferred();
		var svr = {
			url : BASE_COMMON_URL + '/codes/account/' + types + ( accessibleOnly ? '?s_filter=1' : '')
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.values);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	/*
	 * Returns primary contact @contacts - list of account contacts
	 * @primaryContactId - uuid of the primary contact
	 */
	et.evenue.getPrimaryContact = function(contacts, primaryContactId) {
		var primaryContact = null;
		if (contacts && contacts.length > 0) {
			if (primaryContactId) {
				$.each(contacts, function(index, contact) {
					if (primaryContactId == contact.key.id) {
						primaryContact = contact;
						return false;
					}
				});
			} else {
				return contacts[0];
			}
		}
		return primaryContact;
	};

	/*
	 * Posts data to account service to create new account @param account - json
	 * object to create
	 */
	et.evenue.createAccount = function(account) {
		var deferred = $.Deferred();

		var svr = {
			url : et.evenue.urls.BASE_ACCOUNT_URL,
			type : et.constants.HTTP_POST,
			data : account
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	/*
	 * options = { placeholder - element in which to display alert, message -
	 * text to be displayed fadeTo - true/false (defaults to true) duration -
	 * milliseconds before alert disappears panelType - style if error , success
	 * or warning }
	 */
	et.evenue.showAlert = function(options) {
		var dfltOptions = {
			placeholder : '#placeholder-alert',
			fadeTo : true,
			duration : 5000,
			message : "You forgot to set the message",
			panelType : "alert-success",
			icon: 'fa fa-check'
		};
		options = $.extend({}, dfltOptions, options);
		var alertContent = '<div role="alert" class="alert ' + options.panelType + ' alert-dismissible alert-temp fade in block"><i class="'+options.icon+'"></i><button type="button" class="close fa fa-times" data-dismiss="alert"></button><div class="message-content">' + options.message + '</div></div>';
		$(options.placeholder).append(alertContent);
		$(".alert-temp").alert();
		if (options.fadeTo) {
			$(".alert-temp").fadeTo(options.duration, 500).slideUp(500, function() {
				$(".alert-temp").alert('close');
			});
		}
	};

	/*
	 * get password policy
	 * @param clientId
	 */
	et.evenue.getPasswordPolicy = function(clientId) {
		var url = et.evenue.constants.SERVICE_PASSWORD_POLICY_RESOURCE_URL + '?view=operator', deferred = $.Deferred();
		var svr = {
			type : HTTP_GET,
			url : url
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.values.passwordPolicy);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};
	et.evenue.getConsumerPasswordPolicy = function() {
		var url = et.evenue.constants.SERVICE_PASSWORD_POLICY_RESOURCE_URL + '?view=consumer', deferred = $.Deferred();
		var svr = {
			type : HTTP_GET,
			url : url
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.values.passwordPolicy);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	et.evenue.resetPassword = function(newPassword, encryptedParams) {
		var deferred = $.Deferred();
		var data = {
			password : newPassword,
			encrypted : encryptedParams
		};
		var url = et.evenue.constants.SERVICE_OAUTH_ACCOUNT_URL.replace("{dbId}", "default");

		var svr = {
			type : HTTP_POST,
			url : url,
			data : data
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};
	/*
	 * Updates account data, columns updated are based on updateType @param
	 * account - json object to update @param updateType - constants used to
	 * determine columns that will be updated. values are in et.evenue.constants. If
	 * value is null all columns will be updated.
	 */
	et.evenue.updateAccount = function(account, updateType) {
		updateType = updateType ? updateType : this.constants.ACCT_ALL;
		var deferred = $.Deferred();

		var svr = {
			url : et.evenue.urls.BASE_ACCOUNT_URL + '?updateType=' + updateType,
			type : et.constants.HTTP_PATCH,
			data : account
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				et.cache.account = null;
				deferred.resolve(result.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	/*
	 * Updates account data, columns updated are based on updateType @param
	 * person - json object to update @param updateType - constants used to
	 * determine columns that will be updated. values are in et.evenue.constants. If
	 * value is null all columns will be updated.
	 */
	et.evenue.updatePerson = function(person, updateType) {
		updateType = updateType ? updateType : this.constants.ACCT_ALL;
		var deferred = $.Deferred();

		var svr = {
			url : et.evenue.urls.BASE_ACCOUNT_URL + "/" + person.key.fromAccountId + "/" + et.evenue.urls.PATH_PERSON + '/' + updateType,
			type : et.constants.HTTP_PATCH,
			data : person
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.data);
				et.cache.account = null;
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	et.evenue.updateAdditionalPatronData = function(p_dataGroups, p_ptrnAdditionalDataUiVo) {
		var deferred = $.Deferred();

		$.when(et.evenue.getUserContext()).done(function(p_userContext) {
			var svr = {
				url : PATRON_SERVICES_URL + p_userContext.patronId + ADDITIONAL_PATRON_DATA_SERVICE_URL,
				data : {
					dataGroups : p_dataGroups,
					uiVo : p_ptrnAdditionalDataUiVo
				},
				type : HTTP_POST
			};
			(JSON.stringify(svr));

			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data) {
				deferred.resolve(data);
			}).fail(function() {
				deferred.reject();
			});
		});

		return deferred.promise();
	};

	/*
	 * Check if two phones are equals with no format
	 * @paran: phone1 {phone}
	 * @param: phone2 {phone}
	 * @return boolean
	 */
	et.evenue.isSamePhone = function(phone1, phone2) {
		var isSame = false;
		if (phone1 && phone2) {
			isSame = (phone1.type === phone2.type);
		}
		return isSame;
	};

	/*
	 * Authenticates user in back office and returns patronVo
	 */
	et.evenue.userLogin = function(p_values) {
		var deferred = $.Deferred();
		var svr = {
			url : EC.LOGIN_URL + "?patronId=" + (p_values.patronId ? p_values.patronId : "") + "&email=" + (p_values.email ? p_values.email : "") + "&password=" + p_values.password
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {
			if (data.status.cd == 0)//eVenue Pac 7.2 login succeeded
			{
				var result = data.value;
				var patron = result.myaccount.patronVo;
				et.cache.myaccount = result.myaccount;

				//Try to log the user into eVenue
				et.evenue.evLogin(patron.patronId, result.hashcode, result.timestamp).done(function(result) {
					if (result)//eVenue login succeeded
					{
						$("#hdr_sign_out").show();
						$("#hdr_sign_in").hide();
						if (et.cache.userContext) {
							et.cache.userContext.logon = true;
							et.cache.userContext.patronId = patron.patronId;
						}
						deferred.resolve(patron);
					} else//eVenue login failed
					{
						deferred.reject();
						et.evenue.userLogout();
					}
				}).fail(function() {
					et.evenue.userLogout();
					deferred.reject();
				});

			} else//eVenue Pac 7.2 login failed
			{
				et.handleException(data);
				deferred.reject();
			}

		}).fail(function()//eVenue Pac 7.2 login failed
		{
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * Sets patronVo in UserContexVo to null and logon to false
	 * Clears myaccount from cache, sets cached userContext logon to false
	 */
	et.evenue.userLogout = function() {
		var svr = {
			url : et.evenue.constants.PATRON_URL + "pacSsoLogout"
		};
		et.ajax(svr).done(function() {

		});
		et.cache.myaccount = null;
		$("#hdr_sign_out").hide();
		$("#hdr_sign_in").show();
		if (et.cache.userContext)
			et.cache.userContext.logon = false;
	};
	
	/**
	 * Pac7 call to logout of pac7 and navigate to eVenue NetCommerce
	 */
	et.evenue.logOut = function(p_linkId, p_goToLogin, clearKmsi, redirectAfterNCLogOut) {

		$.when(et.evenue.getMyAccountv2FeatureFlagZKP(), et.evenue.getUSIFeatureFlags()).done(function(myacctv2,usi) { 
			var usiEnabled = usi.accessible && myacctv2;
 
			if (usiEnabled) { 
				var logOffUrl = window.location.protocol + "//" + window.location.hostname+ '/signout?continue='+ encodeURIComponent(window.location.protocol + "//" + window.location.hostname +'/signin');
				var signInURL = window.location.protocol + "//" + window.location.hostname+ "/signin";
				var redirectURL = "";
				
				if(redirectAfterNCLogOut) {
					redirectURL = signInURL;
				}
				else if (window.location.protocol && window.location.host && window.location.pathname && window.location.search) {
					redirectURL = window.location.protocol + "//" + window.location.host + window.location.pathname + window.location.search;
				}
				
				var signOutUrl = window.location.protocol + "//" + window.location.hostname + '/signout?continue=' + encodeURIComponent(window.location.protocol + "//" + window.location.hostname +'/signin');

				//if user context not present then redirect to evenue login
				var pageParams = {
					"linkID" : p_linkId,
					"url" : p_goToLogin ? redirectURL : signOutUrl
				};
				et.evenue.PageController.navigate( !p_goToLogin || redirectAfterNCLogOut ? logOffUrl : signInURL, pageParams, null, null);

			} else {
				var isSso = false;
				var ssoTs = localStorage.getItem("ev_sso_" + p_linkId);
				if (ssoTs) {
					var tks = ssoTs.split("::");
					var patronId = et.cache && et.cache.userCtx ? et.cache.userCtx.accountId : "";
					if (tks && tks.length == 2 && tks[0] == patronId) {
						isSso = true;
					}
				}
				
				var logOffUrl = window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/REGLogoff";
				var signInURL = window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + p_linkId + "&evm=myac&entry=main.html";
				var redirectURL = "";
				
				if(redirectAfterNCLogOut) {
					redirectURL = signInURL;
				}
				else if (window.location.protocol && window.location.host && window.location.pathname && window.location.search) {
					redirectURL = window.location.protocol + "//" + window.location.host + window.location.pathname + window.location.search;
				}
				
				var signOutUrl = window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + p_linkId + "&evm=regl";

				// p_goToLogin is true on session expire
				// therefore if it's false we assume explicit logout
				clearKmsi = clearKmsi || !p_goToLogin;
				$.when(checkKmsiPromise(clearKmsi, et.siteId)).done(function() {
					if (isSso) {
						var sloInit = "et_evenue_logOut";
						window.location = location.protocol + '//'+ location.hostname + (location.port ? ':' + location.port: '') + '/www/' + et.siteId + '/ss/ev/sso/ncredirectout/?siteId=' + et.siteId + '&linkID=' + p_linkId + '&sloInit=' + sloInit + '&toLogin=' + (+(p_goToLogin ? true : false)) + '&url=' + (p_goToLogin ? encodeURIComponent(redirectURL) : encodeURIComponent(signOutUrl));
					} else {
						var svr = {
								url : "/app/ws/authentication/logout",
								data : {
									"siteId" : p_linkId
								},
								type : HTTP_POST
							};
							var jqxhr = et.ajax(svr);
							jqxhr.done(function(data) {
								//if user context not present then redirect to evenue login
								var pageParams = {
									"linkID" : p_linkId,
									"url" : p_goToLogin ? redirectURL : signOutUrl
								};
								et.evenue.PageController.navigate( !p_goToLogin || redirectAfterNCLogOut ? logOffUrl : signInURL, pageParams, null, null);
							});
					}
				});
			}
		});
	};

	var checkKmsiPromise = function(clearKmsi, siteId) {
		//var deferred = $.Deferred();
		if (!clearKmsi) return true; //deferred.resolve();
		else return et.evenue.removeKmsi(siteId);
	}

	et.evenue.removeKmsi = function(_siteId) {
		var deferred = $.Deferred();
		var siteId = _siteId || et.siteId || window.siteID;
		var kmsiFlag = siteId + '_kmsi';
		if (!localStorage.getItem(kmsiFlag)) {
			deferred.resolve();
		} else {
			localStorage.removeItem(kmsiFlag);
			var data = JSON.stringify({
				"siteId": siteId
			});
			var xhr = new XMLHttpRequest();
			xhr.withCredentials = true;
			xhr.open("DELETE", "/pac-api/auth/kmsi");
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.onload = function() { deferred.resolve(); };
			xhr.onerror = function() { deferred.resolve(); };
			xhr.send(data);
		}
		return deferred.promise();
	};

	et.evenue.getAdditionalPatronData = function(p_dataGroups) {
		var deferred = $.Deferred();

		$.when(et.evenue.getUserContext()).done(function(p_userContext) {
			var svr = {
				url : PATRON_SERVICES_URL + p_userContext.patronId + ADDITIONAL_PATRON_DATA_SERVICE_URL,
				data : {
					dataGroups : p_dataGroups
				},
				type : HTTP_GET
			};

			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data) {
				deferred.resolve(data);
			}).fail(function() {
				deferred.reject();
			});
		});

		return deferred.promise();
	};

	et.evenue.getDonationHistory = function(p_patronId) {
		var deferred = $.Deferred();
		var donationhistory = et.cache.donationhistory;

		if (donationhistory) {
			deferred.resolve(donationhistory);
		} else {
			var svr = {
				url : HISTORY_SERVICES_URL + p_patronId + DONATION_HISTORY_SERVICE_URL,
				type : HTTP_GET
			};

			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data) {
				deferred.resolve(data);
				et.cache.donationhistory = data;

			}).fail(function() {
				deferred.reject();
			});
		}

		return deferred.promise();
	};

	/**
	 *
	 *
	 * *****************************        Start of Patron Functions     ****************************
	 *
	 *
	 */

	et.evenue.getDefalutAddressSetting = function(p_userContext) {
		var config = {
			ajax : et.ajax,
			resCountries : et.countries,
			zipcodeLookupRemoteUrl : PATRON_SERVICES_URL + "csz",
			zipcodeLookupCountries : p_userContext.inlAddrSaveto === EC.YES ? ["*"] : ["US", "CA"],
			defaultCountryCd : p_userContext.dfltCountry,
			displayCountry : true,
			displayMailName : false,
			allowPartial : false
		};

		return config;
	};

	/*
	 * Returns patronVo contained in myAccountVo
	 */

	et.evenue.getPatron = function(p_patronId) {
		var deferred = $.Deferred();
		var patron = et.cache.myaccount ? et.cache.myaccount.patronVo : null;
		if (patron) {
			deferred.resolve(patron);
		} else {
			var patronId = p_patronId ? p_patronId : "-1";
			et.evenue.getPatronDetails(patronId).done(function(myaccount) {
				deferred.resolve(myaccount.patronVo);
			}).fail(function() {
				deferred.reject();
			});
		}
		return deferred.promise();
	};

	et.evenue.getPatronDetails = function(p_patronId) {

		var deferred = $.Deferred();
		var myaccount = et.cache.myaccount;
		if (myaccount) {
			deferred.resolve(myaccount);
		} else {
			var patronDtlsUrl = EC.PATRON_URL + ( p_patronId ? p_patronId : "-1") + "/myAccount";
			var svr = {

				url : patronDtlsUrl
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data) {
				deferred.resolve(data.value);
				et.cache.myaccount = data.value;

			}).fail(function() {
				deferred.reject();
			});

		}
		return deferred.promise();
	};
	/**
	 *Method used for collecting key as addressType and values as an object for LabelType(TF,TS,FB,FS) and Label name
	 * This method can be used for validating an addressType. An invalid addressType shouldn't have an entry in this map.
	 * This map can have min of length=1 and max of length =4
	 */
	et.evenue.getAddressLabelMap = function() {
		var deferred = $.Deferred();
		et.evenue.getUserContext().done(function(data) {
			var labelMap = new Object();
			if (data.billaddrtypedef) {
				labelMap[data.billaddrtypedef] = {
					"lType" : EC.TB_LABEL_TYPE,
					"label" : data.billaddrtypelbl
				};
			}
			if (data.shipaddrtypdef) {
				labelMap[data.shipaddrtypdef] = {
					"lType" : EC.TS_LABEL_TYPE,
					"label" : data.shipaddrtypelbl
				};
			}
			if (data.billdonaddrtypedef) {
				labelMap[data.billdonaddrtypedef] = {
					"lType" : EC.FS_LABEL_TYPE,
					"label" : data.funbilladdrtypelbl
				};
			}
			if (data.shipdonaddrtypedef) {
				labelMap[data.shipdonaddrtypedef] = {
					"lType" : EC.FS_LABEL_TYPE,
					"label" : data.funshipaddrtypelbl
				};
			}
			deferred.resolve(labelMap);
		});
		return deferred.promise();
	};
	et.evenue.getAddrReqdByType = function(p_userCtx, p_type) {

		if (p_type == p_userCtx.billaddrtypedef) {
			return p_userCtx.billaddrreq;
		} else if (p_type == p_userCtx.shipaddrtypdef) {
			return p_userCtx.shipaddrreq;
		} else if (p_type == p_userCtx.billdonaddrtypedef) {
			return "1";
		} else if (p_type == p_userCtx.shipdonaddrtypedef) {
			return p_userCtx.donshipaddrreq;
			;
		}

	};
	//
	et.evenue.changeTempAddrTypeMap = function(p_userCtx) {

		var changeTypeMap = new Object();
		if (p_userCtx.billaddrtypedef) {
			changeTypeMap[p_userCtx.billaddrtypedef] = p_userCtx.tmpbilladdrtype;
		}
		if (p_userCtx.shipaddrtypdef) {
			changeTypeMap[p_userCtx.shipaddrtypdef] = p_userCtx.tmpshipaddrtype;
		}
		if (p_userCtx.billdonaddrtypedef) {
			changeTypeMap[p_userCtx.billdonaddrtypedef] = p_userCtx.tmpbilldonaddrtype;
		}
		if (p_userCtx.shipdonaddrtypedef) {
			changeTypeMap[p_userCtx.shipdonaddrtypedef] = p_userCtx.tmpshipdonaddrtype;
		}
		return changeTypeMap;

	};

	et.evenue.convertAddressLine4ToStateForIntlAddress = function(p_userCtx, p_addressList) {
		if (p_userCtx.inlAddrSaveto === EC.YES) {
			for (var i = 0; i < p_addressList.length; i++) {
				var address = p_addressList[i];
				if ((address.address4 && address.address4 !== "") && (!address.state || address.state === "")) {
					address.state = address.address4;
				}
			}
		}
	};

	/**
	 * Method returns map of addressTypes in userContext based on cart type
	 * map will have 1 to 4 entries
	 * key - address type
	 * value - JSON containing attributes: reqd, title, label type, temporary address type
	 * p_cartType - if null all addressTypes from userContext are added to the map
	 */
	et.evenue.getAddressTypeMap = function(p_userContext, p_cartType) {
		var index = 0;
		var result = {
		};
		if (!p_cartType || p_cartType.indexOf('T') > -1 || (p_cartType == "D" && p_userContext.collectdonaddr == EC.NO)) {
			result[p_userContext.billaddrtypedef] = {
				reqd : p_userContext.billaddrreq,
				title : p_userContext.billaddrtypelbl,
				lbltype : EC.TB_LABEL_TYPE,
				tempType : p_userContext.tmpbilladdrtype,
				seq : index++
			};
			if (p_userContext.collectaddr == EC.OPTIONAL) {
				result[p_userContext.shipaddrtypdef] = {
					reqd : p_userContext.shipaddrreq,
					title : p_userContext.shipaddrtypelbl,
					lbltype : EC.TS_LABEL_TYPE,
					tempType : p_userContext.tmpshipaddrtype,
					seq : index++
				};
			}
		}
		if (!p_cartType || p_cartType.indexOf('D') > -1) {
			if (p_userContext.collectdonaddr != EC.NO) {
				result[p_userContext.billdonaddrtypedef] = {
					reqd : p_userContext.billdonaddrreq,
					title : p_userContext.funbilladdrtypelbl,
					lbltype : EC.TB_LABEL_TYPE,
					tempType : p_userContext.tmpbilldonaddrtype,
					seq : index++
				};
				if (p_userContext.collectdonaddr == EC.OPTIONAL) {
					result[p_userContext.shipdonaddrtypedef] = {
						reqd : p_userContext.donshipaddrreq,
						title : p_userContext.funshipaddrtypelbl,
						lbltype : EC.TB_LABEL_TYPE,
						tempType : p_userContext.tmpshipdonaddrtype,
						seq : index++
					};
				}
			}
		}

		return result;

	};

	//gets all Address modules even if they empty. Used in MyAddresses and EditAddress
	et.evenue.getAddressBook = function(p_addresses, p_cartType) {
		var deferred = $.Deferred();
		et.evenue.getUserContext().done(function(p_userCtx) {
			var addressList = [];
			var counter = 0;

			var tktBillAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userCtx.billaddrtypedef);

			if (!p_cartType || p_cartType == "T" || p_cartType == "TD" || (p_cartType == "D" && p_userCtx.collectdonaddr == EC.NO)) {
				if (tktBillAddress) {
					var address = {
					};
					address = $.extend({
					}, tktBillAddress);
					address.reqd = p_userCtx.billaddrreq;
					addressList[counter] = address;

				} else {
					var address = {
					};
					address.title = p_userCtx.billaddrtypelbl;
					address.type = p_userCtx.billaddrtypedef;
					address.lbltype = EC.TB_LABEL_TYPE;
					address.empty = true;
					address.reqd = p_userCtx.billaddrreq;
					addressList[counter] = address;

				}
				counter++;
				if (p_userCtx.collectaddr == EC.OPTIONAL) {
					var tktShipAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userCtx.shipaddrtypdef);
					if (tktShipAddress) {
						//tktShipAddress.reqd = p_userCtx.shipaddrreq;
						var address = {
						};
						address = $.extend({
						}, tktShipAddress);
						address.reqd = p_userCtx.shipaddrreq;
						addressList[counter] = address;
					} else {
						var address = {
						};
						address.title = p_userCtx.shipaddrtypelbl;
						address.type = p_userCtx.shipaddrtypdef;
						address.lbltype = EC.TS_LABEL_TYPE;
						address.empty = true;
						address.reqd = p_userCtx.shipaddrreq;
						addressList[counter] = address;

					}
					counter++;
				}
			}
			if (!p_cartType || p_cartType == "D" || p_cartType == "TD") {
				if (p_userCtx.collectdonaddr != EC.NO) {
					var funBillAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userCtx.billdonaddrtypedef);
					if (funBillAddress) {
						var address = {
						};
						address = $.extend({
						}, funBillAddress);
						address.reqd = "1";
						addressList[counter] = address;
					} else {
						var address = {
						};
						address.title = p_userCtx.funbilladdrtypelbl;
						address.type = p_userCtx.billdonaddrtypedef;
						address.lbltype = EC.FB_LABEL_TYPE;
						address.empty = true;
						address.reqd = "1";
						addressList[counter] = address;
					}
					counter++;
					if (p_userCtx.collectdonaddr == EC.OPTIONAL) {
						var funShipAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userCtx.shipdonaddrtypedef);
						if (funShipAddress) {
							var address = {
							};
							address = $.extend({
							}, funShipAddress);
							address.reqd = p_userCtx.donshipaddrreq;
							addressList[counter] = address;

						} else {
							var address = {
							};
							address.reqd = p_userCtx.donshipaddrreq;
							address.title = p_userCtx.funshipaddrtypelbl;
							address.type = p_userCtx.shipdonaddrtypedef;
							address.lbltype = EC.FS_LABEL_TYPE;
							address.empty = true;
							addressList[counter] = address;
						}
						counter++;
					}
				}
			}

			et.evenue.convertAddressLine4ToStateForIntlAddress(p_userCtx, addressList);

			deferred.resolve(addressList);
		});
		return deferred.promise();
	};

	et.evenue.updateAddressTitleType = function(p_userContext, p_address, p_type) {
		if (p_type == p_userContext.billaddrtypedef) {
			p_address.title = p_userContext.billaddrtypelbl;
			p_address.type = p_userContext.billaddrtypedef;

		} else if (p_type == p_userContext.shipaddrtypdef) {
			p_address.title = p_userContext.shipaddrtypelbl;
			p_address.type = p_userContext.shipaddrtypdef;

		} else if (p_type == p_userContext.billdonaddrtypedef) {
			p_address.title = p_userContext.funbilladdrtypelbl;
			p_address.type = p_userContext.billdonaddrtypedef;

		} else if (p_type == p_userContext.shipdonaddrtypedef) {
			p_address.title = p_userContext.funshipaddrtypelbl;
			p_address.type = p_userContext.shipdonaddrtypedef;

		}
		return p_address;
	};
	/*
	 * Returns list of patron addresses based on list of address types
	 */
	et.evenue.getAddressesByTypes = function(addresses, p_types) {
		var returnList = [];
		if (addresses && p_types) {
			for (var i = 0; i < addresses.length; i++) {
				var address = addresses[i];
				var x = $.inArray(address.type, p_types);
				if (x > -1) {
					returnList.push(address);
				}
			}
		}
		return returnList;
	};

	et.evenue.getPhonePropsByType = function(p_userCtx) {
		var phoneTypeMap = new Object();

		var prefix = "phtype";
		for (var index = 1; index <= 4; index++) {
			updatePhoneTypeMap(phoneTypeMap, p_userCtx, prefix, index);
		}
		prefix = "donphtype";
		for (var index = 1; index <= 2; index++) {
			updatePhoneTypeMap(phoneTypeMap, p_userCtx, prefix, index);
		}
		return phoneTypeMap;

	};

	/*
	 * Adds phone type data to p_phoneTypeMap
	 * p_phoneTypeMap - Map of phone types
	 * p_userCtx - userContext
	 * p_prefix - type of phone (phtype, donphtype)
	 * p_index - index of phone type
	 */
	updatePhoneTypeMap = function(p_phoneTypeMap, p_userCtx, p_prefix, p_index) {
		var fieldName = p_prefix + p_index + "def";
		if (p_userCtx[fieldName]) {
			var reqdName = p_prefix + p_index + "req";
			var mobileName = p_prefix + p_index + "mob";
			var tmpType = p_prefix == "phtype" ? "tmpphtype" + p_index : "dontmpphtype" + p_index;
			var lblName = p_prefix + p_index + "lbl";
			var phonePropsObj = {
				reqd : p_userCtx[reqdName],
				changeType : p_userCtx[tmpType],
				mobile : p_userCtx[mobileName],
				title : p_userCtx[lblName]
			};
			p_phoneTypeMap[p_userCtx[fieldName]] = phonePropsObj;
		}
	};

	et.evenue.getPhoneBook = function(p_userCtx, p_phones, p_shuffleMobile, p_cartType) {
		var phoneList = [];
		var counter = 0;
		var phone;
		var mobileIndex = -1;
		var noDonPhonesConfigured = false;
		noDonPhonesConfigured = !(p_userCtx.donphtype1def || p_userCtx.donphtype2def);

		if (!p_cartType || p_cartType.indexOf("T") != -1 || noDonPhonesConfigured) {
			var prefix = "phtype";
			for (var index = 1; index <= 4; index++) {
				phone = getPhone(p_phones, prefix, p_userCtx, index);
				if (phone) {
					mobileIndex = phone.mobile == 1 ? counter : mobileIndex;
					phoneList[counter] = phone;
					counter++;
				}
			}
		}
		if (!p_cartType || p_cartType.indexOf("D") != -1) {
			var prefix = "donphtype";
			for (var index = 1; index <= 2; index++) {
				phone = getPhone(p_phones, prefix, p_userCtx, index);
				if (phone) {
					mobileIndex = phone.mobile == 1 ? counter : mobileIndex;
					phoneList[counter] = phone;
					counter++;
				}
			}
		}

		if (mobileIndex === -1) {
			var mobile = getMobile(p_phones, p_userCtx);
			if (mobile) {
				mobileIndex = counter;
				phoneList[counter] = mobile;
				counter++;
			}
		}

		//reshuffle mobile type phone to th elast in the list only if this method is called from Enter Phones
		if (mobileIndex >= 0 && p_shuffleMobile == "1" && p_userCtx.optinreqflg == "1") {
			var mobilePhone = phoneList[mobileIndex];
			phoneList.push(mobilePhone);
			phoneList.splice(mobileIndex, 1);
		}
		return phoneList;
	};

	/*
	 * when
	 * R.setup check message opt-in
	 * then return mobile ignore phone type
	 *
	 * the fix is display mobile when opt-in checked
	 */
	getMobile = function(p_phones, p_userCtx) {
		if (!p_userCtx.optinreqflg || p_userCtx.optinreqflg !== "1") {
			return null;
		}

		var prefix = "phtype";
		for (var index = 1; index <= 4; index++) {
			var phone = getPhone(p_phones, prefix, p_userCtx, index);
			if (phone) {
				if (phone.mobile == 1) {
					return phone;
				}
			}
		}

		var prefix = "donphtype";
		for (var index = 1; index <= 2; index++) {
			var phone = getPhone(p_phones, prefix, p_userCtx, index);
			if (phone) {
				if (phone.mobile == 1) {
					return phone;
				}
			}
		}

		return null;
	};

	/*
	 * Returns phone data for phone type
	 * p_phones - list of PtrnPhoneVos
	 * p_prefix - type of phone (phtype, donphtype)
	 * p_p_userCtx - userContext
	 * p_index - index of phone type
	 */
	getPhone = function(p_phones, p_prefix, p_userCtx, p_index) {
		var phone = null;
		var fieldName = p_prefix + p_index + "def";
		if (p_userCtx[fieldName]) {
			var reqdName = p_prefix + p_index + "req";
			var mobileName = p_prefix + p_index + "mob";
			var lblName = p_prefix + p_index + "lbl";
			var phone = et.getValueFromListByField(p_phones, EC.TYPE, p_userCtx[fieldName]);
			if (!phone) {
				phone = {
					title : p_userCtx[lblName],
					type : p_userCtx[fieldName],
					empty : true
				};
			}
			phone.reqd = p_userCtx[reqdName];
			phone.mobile = p_userCtx[mobileName];
		}
		return phone;
	};

	/*
	 * Returns address that matches p_fieldValue with value in p_fieldName
	 * if address is not found:
	 * p_returnEmptyAddress == true 'new' address will be returned
	 * else null is returned
	 */
	et.evenue.getAddress = function(p_fieldName, p_fieldValue, p_returnEmptyAddress) {
		var address = p_returnEmptyAddress ? {
		} : null;
		if (et.cache.myaccount && et.cache.myaccount.patronVo && et.cache.myaccount.patronVo.PtrnAddressVos) {
			address = et.getValueFromListByField(et.cache.myaccount.patronVo.PtrnAddressVos, p_fieldName, p_fieldValue);
		}
		return address;
	};

	/*
	 * Adds or updates p_address to list of patron ptrnAddressVos
	 */
	et.evenue.updateAddress = function(p_address) {
		et.evenue.getPatronDetails("-1").done(function(accountVo) {
			if (accountVo && accountVo.patronVo) {
				var patronVo = accountVo.patronVo;
				if (!patronVo.PtrnAddressVos) {
					patronVo.PtrnAddressVos = [];
				}
				var existingAddress = patronVo.PtrnAddressVos.length > 0 ? et.getValueFromListByField(patronVo.PtrnAddressVos, EC.TYPE, p_address.type) : null;
				if (existingAddress) {
					$.extend(existingAddress, p_address);
				} else {
					patronVo.PtrnAddressVos.push(p_address);
				}
			}
		});
	};

	/*
	 * Update/Add phones to patronVo
	 * p_phones - list of phones to be updated/added
	 */
	et.evenue.updatePhones = function(p_phones) {
		if (p_phones) {
			et.evenue.getPatronDetails(-1).done(function(myAccountVo) {
				if (myAccountVo && myAccountVo.patronVo) {
					var patronVo = myAccountVo.patronVo;
					if (!patronVo.PtrnPhoneVos) {
						patronVo.PtrnPhoneVos = [];
						patronVo.PtrnPhoneVos = p_phones;
					} else {
						var existingPhone;
						$.each(p_phones, function(index, phone) {
							existingPhone = et.getValueFromListByField(patronVo.PtrnPhoneVos, EC.TYPE, phone.type);
							if (existingPhone) {
								$.extend(existingPhone, phone);
							} else {
								patronVo.PtrnPhoneVos.push(phone);
							}
						});
					}
					et.cache.myaccount.patronVo.PtrnPhoneVos = patronVo.PtrnPhoneVos;
				}
			});
		}
	};

	/*
	 * Adds or updates a list of p_addresses to list of patron ptrnAddressVos
	 */
	et.evenue.updateAddresses = function(p_addresses) {
		var deferred = $.Deferred();
		et.evenue.getUserContext().done(function(p_userContext) {
			et.evenue.getPatronDetails(p_userContext.patronId).done(function(p_accountVo) {
				if (p_accountVo) {
					if (!p_accountVo.patronVo.PtrnAddressVos) {
						et.cache.myaccount.patronVo.PtrnAddressVos = [];

					}
					if (p_addresses) {
						if (p_addresses.length > 0) {
							et.evenue.getAddressLabelMap().done(function(labelMap) {
								for (var i = 0; i < p_addresses.length; i++) {
									var existingAddress = et.getValueFromListByField(p_accountVo.patronVo.PtrnAddressVos, EC.TYPE, p_addresses[i].type);
									if (existingAddress) {
										$.extend(existingAddress, p_addresses[i]);
									} else {
										var labelValue = labelMap[p_addresses[i].type];
										if (labelValue) {
											p_addresses[i].title = labelValue.label;
											et.cache.myaccount.patronVo.PtrnAddressVos.push(p_addresses[i]);
										}
									}
								}
								deferred.resolve(labelMap);
							});
							return deferred.promise();
						}
					}
				}
				deferred.resolve(p_accountVo);
			});
			deferred.resolve(p_userContext);
		});

	};

	et.evenue.useAddressContent = function(p_userContext, p_cartType, p_labelType, p_title) {
		var cbVal;
		var cbText;
		if (!p_cartType || p_cartType.indexOf("T") != -1) {
			if (p_labelType == EC.TB_LABEL_TYPE) {
				cbVal = p_userContext.billaddrtypedef;
				//cbText = i18n.tkt_bill_addr_title;
			} else if (p_labelType == EC.TS_LABEL_TYPE) {
				cbVal = p_userContext.shipaddrtypdef;
				//cbText = i18n.tkt_ship_addr_title;
			}
		}
		if (!p_cartType || p_cartType.indexOf("D") != -1) {
			if (p_labelType == EC.FB_LABEL_TYPE) {
				cbVal = p_userContext.billdonaddrtypedef;
				//cbText = i18n.fund_bill_addr_title;
			} else if (p_labelType == EC.FS_LABEL_TYPE) {
				cbVal = p_userContext.shipdonaddrtypedef;
				//cbText = i18n.fund_ship_addr_title;
			}
		}
		var checkBoxArr = [cbVal, p_title];
		return checkBoxArr;
	};

	et.evenue.isAllAddressesEmpty = function(p_validAddresses) {
		var isAllAddressEmpty = true;
		for (var index = 0; index < p_validAddresses.length; index++) {
			if (!p_validAddresses[index].empty) {
				isAllAddressEmpty = false;
				break;
			}
		}
		return isAllAddressEmpty;
	};
	et.evenue.isAllReqdAddressesExist = function(p_validAddresses) {
		var isAllAddressFull = true;
		for (var index = 0; index < p_validAddresses.length; index++) {
			if (p_validAddresses[index].empty && p_validAddresses[index].reqd == "1") {
				isAllAddressFull = false;
				break;
			}
		}
		return isAllAddressFull;
	};

	/*
	 * validate max length and incomplete address for login account
	 * this function will validate the required addresses in userContext
	 */
	et.evenue.validateAddressMetaData = function(p_validAddresses, p_callBack) {
		var addressTypes = [];

		for (var index = 0; index < p_validAddresses.length; index++) {
			var address = p_validAddresses[index];
			if (address && address.type && !address.empty && address.reqd == "1") {
				addressTypes.push(address.type);
			}
		}

		if (addressTypes.length == 0) {
			p_callBack();
		}

		var deferred = $.Deferred();
		var PATRON_SERVICE_URL = et.rootCtx + "/ws/patron/-1/address";
		var url = PATRON_SERVICE_URL + "/validateMetaData";
		var svr = {
			data : addressTypes,
			url : url,
			type : "POST"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result) {
			if (p_result.status.cd === et.evenue.errorCodes.PARTIAL_ADDR_NOTALLWD) {
				p_callBack(true);
			} else if (p_result.status.cd === 0) {
				p_callBack();
			}

			deferred.resolve(p_result.value);
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};

	et.evenue.processForwardForTktAddress = function(p_userContext, p_addresses, p_incompleteAddr) {

		var forward;
		var tktBillAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userContext.billaddrtypedef);
		var tktShipAddress;
		if (p_userContext.collectaddr == EC.OPTIONAL && p_userContext.shipaddrtypdef) {
			tktShipAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userContext.shipaddrtypdef);
		}
		if (!tktBillAddress && !tktShipAddress) {
			if (et.isMobile()) {

				forward = "editAddress";
				//becoz at least 1 address has content
			} else {
				forward = "checkout";
			}
		} else {
			if (!tktBillAddress || (!tktShipAddress && p_userContext.shipaddrreq == "1") || p_incompleteAddr) {

				if (et.isMobile()) {
					forward = "addressbook";
					//becoz at least 1 address has content
				} else {
					forward = "checkout";
				}
			}
		}

		return forward;

	};

	et.evenue.processForwardForFdAddresses = function(p_userContext, p_addresses, p_incompleteAddr) {
		var forward;
		if (p_userContext.collectdonaddr != EC.NO && p_userContext.billdonaddrtypedef) {
			var funBillAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userContext.billdonaddrtypedef);
			var funShipAddress;
			if (p_userContext.collectdonaddr == EC.OPTIONAL && p_userContext.shipdonaddrtypedef) {
				funShipAddress = et.getValueFromListByField(p_addresses, EC.TYPE, p_userContext.shipdonaddrtypedef);
			}
			if (!funBillAddress && !funShipAddress) {

				if (et.isMobile()) {

					forward = "editAddress";
					//becoz no address has content
				} else {
					forward = "checkout";
				}
			} else {
				if (!funBillAddress || (!funShipAddress && p_userContext.donshipaddrreq == "1") || p_incompleteAddr) {
					if (et.isMobile()) {
						forward = "addressbook";
						//becoz at least 1 address has content
					} else {
						forward = "checkout";
					}
				}
			}
		} else {
			forward = et.evenue.processForwardForTktAddress(p_userContext, p_addresses);
		}
		return forward;
	};
	et.evenue.forwardToEditPhones = function(p_userContext, p_phones) {
		var forward;
		var params = et.evenue.getWizardParams();
		if (params.cartType == "T") {
			return et.evenue.processTicketingPhones(p_userContext, p_phones);
		} else if (params.cartType == "D") {
			if (p_userContext.donphtype1def || p_userContext.donphtype2def) {
				return et.evenue.processFundPhones(p_userContext, p_phones);
			} else {
				return et.evenue.processTicketingPhones(p_userContext, p_phones);
			}
		} else {
			if (et.evenue.processTicketingPhones(p_userContext, p_phones) || et.evenue.processFundPhones(p_userContext, p_phones)) {
				return true;
			}
		}
		return false;
	};
	et.evenue.processTicketingPhones = function(p_userContext, p_phones) {
		var tktPhone1 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.phtype1def);
		var tktPhone2;
		var tktPhone3;
		var tktPhone4;

		if (p_userContext.phtype2def) {
			tktPhone2 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.phtype2def);
		}
		if (p_userContext.phtype3def) {
			tktPhone3 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.phtype3def);
		}
		if (p_userContext.phtype4def) {
			tktPhone4 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.phtype4def);
		}

		var isMobileEmptyAndRequired = et.evenue.processMobilePhones(p_userContext, p_phones);
		if (isMobileEmptyAndRequired) {
			return true;
		}

		if ((!tktPhone1 && p_userContext.phtype1req == "1") || ((!tktPhone2 || tktPhone2 == "") && p_userContext.phtype2req == "1") || ((!tktPhone3 || tktPhone3 == "") && p_userContext.phtype3req == "1") || ((!tktPhone4 || tktPhone4 == "") && p_userContext.phtype4req == "1")) {
			return true;
		}
		return false;
	};

	/*
	 * when
	 *
	 * 1. R.setup check message opt-in
	 * 2. mobile is empty and requried
	 *
	 * then return mobile ignore cart type
	 *
	 * the fix is for EvNavigation.js
	 */
	et.evenue.processMobilePhones = function(p_userContext, p_phones) {
		var mobile = getMobile(p_phones, p_userContext);
		if (mobile && mobile.empty === true && mobile.reqd == "1") {
			return true;
		} else {
			return false;
		}
	};

	et.evenue.processFundPhones = function(p_userContext, p_phones) {
		var fundPhone1;
		var fundPhone2;
		if (p_userContext.donphtype1def) {
			fundPhone1 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.donphtype1def);
		}
		if (p_userContext.donphtype2def) {
			fundPhone2 = et.getValueFromListByField(p_phones, EC.TYPE, p_userContext.donphtype2def);
		}

		var isMobileEmptyAndRequired = et.evenue.processMobilePhones(p_userContext, p_phones);
		if (isMobileEmptyAndRequired) {
			return true;
		}

		if (((!fundPhone1 || fundPhone1 == "") && p_userContext.donphtype1req == "1") || ((!fundPhone2 || fundPhone2 == "") && p_userContext.donphtype2req == "1")) {
			return true;
		}
		return false;
	};

	et.evenue.phoneOptInValChanged = function(p_optInVos, p_origCbVal) {
		return (p_optInVos && p_optInVos.length > 0 && p_origCbVal != undefined && p_optInVos[0].optInFl != p_origCbVal);
	};

	/*
	 * Clears patron from cache
	 */
	et.evenue.clearPatron = function() {
		if (et.cache.myaccount)
			et.cache.myaccount = null;
	};

	/**
	 *
	 *
	 * *****************************        End of Patron Functions     ****************************
	 *
	 *
	 */

	/**
	 *
	 *
	 * *****************************        Start of Payment Functions     ****************************
	 *
	 *
	 */

	/*
	 * Gets Payment Id
	 */
	et.evenue.getPaymentId = function() {
		if (et.cache.payment && et.cache.payment.paymentVos) {
			var paymentLength = et.cache.payment.paymentVos.length;
			if (paymentLength > 0) {
				//find the least id < 0 and decrement it
				var vos = et.cache.payment.paymentVos;
				var leastId = vos[0].id;
				for (var i = 1; i < vos.length; i++) {
					if (vos[i].id < leastId)
						leastId = vos[i].id;
				}
				if (leastId < 0)
					return --leastId;
			} else {
				return -2;
				//start at -2 as the default payment is -1
			}
		}
		return -2;
	};

	/*
	 * Adds payment
	 * Returns false if payment cannot be added
	 * Returns true when no cache is present
	 * Returns the id when able to add successfully.
	 */
	et.evenue.addPaymentVo = function(p_vo, p_mode, p_dupCheck) {
		p_dupCheck = typeof p_dupCheck !== 'undefined' ? p_dupCheck : true;
		if (!et.cache.payment) {
			return true;
		} else {
			if (!et.cache.payment.paymentVos) {
				et.cache.payment.paymentVos = [];
			}
			if (p_dupCheck) {
				if (this.isDuplicatePmt(p_vo)) {
					return false;
				}
			}
			if (p_mode == PAYMENT_ADD_MODE) {
				p_vo.id = this.getPaymentId();
				et.cache.payment.paymentVos.push(p_vo);
			} else if (p_mode == PAYMENT_EDIT_MODE) {
				var result = this.updatePaymentById(p_vo.id, p_vo);
				if (!result) {
					return false;
				}
			}
		}
		return p_vo.id;
	};

	/*
	 * Updates payment based on payment id
	 */
	et.evenue.updatePaymentById = function(p_id, p_vo) {
		if (et.cache.payment) {
			var vos = et.cache.payment.paymentVos;
			if (vos) {
				for (var i = 0; i < vos.length; i++) {
					if (vos[i].id == p_id) {
						vos[i] = p_vo;
						return true;
					}
				}
			}
			return false;
		}
		return false;
	};

	/*
	 * Returns payment based on payment id
	 */
	et.evenue.getPaymentVoById = function(p_id) {
		if (et.cache.payment) {
			var vos = et.cache.payment.paymentVos;
			if (vos) {
				for (var i = 0; i < vos.length; i++) {
					if (vos[i].id == p_id)
						return vos[i];
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	/*
	 * Deletes payment based on payment id
	 */
	et.evenue.deletePaymentVoById = function(p_id) {
		if (et.cache.payment) {
			var payments = et.cache.payment.paymentVos;
			if (payments) {
				for (var i = 0; i < payments.length; i++) {
					if (payments[i].id == p_id) {
						payments.splice(i, 1);
						return true;
					}
				}
			}
		}
		return false;
	};

	/*
	 * Deletes payment based on payment type
	 */
	et.evenue.deletePaymentVoByType = function(p_type) {
		var retVal = false;
		if (et.cache.payment) {
			var payments = et.cache.payment.paymentVos;
			if (payments) {
				for (var i = 0; i < payments.length; i++) {
					if (payments[i].paymodeType == p_type) {
						payments.splice(i, 1);
						retVal = true;
					}
				}
			}
		}
		return retVal;
	};

	et.evenue.isDuplicatePmt = function(p_vo) {
		var payments = et.cache.payment ? et.cache.payment.paymentVos : null;
		if (payments) {
			var payment, paymentNmbr;
			for (var i = 0; i < payments.length; i++) {
				payment = payments[i];
				paymentNmbr = payment.paymentNmbr;
				if (payment.type == p_vo.type) {
					if (payment.storeValueId > 0) {
						//TODO you may not want to check stored values at all.
						if (et.evenue.getLastFour(paymentNmbr) == et.evenue.getLastFour(p_vo.paymentNmbr)) {
							return true;
						}
					} else {
						if (paymentNmbr == p_vo.paymentNmbr) {
							if (et.evenue.constants.PAYMENT_TYPE.ECHECK == p_vo.type) {
								if (payment.routingNmbr == p_vo.routingNmbr)
									return true;
							}
							return true;
						}
					}
				}
			}
		}
		return false;
	};

	/*
	 * Returns the paymode of type 'EC'
	 */
	et.evenue.getBankAcctPaymode = function() {
		var paymodes = et.cache.userContext.paymodes;
		if (paymodes) {
			var paymode;
			for (var p = 0; p < paymodes.length; p++) {
				paymode = paymodes[p];
				if (paymode.type == et.evenue.constants.PAYMENT_TYPE.ECHECK) {
					return paymode;
				}
			}
		}
		return null;
	};

	/*
	 * Checks if credit card is expired
	 */
	et.evenue.isCCExpired = function(p_mth, p_yr) {
		var mm = new Date().getMonth() + 1;
		var yyyy = new Date().getFullYear();

		if (p_yr > yyyy) {
			return false;
		} else if (p_yr < yyyy) {
			return true;
		} else if (p_mth < mm) {
			return true;
		} else {
			return false;
		}
	};

	/*
	 * Returns paymode based on p_paymodeCd
	 */
	et.evenue.getPaymode = function(p_paymodeCd) {
		var paymode = et.getValueFromListByField(et.cache.userContext.paymodes, PAYMODE_CD, p_paymodeCd);
		return paymode;
	};

	/*
	 * Returns stored card info : Visa XXXX1234
	 * p_showExpirationDate == true: Visa XXXX1234, Exp 7/2013
	 */
	et.evenue.getCreditCardInfo = function(p_storedCreditCard, p_showExpirationDate) {
		var cardInfo = "";
		cardInfo += et.evenue.constants.CREDIT_CARD_LIST[p_storedCreditCard.type] + " ";
		cardInfo += et.evenue.constants.PAYMENT_METHOD_MASK + p_storedCreditCard.number;
		cardInfo += ( p_showExpirationDate ? ", Exp " + et.evenue.formatMonth(p_storedCreditCard.expireMonth) + "/" + p_storedCreditCard.expireYear : "");
		return cardInfo;
	};

	/*
	 * when month is a sigle digit, append a 0 in front of month
	 * return month as MM
	 */
	et.evenue.formatMonth = function(p_month) {
		var month;
		if (p_month) {
			if (p_month < 10) {
				month = "0" + p_month;
			} else {
				month = p_month;
			}
		}

		return month;
	};

	/*
	 * Returns bank account info : Checking Account xxxx4112
	 */
	et.evenue.getBankAccountInfo = function(p_storedBankAccount) {
		var cardInfo = "";
		cardInfo = (p_storedBankAccount.accountType == EC.CHECKING) ? i18n.option_checking : i18n.option_savings;
		cardInfo += " " + et.evenue.constants.PAYMENT_METHOD_MASK + p_storedBankAccount.accountNmbr;
		return cardInfo;
	};

	/*
	 * Returns payment card info : Visa XXXX1234
	 * p_showExpirationDate == true: Visa XXXX1234, Exp 7/2013
	 * For bank account : Checking Account xxxx4112
	 */
	et.evenue.getPaymentCardInfo = function(p_payment, p_showExpirationDate) {
		var cardInfo = "";
		if (p_payment.paymodeType == EC.ECHECK) {
			cardInfo = (p_payment.bankAcctType == EC.CHECKING) ? i18n.option_checking : i18n.option_savings;
			cardInfo += " Account " + et.evenue.constants.PAYMENT_METHOD_MASK + p_payment.paymentNmbr;
		} else if (p_payment.paymodeType == EC.CREDITCARD) {
			var paymode = et.evenue.getPaymode(p_payment.paymodeCd);
			cardInfo += paymode ? et.evenue.constants.CREDIT_CARD_LIST[paymode.ccType] + " " : "";
			cardInfo += et.evenue.maskAccountNumber(p_payment.paymentNmbr);
			cardInfo += ( p_showExpirationDate ? ", Exp " + et.evenue.formatMonth(p_payment.ccMonth) + "/" + p_payment.ccYear : "");
		} else if (p_payment.paymodeType == EC.OTHER) {
			var paymode = et.evenue.getPaymode(p_payment.paymodeCd);
			cardInfo = "Other (" + paymode.name + ")";
		}
		return cardInfo;
	};

	/*
	 * Return content of Cvv Modal dialog
	 */
	et.evenue.getCvvContent = function() {
		if (!m_cvvContent) {
			var ul = $("<ul></ul>").addClass("listview listview-static");
			var li = getLi().append(getDiv().html(i18n.msg_cvn));
			ul.append(li);
			li = getLi().append(getDiv().html(i18n.msg_purpose));
			ul.append(li);
			li = getLi().append(getDiv().html(i18n.msg_card));
			ul.append(li);
			li = getLi().append(getDiv().append($('<img alt="circle" src="~{url.prefix}inf/common/images/ImgCvvMCVisa.gif" width="179" height="101" />')));
			ul.append(li);
			li = getLi().append(getDiv().html(i18n.msg_ae));
			ul.append(li);
			li = getLi().append(getDiv().append($('<img alt="circle" src="~{url.prefix}inf/common/images/ImgCvvAmex.gif" width="179" height="101" />')));
			ul.append(li);
			m_cvvContent = ul;
		}
		return m_cvvContent;
	};

	/**
	 *
	 *
	 * *****************************        End of Payment Functions     ****************************
	 *
	 *
	 */

	/**
	 *
	 *
	 * *****************************        Start of Order History Functions     ****************************
	 *
	 *
	 */

	/*
	 * method to call getOrderHistory restful service and cache results as neededdflt
	 */
	et.evenue.getOrderHistory = function(p_patronId, p_requestType, p_sortOrder) {
		var deferred = $.Deferred(), sortOrder = p_sortOrder || et.evenue.constants.DFLT_SORT_ORDER;

		var cache = et.evenue.getOrderHistoryCache(p_requestType);
		if (cache) {

			deferred.resolve(cache);

		} else {

			var svr = {
				url : HISTORY_SERVICES_URL + p_patronId + ORDER_HISTORY_SERVICE_URL,
				data : {
					requestType : p_requestType,
					maxItems : et.evenue.getLoadMoreThreshold(),
					sortType : sortOrder
				},
				type : HTTP_GET
			};

			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data) {
				if (data.status.cd == 0) {

					et.evenue.setOrderHistoryCache(p_requestType, data);
				}
				deferred.resolve(data);
			}).fail(function() {
				deferred.reject();
			});

		}
		return deferred.promise();
	};

	/**
	 * loads more order history onto a given view.
	 * Author: Nathan LaFranchi
	 * @param params Parameters to be sent to the server.
	 * @param p_view View that the line items will be appended to.
	 * @param params.patronId Id of patron to load more items for
	 * @param params.callback Function to be called to populate the flex grid.
	 *
	 */
	et.evenue.loadMoreOrderHistory = function(params, p_view) {
		var deferred = $.Deferred();
		var svr = {
			url : HISTORY_SERVICES_URL + params.patronId + LOAD_MORE_ORDER_HISTORY,
			data : params,
			type : "GET"
		};

		var jqxhr = et.ajax(svr);

		jqxhr.done(function(data) {
			if (data.status.cd == 0) {
				// Append additional line items to grid
				params.callback(data.value.lineItemOHVos);
				// Adding new line items to cache.
				var cache = et.evenue.getOrderHistoryCache(params.requestType);
				cache.value.lineItemOHVos.reverse();
				$.each(cache.value.lineItemOHVos, function(index, lineItem) {
					data.value.lineItemOHVos.unshift(lineItem);
				});
				et.evenue.setOrderHistoryCache(params.requestType, data);

				et.evenue.setLoadMoreBtnVisible(data.value.moreItems, p_view);
				deferred.resolve(data);
			} else {
				et.evenue.setLoadMoreBtnVisible(false, p_view);
				et.displayMessagePanel({
					msgs : [data.status.mg],
					element : p_view.find("#error-panel")
				});
				p_view.find(".text-instruction").remove();
				p_view.find(".panel-default").remove();
			}

		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	et.evenue.setOrderHistoryCache = function(p_requestType, p_data) {
		if (p_requestType == "ORDERS") {
			et.cache.orderHistory = p_data;
		}
		if (p_requestType == "PACKAGES") {
			et.cache.packageHistory = p_data;
		}
		if (p_requestType == "TRANSFERS") {
			et.cache.transferHistory = p_data;
		}
	};

	et.evenue.getOrderHistoryCache = function(p_requestType) {
		if (p_requestType == "ORDERS") {
			return et.cache.orderHistory;
		}
		if (p_requestType == "PACKAGES") {
			return et.cache.packageHistory;
		}
		if (p_requestType == "TRANSFERS") {
			return et.cache.transferHistory;
		}
	};

	/*
	 * Returns select drowp with Order History options
	 */
	et.evenue.getOrderHistorySelect = function() {
		var deferred = $.Deferred();
		et.evenue.getOrderHistory(-1, "ORDERS").done(function(orderHistory) {
			var select = getSelect(getOrderHistorySelectOptions(orderHistory.value));
			deferred.resolve(select);
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};

	et.evenue.getOrderHistorySelectOnStart = function(orderHistory) {
		var deferred = $.Deferred();
		var select = getSelect(getOrderHistorySelectOptions(orderHistory.value));
		deferred.resolve(orderHistory, select);
		return deferred.promise();
	};
	/*
	 * Creates list of Order History Select options based on flags
	 */
	var getOrderHistorySelectOptions = function(p_orderHistory) {
		var options = [];

		if (!p_orderHistory)
			return options;
		options.push({
			id : "orderHistory",
			text : i18n.lbl_order_history
		});

		if (p_orderHistory.viewExchange == EC.YES)
			options.push({
				id : "exchangeHistory",
				text : i18n.opt_exchanges
			});
		if (p_orderHistory.viewPackage == EC.YES)
			options.push({
				id : "packageHistory",
				text : i18n.opt_item_packages
			});
		if (p_orderHistory.vieweVenueTransfer == EC.YES)
			options.push({
				id : "transferHistory",
				text : i18n.opt_transfers
			});
		if (p_orderHistory.viewRenewal == EC.YES)
			options.push({
				id : "renewalHistory",
				text : i18n.opt_renewals
			});
		if (p_orderHistory.viewReturn == EC.YES)
			options.push({
				id : "returnHistory",
				text : i18n.opt_returns
			});
		return options;
	};

	/**
	 *
	 *
	 * *****************************        End of Order History Functions     ****************************
	 *
	 *
	 */

	/*
	 * Split exchange events list into out and in arrays
	 */
	et.evenue.getExchangeOutInEvents = function(p_events) {
		var exchangeOutEvents = new Array();
		var exchangeInEvents = new Array();

		$.each(p_events, function(index, eventVo) {
			if (eventVo.type == "O") {
				exchangeOutEvents.push(eventVo);
			} else if (eventVo.type == "I") {
				exchangeInEvents.push(eventVo);
			}
		});

		var all_events = new Array();
		all_events.push(exchangeOutEvents);
		all_events.push(exchangeInEvents);
		return all_events;
	};

	et.evenue.getSeatBlock = function(p_seat) {
		if (!p_seat) {
			return "";
		}

		//Bug 52804
		if (!p_seat.row && !p_seat.seats && !p_seat.level) {
			return "";
		}

		//----------Gen Adm if both row and seats are null
		if (p_seat.level === "Gen Adm") {
			return i18n.lbl_gen_adm;
		}

		//-----------if level exists but no section, just set level - Bug 53403
		var seats = p_seat.section ? p_seat.level + ":" + p_seat.section : p_seat.level;

		//-----------Sectional Gen Adm
		if (!p_seat.row || !p_seat.seats) {
			return seats + ": " + i18n.lbl_gen_adm;
		}

		//----------Reserved seats
		seats = seats + ", " + i18n.lbl_row + " " + p_seat.row;
		if (p_seat.seats) {
			var seatLabel = i18n.lbl_seat;
			if (p_seat.seats.indexOf('-') > -1) {
				seatLabel = i18n.lbl_seats;
			}
			seats = seats + ", " + seatLabel + " " + p_seat.seats;
		}
		return seats;
	};

	et.evenue.isTicket = function(p_type) {
		return p_type == "C" || p_type == "S";
	};

	et.evenue.isCombo = function(p_type) {
		return p_type == "C";
	};

	et.evenue.isNonTicket = function(p_type) {
		return p_type != "C" && p_type != "S";
	};

	/*
	 * If all events are in same facility return facility name
	 */
	et.evenue.getFacilityForCombo = function(p_events, p_facilityField) {
		var facility = "";
		var event;
		for (var i = 0; i < p_events.length; i++) {
			event = p_events[i];
			if (i == 0)
				facility = event[p_facilityField];
			else {
				if (facility != event[p_facilityField])
					return "";
			}
		}
		return facility;
	};

	et.evenue.getWizardParams = function() {
		var action = et.getPageParameter("action");
		var cartType = et.getPageParameter("cartType");
		var continueUrl = et.getPageParameter("continueUrl");
		var gcUrl = et.getPageParameter("gcUrl");
		var studentType = et.getPageParameter("st");
		var process = et.getPageParameter("process");
		var evm = et.getPageParameter("evm");
		var params = {
			"action" : action,
			"cartType" : cartType,
			"continueUrl" : continueUrl,
			"gcUrl" : gcUrl,
			"st" : studentType,
			"process" : process,
			"evm" : evm
		};
		return params;

	};

	/*
	 * Displays ItemInfo region
	 * p_div - id of div where region will be displayed
	 * p_item - json used to populate the region, format below:
	 * 	{
	 * 		logo: url to logo
	 * 		name: item name
	 * 		date: event date
	 * 		facility: item facility
	 * 		season: item season
	 * 		pl: price level
	 * 		pt: price type
	 * 		seats: seats
	 * 	}
	 */
	et.evenue.displayItemInfoRegion = function(p_div, p_item) {
		var itemInfoRegion = et.provide(et.evenue.regions.ItemInfo.name);
		itemInfoRegion.go(p_div, function() {
			itemInfoRegion.contextChanged(p_item);
		});
	};

	et.evenue.getExchItemInfoRegion = function(p_postSaleEventSeatsVo) {
		//Strip away all HTML tags for eventName
		var eventName = $("<div>").html(p_postSaleEventSeatsVo.eventName)[0].innerText || $("<div>").html(p_postSaleEventSeatsVo.eventName)[0].textContent;
		var $container = $("<div/>", {
			style : "word-break:break-word;-ms-word-break:break-all;",
			class : "box-b no-padding"
		});
		var boxLogoDiv = $("<div/>", {
			class : "box-logo"
		});
		var $logo = $("<img/>", {
			alt : eventName,
			src : et.rootCtx + "/www/ss/evenue/common/images/na.gif",
			"data-logo" : p_postSaleEventSeatsVo.seasonCd + ":" + p_postSaleEventSeatsVo.eventCd
		}).appendTo(boxLogoDiv);

		boxLogoDiv.appendTo($container);

		var $content = $("<div/>", {
			class : "box-content"
		}).appendTo($container);

		$("<label>").text(eventName.trim()).addClass("text-major").appendTo($content);

		if (p_postSaleEventSeatsVo.eventDateTime !== "") {
			$("<label/>", {
				text : p_postSaleEventSeatsVo.eventDateTime
			}).appendTo($content);
		}

		if (p_postSaleEventSeatsVo.facName && !p_postSaleEventSeatsVo.hideFacilityFl) {
			$("<label/>", {
				text : p_postSaleEventSeatsVo.facName
			}).appendTo($content);
		}

		return $container;
	};

	/*
	 * Appends item info to html element
	 * p_element - element to which info info is appended
	 * p_data - json used to populate the region, format below:
	 * {
	 * 		itemId: seasonCd_eventId - used for class of logo and id of side element
	 * 		content: [ ]
	 * 		padding: true/false - defaults to true
	 * 		sideElement: img/checkbox - element in asideDiv
	 * }
	 */
	et.evenue.getItemInfoRegion = function(p_element, p_data) {
		var dfltData = {
			itemId : "",
			content : [],
			padding : true,
			sideElement : null
		};
		var data = p_data ? p_data : dfltData;
		var boxDiv = getDiv().addClass("box-b");
		data.padding ? boxDiv.addClass("no-padding") : null;
		var contentDiv = $("<div style='word-break:break-word;-ms-word-break:break-all'/>").addClass("box-content");
		var label, dataList = data.content;
		$.each(dataList, function(index, dataItem) {
			var itemName = $("<div>").html(dataItem)[0].innerText || $("<div>").html(dataItem)[0].textContent;
			//Clear all HTML tags
			if (data.sideElement) {
				label = getLabel().text(itemName.trim()).prop("for", data.itemId).addClass(index == 0 ? "text-major" : "");
			} else {
				label = getDiv().html(itemName.trim()).addClass(index == 0 ? "text-major" : "");
			}
			contentDiv.append(label);
		});
		var asideDiv = getDiv().addClass("box-aside");
		if (data.sideElement) {
			asideDiv.append(getSideElement(data.sideElement, data.itemId));
		}

		var eventName = $("<div>").html(data.content[0])[0].innerText || $("<div>").html(data.content[0])[0].textContent;
		//Append the logo if it's the event header, or if it has the showLogo flag = true
		if (p_data.seats != null || p_data.showLogo) {
			var boxLogo = getDiv().addClass("box-logo");
			boxLogo.append(getImg().attr("alt", eventName).attr("src", et.rootCtx + "/www/ss/evenue/common/images/na.gif").attr("data-logo", data.itemId));
			boxDiv.append(boxLogo);
		}

		boxDiv.append(contentDiv);
		boxDiv.append(asideDiv);
		p_element.append(boxDiv);
	};

	et.evenue.getItemInfo = function(p_item, p_seatStr) {
		var result = [p_item.name];
		if (p_item.eventDtStr)
			result.push(et.evenue.formatEventDatetime(p_item.eventDtStr, p_item.eventDtStrTo, false, false, p_item.timeTba == "0" ? false : true));
		if (p_item.facility)
			result.push(p_item.facility);
		if (p_item.season)
			result.push(p_item.season);
		if (p_item.pl)
			result.push(p_item.pl);
		if (p_item.pt) {
			var pts = p_item.pt.split("<br>");
			$.each(pts, function(index, pt) {
				result.push(pt);
			});
		}
		if (p_seatStr) {
			var seats = p_seatStr.split("<br>");
			$.each(seats, function(index, seatStr) {
				result.push(seatStr);
			});
		}
		return result;
	};

	et.evenue.getItemKeyVo = function(p_keyStr) {
		var index = p_keyStr.indexOf(":");
		if (index != -1) {
			var keyVo = {
				"seasonCd" : p_keyStr.substring(0, index),
				"itemCd" : p_keyStr.substring(index + 1)
			};

			return keyVo;

		}

		return null;
	};

	et.evenue.getPostSaleSeatKeyVos = function(p_keyStrs) {
		if (p_keyStrs.length === 0) {
			return;
		}
		var keyVos = [];
		for (var index = 0; index < p_keyStrs.length; index++) {
			var keyStr = p_keyStrs[index];
			if (keyStr !== "") {
				keyVos.push(this.getPostSaleSeatKeyVo(keyStr));
			}
		}
		return keyVos;
	};

	/*
	 * Returns itemKeyVo from seatId string
	 */
	et.evenue.getPostSaleSeatKeyVo = function(p_string) {
		var keyVo;
		if (p_string) {
			var colonIndex = p_string.indexOf(":");
			var orderLineId = p_string.substring(0, colonIndex);

			p_string = p_string.substring(colonIndex + 1);
			colonIndex = p_string.indexOf(":");
			var orderLineItemId = p_string.substring(0, colonIndex);

			p_string = p_string.substring(colonIndex + 1);
			var seatId = p_string;

			keyVo = {
				"orderLineId" : orderLineId,
				"orderLineItemId" : orderLineItemId,
				"seatId" : seatId
			};
		}
		return keyVo;
	};

	/*
	 * Returns the transfer fee amount
	 */
	et.evenue.getTransferFeeAmount = function(p_events, p_userContext) {
		var seasonList = [];
		$.each(p_events, function(index, item) {
			var itemKeyVo = et.evenue.getItemKeyVo(item);
			if (itemKeyVo) {
				if (seasonList.indexOf(itemKeyVo.seasonCd) < 0)
					seasonList.push(itemKeyVo.seasonCd);
			}
		});

		var amount = 0;
		if (seasonList.length > 0) {
			var postSaleSeasonVos = p_userContext.transferPostSaleSeasonVos;
			$.each(seasonList, function(index, seasonCd) {
				var vo = et.getValueFromListByField(postSaleSeasonVos, "seasonCd", seasonCd);
				if (vo)
					amount += vo.amount;
			});
		}
		return amount;
	};

	/*
	 * Returns eventInfo created from PostSaleEventSeatsVo
	 * p_eventInfo: PostSaleEventSeatsVo
	 * p_sideElement: img/checkbox/null - element in asideDiv
	 */
	et.evenue.getEventInfoWithSeasonName = function(p_eventInfo, p_sideElement) {
		var itemInfo = et.evenue.getEventInfo(p_eventInfo, p_sideElement);
		itemInfo.content.push(p_eventInfo.seasonName);
		return itemInfo;
	};

	/*
	 * Returns eventInfo created from PostSaleEventSeatsVo
	 * p_eventInfo: PostSaleEventSeatsVo
	 * p_sideElement: img/checkbox/null - element in asideDiv
	 */
	et.evenue.getEventInfo = function(p_eventInfo, p_sideElement) {
		var itemInfo = {
			itemId : p_eventInfo.seasonCd + ":" + p_eventInfo.eventCd,
			content : [p_eventInfo.eventName, p_eventInfo.eventDateTime, p_eventInfo.hideFacilityFl === true ? "" : p_eventInfo.facName],
			sideElement : p_sideElement
		};
		if (p_eventInfo.postSaleSeatVos && p_eventInfo.postSaleSeatVos.length > 0) {
			itemInfo.seats = [];
			$.each(p_eventInfo.postSaleSeatVos, function(index, seat) {
				var rowSeat = seat.levelSecDispValue;
				if (seat.seatingType == et.evenue.constants.GA) {
					rowSeat = i18n.lbl_gen_adm;
				} else if (seat.seatingType == et.evenue.constants.SECGA) {
					rowSeat += ": " + i18n.lbl_gen_adm;
				} else {
					if (seat.rowCd)
						rowSeat += ", " + i18n.lbl_row + " " + seat.rowCd;
					if (seat.seatCd)
						rowSeat += ", " + i18n.lbl_seat + " " + seat.seatCd;
				}
				var content = [(rowSeat), (seat.priceLevelName + ", " + seat.priceTypeName)];

				if (seat.barcode && (seat.seatingType == et.evenue.constants.GA || seat.seatingType == et.evenue.constants.SECGA)) {
					content.push("Barcode " + seat.barcode);
					//content.push(seat.barcode);
				}
				if (seat.price && seat.price >= 0) {
					content.push((et.evenue.getFormattedNumber(seat.price, true)));
				}
				itemInfo.seats.push({
					itemId : seat.orderLineId + ":" + seat.orderLineItemId + ":" + seat.seatId,
					content : content,
					sideElement : "checkbox"
				});
			});
		} else {
			// These are misc items.  They have no PostSaleSeatVos
			itemInfo.seats = [];
			if (p_eventInfo.barcodes) {
				var barcodes = p_eventInfo.barcodes.split("^");
				$.each(barcodes, function(index, barcode) {
					var content = [("BARCODE  " + barcode)];
					itemInfo.seats.push({
						itemId : barcode,
						content : content,
						sideElement : "checkbox"
					});
				});
			}
		}
		return itemInfo;
	};

	/*
	 * Returns seats details for previously selected Post Sale events
	 */
	et.evenue.getPostSaleSeatDetails = function(p_processId, p_patronId, p_keyVos) {
		var deferred = $.Deferred();
		var ORDER_SERVICE_URL = PATRON_SERVICES_URL + p_patronId + "/order";
		var seatDetailsUrl = ORDER_SERVICE_URL + "/seatdetails" + "?type=" + p_processId;

		var svr = {
			url : seatDetailsUrl,
			data : p_keyVos,
			type : "POST"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {
			deferred.resolve(data);
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * Returns PostSaleVo for specified processId and updates cache
	 */
	et.evenue.getPostSaleDetails = function(p_patronId, p_processId) {
		var deferred = $.Deferred();
		var postSaleDetails = et.cache.postSaleDetails;
		if (postSaleDetails) {
			deferred.resolve(postSaleDetails);
		} else {
			var ORDER_SERVICE_URL = PATRON_SERVICES_URL + p_patronId + "/order/postsale?type=" + p_processId;

			var svr = {
				url : ORDER_SERVICE_URL
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(data) {
				if (data.status.cd == 0) {
					et.cache.postSaleDetails = data.value;
					deferred.resolve(data.value);
				} else {
					deferred.reject();
				}
			}).fail(function() {
				deferred.reject();
			});
		}
		return deferred.promise();
	};

	/*
	 * Posts PostSaleVo to the /postsale service and updates the cache
	 */
	et.evenue.setPostSaleDetails = function(p_patronId, p_postSaleVo) {
		var deferred = $.Deferred();
		var ORDER_SERVICE_URL = PATRON_SERVICES_URL + p_patronId + "/order/postsale";
		var svr = {
			url : ORDER_SERVICE_URL,
			data : p_postSaleVo,
			type : 'POST'
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {
			if (et.handleException(data)) {
				et.cache.postSaleDetails = data.value;
				deferred.resolve(data.value);
			} else {
				deferred.reject();
			}
		}).fail(function(data) {
			et.handleFailure(data);
		});
		return deferred.promise();
	};

	/*
	 * Confirms post sale transaction and updates the cache
	 */
	et.evenue.confirmPostSale = function(p_patronId, p_postSaleVo) {
		var deferred = $.Deferred();
		var ORDER_SERVICE_URL = PATRON_SERVICES_URL + p_patronId + "/order/postsaleorder";
		var svr = {
			url : ORDER_SERVICE_URL,
			data : p_postSaleVo,
			type : 'POST'
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {
			if (et.handleException(data)) {
				et.cache.postSaleDetails = data.value;
				deferred.resolve(data.value);
			} else {
				deferred.reject();
			}
		}).fail(function(data) {
			et.handleFailure(data);
		});
		return deferred.promise();
	};

	et.evenue.exchangeCartAjax = function(p_cancelExchange, p_callBack) {
		var deferred = $.Deferred();
		var EXCHANGE_SERVICE_URL = et.rootCtx + "/ws/exchange/-1";
		var exchangeCartUrl = EXCHANGE_SERVICE_URL + "/exchangeCart";
		var svr = {
			url : exchangeCartUrl,
			type : "GET"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result) {
			et.handleException(p_result);
			deferred.resolve(p_result.value);
		}).fail(function(p_result) {
			et.handleFailure(p_result);
			deferred.reject();
		});
		return deferred.promise();
	};

	et.evenue.cancelExchangeAjax = function(p_cancelExchange) {
		var deferred = $.Deferred();
		var EXCHANGE_SERVICE_URL = et.rootCtx + "/ws/exchange/-1";
		var cancelExchangeUrl = EXCHANGE_SERVICE_URL + "/cancelExchange" + "?removeAllItems=" + p_cancelExchange;
		var svr = {
			url : cancelExchangeUrl,
			type : "POST"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result) {
			et.handleException(p_result);
			deferred.resolve(p_result.value);
		}).fail(function(p_result) {
			et.handleFailure(p_result);
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * Returns seat block string built from postSaleSaleVo
	 */
	et.evenue.getPostSaleSeatDisplay = function(p_postSaleSeat) {
		var seatStr = p_postSaleSeat.levelSecDispValue;
		if (p_postSaleSeat.rowCd)
			seatStr += ", " + i18n.lbl_row + " " + p_postSaleSeat.rowCd;
		if (p_postSaleSeat.seatCd)
			seatStr += ", " + getSeatBlock(p_postSaleSeat.seatCd);
		return seatStr;
	};

	/*
	 * Returns seat block string built from postSaleSaleVo
	 */
	et.evenue.getPostSaleSeatBlkDisplay = function(p_postSaleSeat) {
		var seatStr = p_postSaleSeat.levelSecDispValue;
		if (p_postSaleSeat.seatingType == et.evenue.constants.GA)
			seatStr = i18n.lbl_gen_adm;
		else if (p_postSaleSeat.seatingType == et.evenue.constants.SECGA)
			seatStr += ": " + i18n.lbl_gen_adm;
		else {
			if (p_postSaleSeat.rowCd)
				seatStr += ", " + i18n.lbl_row + " " + p_postSaleSeat.rowCd;
			if (p_postSaleSeat.seatCd)
				seatStr += ", " + getSeatBlock(p_postSaleSeat.seatCd);
		}
		return seatStr;
	};

	var getSeatBlock = function(p_seatCd) {
		if (p_seatCd.indexOf(",") > -1) {
			var seats = p_seatCd.split(",");
			if (seats[0] == seats[1]) {
				return i18n.lbl_seat + " " + seats[0];
			} else {
				return i18n.col_seats + " " + seats[0] + "-" + seats[1];
			};
		} else {
			return i18n.lbl_seat + " " + p_seatCd;
		}
	};

	/*
	 * Returns element
	 * p_type - type of element
	 * p_id	  - id of element
	 */
	var getSideElement = function(p_type, p_id) {
		var element;
		switch(p_type) {
		case "img" :
			element = $('<i></i>').addClass('fa fa-chevron-right');
			break;
		case "checkbox" :
			element = $('<input/>').prop("type", p_type).prop("id", p_id);
			break;
		}
		return element;
	};

	var getDiv = function() {
		return $("<div></div>");
	};

	var getImg = function() {
		return $("<img></img>");
	};

	var getLi = function() {
		return $("<li></li>");
	};

	var getLabel = function() {
		return $("<label></label>");
	};

	/*
	 * Format long
	 */
	et.evenue.getFormattedNumber = function(p_value, p_showCurrencySign) {
		return et.localeCurrencyDefault(p_value, et.locale, p_showCurrencySign);
	};

	/*
	 * Appends a seat blocks to an existing seat block string
	 * p_seatStr - element that new seats will be appended to
	 * p_seats - array of seatOHVos
	 */
	et.evenue.getSeatStr = function(p_seatStr, p_seats) {
		var seatStr = p_seatStr ? p_seatStr += "<br>" : "";
		$.each(p_seats, function(index, seat) {
			index > 0 ? seatStr += "<br>" : null;
			seatStr += et.evenue.getSeatBlock(seat);
		});
		return seatStr;
	};

	/*
	 * Appends transfer data to element
	 * p_element - element that data will be appended to
	 * p_content - lineItemOHVo
	 * p_displaySeason - true or false to display Season row
	 */

	et.evenue.getTransferData = function(p_element, p_lineItem, p_displaySeason) {
		var headers = [i18n.col_transfer_date, i18n.col_transfer_recipient, i18n.col_transfer_fee, i18n.lbl_season];
		var formattedContent = formatTransferData(p_lineItem, p_displaySeason);
		$.each(formattedContent, function(index, content) {
			p_element.append(getTransferRow(headers[index], content));
		});
	};

	var formatTransferData = function(p_lineItem, p_displaySeason) {
		var paidBy = "";
		if (p_lineItem[TRANSFER_PAID_BY] == "N")
			paidBy = i18n.ex_none;
		else
			paidBy = p_lineItem[TRANSFER_PAID_BY] == "T" ? i18n.lbl_paid_by_me : i18n.lbl_paid_by_recipient;

		var result = [et.createDateTime(p_lineItem.transferDtStr, et.datetimeOptions.SHORT, et.locale), paidBy];
		if (p_displaySeason)
			result.push(p_lineItem.season);
		return result;
	};

	/*
	 * Returns row element
	 * p_title - heading
	 * p_content - content
	 */
	var getTransferRow = function(p_title, p_content) {
		var tr = $("<tr>");
		tr.append($("<th>" + p_title + "</th><td>" + p_content + "</td>"));
		return tr;
	};

	/*
	 * Appends transfer data to element
	 * p_element - element that data will be appended to
	 * p_content - lineItemOHVo
	 * p_displaySeason - true or false to display Season row
	 */

	et.evenue.getPackageData = function(p_element, p_lineItem) {
		var headers = [i18n.col_package, i18n.lbl_order_date, i18n.col_package_cost, i18n.lbl_season];
		var formattedContent = formatPackageData(p_lineItem);
		$.each(formattedContent, function(index, content) {
			p_element.append(getTransferRow(headers[index], content));
		});
	};

	var formatPackageData = function(p_lineItem) {
		var result = [p_lineItem.name, et.createDateTime(p_lineItem.orderDtStr, et.datetimeOptions.SHORT, et.locale), et.evenue.getFormattedNumber(p_lineItem.totalPrice, true), p_lineItem.season];
		return result;
	};

	/*
	 * Returns select element with options
	 * p_selectOptions - list of options [{ id: "id", text: "Display Text" }]
	 */
	var getSelect = function(p_selectOptions) {

		var select = $('<select style="width: 145px;" class="form-control"></select>');
		if (p_selectOptions) {
			$.each(p_selectOptions, function(index, selectOption) {
				select.append($("<option />").val(selectOption.id).text(selectOption.text));
			});
		}
		return select;
	};

	et.evenue.forwardToErrorPage = function(p_presenter, p_params) {
		var params = p_params ? p_params : {
			errorCd : et.evenue.errorCodes.PAGE_NOT_AVAILABLE
		};
		p_presenter.pageDone({
			forward : C.ERROR_PAGE,
			params : params
		});
	};

	et.evenue.cloneTemplateElement = function(p_templateId) {
		var startsWith = p_templateId.replace('template', '');
		var selectorStr = "[id^='" + startsWith + "']:not([id='" + p_templateId + "']):last";
		var currentElem = $(selectorStr);
		if (!currentElem.length) {
			currentElem = $("#" + p_templateId);
		}
		if (currentElem.length) {
			var id = +currentElem[0].id.match(/\d+/g) + 1;
			var newElem = $("#" + p_templateId).clone();
			newElem.attr('id', startsWith + id);
			return newElem;
		}
		return null;
	};
	et.evenue.maskAccountNumber = function(accountNumber) {
		var result = '';
		if (accountNumber) {
			result = et.evenue.constants.PAYMENT_METHOD_MASK + et.evenue.getLastFour(accountNumber);
		}
		return result;
	};
	et.evenue.getLastFour = function(p_value) {
		return p_value ? p_value.substr(-4) : ''
	};
	/*
	 * Method returns map of input fields and values
	 * p_formInputs - form inputs
	 * return inputValues - map
	 */
	et.evenue.getFormValues = function(p_formInputs) {
		var inputValues = {
		};
		p_formInputs.each(function() {
			if (this.type == 'checkbox') {
				inputValues[this.name] = $(this).is(":checked") ? true : false;
			} else {
				inputValues[this.name] = $(this).val();
			}
		});
		return inputValues;
	};

	/*
	 * Determines if p_page can be accessed
	 * Returns true/false
	 */
	et.evenue.isPageAllowed = function(p_page) {
		var deferred = $.Deferred();
		et.evenue.isAllowed(p_page).done(function(result) {
			if (result) {
				deferred.resolve(result.pageAllowed);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * Determines if p_page can be accessed
	 * Returns json
	 * {
	 * 		pageAllowed	: true/false,
	 * 		userContext	: userContextVo,
	 * 		patron		: patronVo;
	 * }
	 */
	et.evenue.isAllowed = function(p_page) {
		var deferred = $.Deferred();
		var result = {
		};
		$.when(et.evenue.getUserContext(), et.evenue.getAccountData('1271088388')).done(function(userContext, p_patron) {
			result.userContext = userContext;
			result.patron = p_patron;
			var isPageAllowed = false;
			//StoredCreditCards, StoredBankAccounts, StoreCreditCard, StoredBankAccount
			if (p_page == "STORED_VALUES") {
				isPageAllowed = userContext.storedccbafl == 1 ? true : false;
			}
			//Donation History
			else if (p_page == "DONATION_HISTORY") {
				isPageAllowed = userContext.allowDonHistoryFlg == 1 ? true : false;
			}
			//Donation Details
			else if (p_page == "DONATION_DETAILS") {
				isPageAllowed = (userContext.allowDonHistoryFlg == 1 && userContext.dispDonDtlFlg == 1) ? true : false;
			} else if (p_page == et.evenue.constants.TRANSFER) {
				isPageAllowed = p_patron.transferfl == 1 ? true : false;
			} else if (p_page == et.evenue.constants.CANCEL_TRANSFER) {
				isPageAllowed = true;
			} else if (p_page == et.evenue.constants.RETURN) {
				isPageAllowed = p_patron.returnfl == 1 ? true : false;
			} else if (p_page == et.evenue.constants.EXCHANGE) {
				isPageAllowed = p_patron.exchfl == 1 ? true : false;
			} else if (p_page == "ORDER_HISTORY") {
				isPageAllowed = userContext.orderhistfl;
			} else if (p_page == et.evenue.constants.REISSUE) {
				isPageAllowed = userContext.reifl == 1 ? true : false;
			} else if (p_page == et.evenue.constants.PRIORITY_PTS) {
				isPageAllowed = userContext.custprifl == et.evenue.constants.YES || userContext.orderprifl == et.evenue.constants.YES || userContext.donprifl == et.evenue.constants.YES;
			}
			//TODO : remove hard code when userContext is ready
			result.pageAllowed = true;
			//Add code to check if your page is allowed
			deferred.resolve(result);
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * Determines if display is allowed for TICKET_FEE and FACILITY_FEE
	 */
	et.evenue.isPricingDisplayAllowed = function(p_type) {
		var result = false;
		$.when(et.evenue.getUserContext()).done(function(p_userContext) {
			var pricingDisplay = p_userContext.pricingDisplay;
			if (pricingDisplay == "3" && p_type == "FACILITY_FEE")//show facility only if displayMode = 3
			{
				result = true;
			} else if (pricingDisplay != "4" && p_type == "TICKET_FEE")//show ticket fee if display mode !=4 (i.e 1,2,3)
			{
				result = true;
			}
		});
		return result;
	};

	/*
	 * Build and return an array of objects grouped by season code
	 * Each item in the RETURN array has the seasonCd, array of p_lineItems for that seasonCd
	 * p_lineItems - array of any object that has field "seasonCd"
	 * TODO This could be made generic by being able to group by any attribute for example driveCd in donations
	 * Currently this method used by all order history pages
	 */
	et.evenue.groupObjectsBySeason = function(p_lineItems) {
		var objects;
		var object;

		if (p_lineItems) {
			objects = new Array();
			for (var i = 0; i < p_lineItems.length; i++) {
				var lineItem = p_lineItems[i];

				//if season exists already use it
				object = et.getValueFromListByField(objects, "seasonCd", lineItem.seasonCd);

				if (!object) {
					object = {
						seasonCd : lineItem.seasonCd,
						lineItems : new Array()
					};
					objects.push(object);
				}
				object.lineItems.push(lineItem);
			}
		}
		return objects;
	};

	/**
	 *called by UI framework when page changed.
	 */
	et.evenue.pageChanged = function(p_pageId, p_pageDef, p_controller) {

		var defer = $.Deferred();

		/** check site/locale see if the page needs to be reloaded */
		//parse the default params
		var propSiteId = C.SITEID;
		var propLocale = C.LOCALE;
		var propLinkId = C.LINKID;
		var siteId = et.getPageParameter(propSiteId) || et.siteId;
		/**forcing the siteId as uppercase*/
		//siteId=siteId?siteId.toUpperCase():siteId;
		var locale = et.getPageParameter(propLocale) || et.locale;
		var linkId = et.getPageParameter(propLinkId) || et.evLinkId;
		//read the previous values from cookie
		var prvSiteId = $.cookie(et.cookiePrefix + propSiteId);
		var prvLocale = $.cookie(et.cookiePrefix + propLocale);
		var prvLinkId = $.cookie(et.cookiePrefix + propLinkId);
		//user switched site or locale, force reload.
		if ((prvSiteId != siteId) || (prvLocale != locale) || (prvLinkId != linkId)) {
			setTimeout(function() {
				location.reload();
			}, 0);
			return defer.promise();
		}

		et.DefaultPageParams[C.BOOKMARK] = et.getPageParameter(C.BOOKMARK);

		var pageObj = p_controller.pages[p_pageId];
		if (pageObj.siteIdRequired === false) {
			//siteId is not required. For instance error page.
			defer.resolve();
		} else {
			//site id is required
			if (!et.siteId) {
				p_controller.navigate(C.ERROR_PAGE, {
					errorCd : et.evenue.errorCodes.SITE_ID_REQUIRED
				});
				defer.reject();
			} else {
				//has site id so let's validate it
				//resolve sellerId/poolId
				/*
				 var deferSid = et.resolveSiteId(et.siteId);
				 deferSid.done(function(p_siteId, p_sellerId, p_poolId) {
				 et.sellerId = p_sellerId;
				 et.poolId = p_poolId;
				 defer.resolve();
				 });
				 deferSid.fail(function() {
				 p_controller.navigate(C.ERROR_PAGE, {
				 errorCd : et.evenue.errorCodes.SITE_ID_INVALID
				 });
				 defer.reject();
				 });
				 */
			}
		}

		return defer.promise();

	};

	et.evenue.populateYearSelect = function(p_select) {
		var date = new Date();
		var year = date.getFullYear();
		for (var i = 0; i <= 10; i++) {
			p_select.append($("<option />").val(year + i).text(year + i));
		}
	};
	et.evenue.getCartTypeByAction = function(p_params) {
		var cartType;
		if (p_params.action !== "CHA" && p_params.action !== "AA") {
			cartType = p_params.cartType;
		}
		return cartType;
	};

	/*
	 * Returns a ContactInfoVo using form inputs
	 * p_formValues - input values
	 */
	et.evenue.getContactInfoVo = function(p_formValues) {
		contactInfoVo = {
			firstName : p_formValues["first-name"],
			lastName : p_formValues["last-name"],
			email : p_formValues.email,
			phone : p_formValues["day-phone"]
		};
		return contactInfoVo;
	};

	//**Get Quick Donation Details

	et.evenue.getQuickDonation = function(p_patronId, p_DonationCd) {
		var deferred = $.Deferred();
		var svr = {
			url : PATRON_SERVICES_URL + p_patronId + EC.QUICK_DONATION_URL + "?donationCd=" + p_DonationCd
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {

			deferred.resolve(data);

		}).fail(function() {
			et.handleFailure();
		});

		return deferred.promise();
	};

	et.evenue.getQuickDonationDetails = function(p_patronId) {
		var deferred = $.Deferred();
		var svr = {
			url : PATRON_SERVICES_URL + p_patronId + EC.QUICK_DONATION_DETAILS_URL
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {
			if (data) {
				deferred.resolve(data);
			} else {
				et.handleException(data);
				deferred.reject();

			}

		}).fail(function() {
			et.handleFailure();
		});

		return deferred.promise();
	};

	et.evenue.setQuickDonationDetails = function(p_patronId, p_data) {
		var deferred = $.Deferred();
		var svr = {
			url : PATRON_SERVICES_URL + p_patronId + EC.QUICK_DONATION_DETAILS_URL,
			type : HTTP_POST,
			data : p_data
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {
			if (data.value) {
				deferred.resolve(data);
			} else {
				et.handleException(data);
				deferred.reject();

			}

		}).fail(function() {
			et.handleFailure();
		});

		return deferred.promise();
	};

	et.evenue.submitQuickDonation = function(p_patronId, p_data) {
		et.showWaitPage();
		var deferred = $.Deferred();
		var svr = {
			url : PATRON_SERVICES_URL + p_patronId + EC.QUICK_DONATION_POST_URL,
			type : HTTP_POST,
			data : p_data
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {
			if (data.value) {
				deferred.resolve(data);
			} else {
				et.dismissWaitPage();
				et.handleException(data);
				deferred.reject();
				;
			}

		}).fail(function() {
			et.handleFailure();
		});

		return deferred.promise();
	};

	/*
	 * Returns OrderPaymentVo using form values
	 */
	et.evenue.getOrderPaymentFromForm = function(p_formValues, p_paymode) {
		var orderPaymentVo;
		if (p_formValues) {
			orderPaymentVo = {
				id : -1,
				storedValueId : 0,
				paymodeCd : p_formValues["card-type"],
				ccType : p_paymode ? p_paymode.ccType : "",
				paymodeType : et.evenue.constants.PAYMENT_TYPE.CREDIT_CARD,
				ccName : p_formValues["card-name"],
				paymentNmbr : p_formValues["card-number"],
				ccMonth : p_formValues["month-select"],
				ccYear : p_formValues["year-select"],
				nickName : p_formValues["card-nick-name"],
				storedFl : p_formValues["chk-store-card"] ? et.evenue.constants.YES : et.evenue.constants.NO,
				preferredFl : p_formValues["preferred-card"] ? et.evenue.constants.YES : et.evenue.constants.NO
			};
		}
		return orderPaymentVo;
	};

	/*
	 * Returns OrderPaymentVo using PtrnCreditCardVo
	 */
	et.evenue.getOrderPaymentFromStoredCard = function(p_storedCard) {
		var orderPaymentVo;
		if (p_storedCard) {
			orderPaymentVo = {
				paymentNmbr : p_storedCard.number,
				paymodeType : et.evenue.constants.PAYMENT_TYPE.CREDIT_CARD,
				paymodeCd : p_storedCard.paymodeCd,
				ccName : p_storedCard.nameOnCard,
				ccType : p_storedCard.type,
				ccYear : p_storedCard.expireYear,
				ccMonth : p_storedCard.expireMonth,
				nickName : p_storedCard.nickName,
				preferredFl : p_storedCard.preferredFl,
				storedValueId : p_storedCard.id,
				id : p_storedCard.id
			};
		}
		return orderPaymentVo;
	};

	et.evenue.isDecimal = function(e) {
		var regex = /^[0-9]+(\.[0-9]{1,2})?$/;
		e = e || window.event;
		var charCode = e.which ? e.which : e.keyCode;
		var inputChar = String.fromCharCode(charCode);
		inputChar = charCode == 46 ? inputChar + "0" : inputChar;
		var value = e.target.value + inputChar;
		return regex.test(value);
	};

	/**
	 * Checks if a given object is null or empty
	 */
	et.evenue.isNotNull = function(object) {
		return (object && object!=="")?true:false;
	};

	et.evenue.isValidEmailAddress = function(p_emailAddress) {
		var pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][\d]\.|1[\d]{2}\.|[\d]{1,2}\.))((25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\.){2}(25[0-5]|2[0-4][\d]|1[\d]{2}|[\d]{1,2})\]?$)/i);
		return pattern.test(p_emailAddress);
	};

	et.evenue.getPostSaleOrderPayment = function(p_feeRegion, p_userContext, p_patronVo) {
		var orderPaymentVo;
		var selectedCard = p_feeRegion.getCardSelected();
		if (!selectedCard || selectedCard == 0) {
			var creditCardValues = p_feeRegion.formValues();
			var paymode = et.getValueFromListByField(p_userContext.paymodes, "paymodeCd", creditCardValues["card-type"]);
			orderPaymentVo = et.evenue.getOrderPaymentFromForm(creditCardValues, paymode);
		} else {
			var storedCard = et.getValueFromListByField(p_patronVo.storedCardVos, "id", selectedCard);
			orderPaymentVo = et.evenue.getOrderPaymentFromStoredCard(storedCard);
		}
		return orderPaymentVo;
	};

	/* common post sale returns methods*/

	et.evenue.returns = {};
	et.evenue.returns.writeRow = function(p_templateId, p_tablebody, p_seat, p_price, p_dc, p_hasCheckbox) {

		var row = et.evenue.cloneTemplateElement(p_templateId);
		var rs = $(".return-seat", row);
		rs.prop("for", p_seat.itemId).text(p_seat.content[0]);
		var rplpt = $(".return-plpt", row);
		rplpt.prop("for", p_seat.itemId).text(p_seat.content[1]);
		if (p_seat.content[2]) {
			var rbc = $(".return-barcode", row);
			rbc.prop("for", p_seat.itemId).text(p_seat.content[2]);
		} else {
			$(".return-barcode", row).remove();
		}
		var rp = $(".return-price", row);
		rp.prop("for", p_seat.itemId).text(p_price);
		var rdc = $(".return-dc", row);
		rdc.prop("for", p_seat.itemId).text(p_dc);

		var cbElement = $('#return-cb', row);
		if (p_hasCheckbox) {
			cbElement.prop("id", p_seat.itemId);
		} else {
			cbElement.remove();
		}

		p_tablebody.append(row);
	};

	et.evenue.formatEventDatetime = function(fromDate, toDate, hideDate, hideTime, isTimeTba) {
		var fromDateStr = et.createDateTime(fromDate, "FLD", et.locale, null, false), fromTimeStr = et.createDateTime(fromDate, "FLT", et.locale, null, false), toDateStr = et.createDateTime(toDate, "FLD", et.locale, null, false), toTimeStr = et.createDateTime(toDate, "FLT", et.locale, null, false), dateStr = "", timeStr = "";

		if (hideDate && hideTime) {
			// empty
			return "";
		} else if (hideDate) {
			// time only
			if (isTimeTba) {
				timeStr = i18n.lbl_dt_time + " " + i18n.lbl_dt_tba;
			} else if (fromDate && toDate) {
				timeStr = fromTimeStr + " " + i18n.lbl_dt_to + " " + toTimeStr;
			} else if (fromDate && !toDate) {
				timeStr = fromTimeStr;
			}
		} else if (hideTime) {
			// date only
			if (fromDate && toDate) {
				dateStr = fromDateStr + " " + i18n.lbl_dt_to + " " + toDateStr;
			} else if (fromDate && !toDate) {
				dateStr = fromDateStr;
			}
		} else {
			// date and time
			if (fromDate && !toDate) {
				// no end date and time
				dateStr = fromDateStr;
			} else if (fromDate && toDate) {
				// has both from and to date strs
				dateStr = fromDateStr + " " + i18n.lbl_dt_to + " " + toDateStr;
			}

			if (isTimeTba && fromDate && toDate) {
				// multi date event
				timeStr += i18n.lbl_dt_time + " " + i18n.lbl_dt_tba;
			} else if (isTimeTba && fromDate && !toDate) {
				// single date event
				timeStr += i18n.lbl_dt_at + " " + i18n.lbl_dt_tba;
			} else if (fromDate && toDate) {
				timeStr += i18n.lbl_dt_from + " " + fromTimeStr + " " + i18n.lbl_dt_to + " " + toTimeStr;
			} else if (fromDate && !toDate) {
				timeStr += i18n.lbl_dt_at + " " + fromTimeStr;
			}
		}

		if (dateStr) {
			return dateStr + " " + timeStr;
		} else {
			return timeStr;
		}

	};

	/** Returns terms and conditions HTML*/
	et.evenue.termsAndConditions = function() {
		var deferred = $.Deferred();
		var svr = {
			url : PATH_GET_CONSUMER_TERMS_AND_CONDITIONS,
			type : HTTP_GET
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {
			deferred.resolve(data);

		}).fail(function(err) {
			deferred.reject(err);
		});
		return deferred.promise();
	};

	/** Returns consumer payment details*/
	et.evenue.getPaymentPolicies = function(context) {
		var deferred = $.Deferred();
		var url = et.evenue.apiBaseUri + et.evenue.urls.PATH_POLICIES;
		url += "?context=" + context;
		var svr = {
			url : url,
			type : HTTP_GET
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {
			deferred.resolve(data.values.paymentPolicies);

		}).fail(function(err) {
			deferred.reject(err);
		});
		return deferred.promise();
	};

	/**
	 * Throws exception if the current accesing site is inactive or is the setting is inactive
	 * Message :"This site is not available.  Please contact the box office if you feel you have reached this message in error"
	 * @param clientId
	 * @param channelId
	 * @returns {*}
	 */
	//	et.evenue.isAccessingSiteValid = function(clientId, channelId) {
	//
	//		var deferred = $.Deferred();
	//
	//		et.evenue.getUserContext().done(function(result) {
	//			var svr = {
	//				url : PATH_VALID_ACCESSING_SITE.replace('{channelId}', channelId),
	//				type : HTTP_GET
	//			};
	//			var jqxhr = et.ajax(svr);
	//			jqxhr.done(function(data) {
	//					deferred.resolve(data);
	//
	//			}).fail(function(err) {
	//				deferred.reject(err);
	//			});
	//
	//		}).fail(function() {
	//			var errorResult={
	//				status:{
	//					cd:'5014',
	//					mgs:["This site is not available.  Please contact the box office if you feel you have reached this message in error."]
	//				}
	//			}
	//			deferred.resolve(errorResult);
	//		});
	//
	//		return deferred.promise();
	//	};

	/*
	 * This is being used for Account page, original function above could break
	 * some other code @param id - accountId
	 */
	et.evenue.getAccountData = function(id) {
		var deferred = $.Deferred();
		var account = et.cache.account;
		if (!account) {
			var svr = {
				url : et.evenue.urls.BASE_ACCOUNT_URL + "/" + id
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(result) {
				et.cache.account = result.values.account;
				deferred.resolve(result.values.account);
			}).fail(function() {
				deferred.reject();
			});
		} else {
			deferred.resolve(account);
		}
		return deferred.promise();
	};

	/*
	 * returns the current channel attributes
	 * @param orgId {string}
	 * @param accountId {string}
	 * @returns {acctchnattrs}
	 */
	et.evenue.getAccountChannel = function(accountId) {
		var deferred = $.Deferred();
		var svr = {
			url : BASE_ACCOUNT_CHANNEL.replace("{accountId}", accountId),
			type : HTTP_GET
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			deferred.resolve(result.values);
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * update account channel data according the update type
	 * @param accountId
	 * @param updateType (EMLPREF: email preferences)
	 * @returns {*}
	 */
	et.evenue.updateAccountChannel = function(accountId, updateType, acctChannelData) {
		var deferred = $.Deferred();

		var svr = {
			url : BASE_ACCOUNT_CHANNEL.replace("{accountId}", accountId) + "/" + updateType,
			type : HTTP_PUT,
			data : acctChannelData
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	/*
	 * Return the standard name format from a nameObject
	 * @param nameObj: name object
	 * @returns {string}
	 */
	et.evenue.formatName = function(nameObj) {
		var name = "";

		if (nameObj.first) {
			name += nameObj.first + " ";
		}

		if (nameObj.middle) {
			name += nameObj.middle + " ";
		}

		if (nameObj.last) {
			name += nameObj.last;
		}

		return name;
	};

	/*
	 * Returns primary contact @contacts - list of account contacts
	 * @primaryContactId - uuid of the primary contact
	 */
	et.evenue.getContactById = function(contacts, contactId) {
		var primaryContact = null;
		if (contacts && contacts.length > 0) {
			if (contactId) {
				$.each(contacts, function(index, contact) {
					if (contactId == contact.key.id) {
						primaryContact = contact;
						return false;
					}
				});
			} else {
				return contacts[0];
			}
		}
		return primaryContact;
	};

	/*
	 * Method for building REST API base string
	 */
	et.evenue.buildResourceUrl = function(namespace, resourceName, includeVersion) {
		includeVersion = includeVersion === undefined ? true : includeVersion;
		var deferred = $.Deferred();
		et.evenue.getUserContext().done(function(userCtx) {
			var pathParts = [];
			if (includeVersion) {
				pathParts.push(et.evenue.apiVersion);
			}
			if (namespace) {
				pathParts.push(namespace);
				pathParts.push(userCtx.selectedOrganizationId);
			}
			pathParts.push(resourceName);
			deferred.resolve(et.evenue.apiBaseUri + pathParts.join("/"));
		});
		return deferred.promise();
	};

	/*
	 * Returns system codes (dbId=-1) for the specified type
	 */
	et.evenue.getSystemCodeTypes = function(type) {
		var deferred = $.Deferred();
		var codeMap = et.cache.systemCodeMap;
		if (codeMap && codeMap[type]) {
			deferred.resolve(codeMap[type]);
		} else {
			var svr = {
				url : BASE_COMMON_URL + '/code/' + et.evenue.clientId + '/list/system/' + type
			};

			var jqxhr = et.ajax(svr);
			jqxhr.done(function(result) {
				if (et.handleException(result)) {
					if (!et.cache.systemCodeMap)
						et.cache.systemCodeMap = {};
					et.cache.systemCodeMap[type] = result.data;
					deferred.resolve(result.data);
				} else {
					deferred.reject();
				}
			}).fail(function() {
				et.handleFailure();
			});
		}
		return deferred.promise();
	};

	et.evenue.sso = {};
	
	et.evenue.sso.logout = function(encodedReturnUrl) {
		var logoutUrl = location.protocol + '//'+ location.hostname + (location.port ? ':' + location.port: '') + et.evenue.constants.SSO_LOCAL_LOGOUT_URL;
		if (encodedReturnUrl) {
			logoutUrl = logoutUrl + '?return=' + encodedReturnUrl;
		}
		window.location = logoutUrl;
	};
	
	et.evenue.sso.signIn = function(p_params, p_presenter, ignoreExceptionFlag, callback) {

		var patronId, hashcode, timestamp;
		var siteId = et.siteId;
		var sellerId, poolId;

		var isLocalhost = function() {
			return (document.location.port !== 80 && document.location.hostname === 'localhost');
		};

		var resolveSiteId = function() {
			var deferred = $.Deferred();

			et.resolveSiteId(siteId, true).done(function(p_siteId, p_sellerId, p_poolId, p_locale, p_locales) {
				siteId = p_siteId;
				sellerId = p_sellerId;
				poolId = p_poolId;
				deferred.resolve();
			}).fail(function(p_error, p_result) {
				deferred.resolve();
				//deferred.reject();
				//et.evenue.forwardToErrorPage(p_presenter, {errorCd:p_result.status.cd, errorMsg:p_result.status.mg});
			});

			return deferred.promise();
		};

		var pac8SignIn = function() {
			var deferred = $.Deferred();
			var svr = {
				url : BASE_CONSUMER_LOGIN,
				type : et.constants.HTTP_POST,
				data : p_params
			};

			et.ajax(svr).done(function(p_result) {
				if (ignoreExceptionFlag && ignoreExceptionFlag == true) {
					if (p_result && (p_result.data || p_result.values) && p_result.status.cd == 0) {
						patronId = p_result.values.accountId;
						hashcode = p_result.values.hash;
						timestamp = p_result.values.timestamp;
						deferred.resolve(p_result);
					} else {
						deferred.reject(p_result);
					}
				} else {
					if (et.handleException(p_result)) {
						patronId = p_result.values.accountId;
						hashcode = p_result.values.hash;
						timestamp = p_result.values.timestamp;
						deferred.resolve(p_result);
					} else {
						$("html, body").scrollTop(0);
						deferred.reject();
					}
				}
			}).fail(function() {
				deferred.reject();
				et.handleFailure();
			});

			return deferred.promise();
		};

		var netCommerceSignIn = function() {
			var deferred = $.Deferred();
			var params = {
				"linkID" : et.evLinkId,
				"cn" : patronId,
				"pw" : hashcode,
				"ts" : timestamp, //,
				"url" : window.location.protocol + "//" + document.domain + "/www/" + et.siteId + "/ss/evenue/success.txt"
			};
			var svr = {
				url : "/cgi-bin/ncommerce3/REGLogin",
				data : params,
				type : "GET"
			};

			et.ajax(svr).done(function(p_result) {
			    deferred.resolve(p_result);
				
			}).fail(function() {
				deferred.resolve(false);
				//deferred.reject("error - failed to call netCommerce ssoLogin.");
			});

			return deferred.promise();
		};
		
		var deferred = $.Deferred();
		resolveSiteId().then(pac8SignIn).then(netCommerceSignIn).done(function(p_result){
		    if ("status:1" === p_result || p_result == false) {
                if(callback) callback();
            }
            else if("status:2" === p_result){
                // Logging in with temp password
                var returnUrl = window.location.href;
                var pageParams = {
                    "evm" : 'regu',
                    "url" : returnUrl
                };
                var redirectURL = "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + et.evLinkId;
                window.location = window.location.protocol + "//" + document.domain + et.createExternalUrl(redirectURL, pageParams);
            }
            else {
                et.handleFailure({statusText: "error - failed to call netCommerce ssoLogin."});
            }
		}).fail(function(){
		    deferred.reject();
		});
		return deferred.promise();
	};

	/**
	 * Single sign out of pac8, pac7 and NetCommerce
	 */
	et.evenue.sso.signOut = function(p_goToLogin){
        var signOutNetCommerce = function(p_goToLogin){
            var logOffUrl = window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/REGLogoff";
            var signInURL = window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + et.evLinkId + "&evm=myac&entry=main.html";
            var redirectURL = "";
            if (window.location.protocol && window.location.host && window.location.pathname && window.location.search) {
                redirectURL = window.location.protocol + "//" + window.location.host + window.location.pathname + window.location.search;
            }
            var signOutUrl = window.location.protocol + "//" + document.domain + "/cgi-bin/ncommerce3/EVExecMacro?linkID=" + et.evLinkId + "&evm=regl";

            //if user context not present then redirect to evenue login
            var pageParams = {
                "linkID" : et.evLinkId,
                "url" : p_goToLogin ? redirectURL : signOutUrl
            };
            et.evenue.PageController.navigate( p_goToLogin ? signInURL : logOffUrl, pageParams, null, null);
        };

        // We no longer need to call pac7 Logout because back end code logs out of pac7
        // after logging out of pac8
	    var signOutHybrid = function() {
	        $("#login-widget-welcome-msg").html(""); //Clear welcome message
	        var deferred = $.Deferred();
	        var svr = {
	          type: et.constants.HTTP_PATCH,
	          url: et.rootCtx + "/rs/contexts?action=logout"
	        };
	        var jqxhr = et.ajax(svr);
	        // Log out of pac8 Consumer
	        jqxhr.done(function(result) {
	            if (et.handleException(result)) {
	                et.cache.userCtx=null;
	                signOutNetCommerce();
	            }
	            deferred.resolve(result);
	        }).fail(function() {
	            et.displayMessagePanel({
	                msgs: ['Logout pac8 failed']
	            });
	            deferred.reject();
	        });
	        return deferred.promise();
	    };

	    // Overwrite the modal event when user clicks on PacFund Donate
	    $("#release-cart-modal-continue-btn").off("click"); //unbind any existing event
	    $("#release-cart-modal-continue-btn").on("click", function(e){
            e.preventDefault();
            et.evenue.releasePac7Cart(function(){
                signOutHybrid();
            });
        });

	  //Check to see if there's a cart and warn user before logging out
        $.when(et.evenue.checkPac7Cart()).done(function(isCartEmpty){
            if(isCartEmpty){
                signOutHybrid();
            }
            //Else, the release-cart modal is going to take care of the navigation
        });
	};

	// Check if user has an existing pac7 cart
	et.evenue.checkPac7Cart = function(){
        var deferred = $.Deferred();
        $.when(et.evenue.services.getPac7CartTimer(et.siteId)).done(function(data) //loads pac7 cart timer values
        {
            if (data.value && data.value.cartNumber > 0 && data.value.remainingTime > 0)
            {
                m_cartId = data.value.cartNumber;
                $('#release-cart-modal-dialog').modal();
                deferred.resolve(false); //return false, cart is not empty
            }
            deferred.resolve(true); //return true, cart is empty
        }).fail(function(error, result){
            et.handleFailure();
            deferred.reject();
        });
        return deferred.promise();
    };

    // Release the pac7 cart
    et.evenue.releasePac7Cart = function(callback){
        et.evenue.services.clearPac7Cart(et.evLinkId, et.siteId, et.cache.userCtx.accountId, m_cartId).done(function(data){
            $(".shopcart-timer").show();
            $("#pac7-timer-container").hide();
            et.evenue.services.clearEvenueShoppingCart(m_cartId).done(function(){
                $('#release-cart-modal-dialog').modal('hide');
                if(callback) callback();
            });
        });
    };

	et.evenue.getAccount = function(organizationId, channelId, userName) {
		var url = BASE_OAUTH_ACCOUNT.replace("{dbId}", organizationId);
		url = url.replace("{channelId}", channelId);
		url = url.replace("{userName}", userName);
		var deferred = $.Deferred();
		var svr = {
			url : url,
			type : "GET"
		};
		var promise = et.ajax(svr);

		return promise;
	};

	et.evenue.getOauhtAccount = function(organizationId, channelId, userName) {
		var svr = {
			url : et.rootCtx + "/rs/account/" + organizationId + "/oauth/channel/" + channelId + "/user/" + userName,
			type : "GET"
		};
		var promise = et.ajax(svr);

		return promise;
	};
	/*
	 * Get order confirmation by confirmation Id
	 */
	et.evenue.getOrderConfirmation = function(transactionId, orgId) {
		var deferred = $.Deferred();

		var svr = {
			url : et.evenue.urls.TRANSACTION_BASE_URL + et.evenue.resourceNames.SEARCH_TRANS,
			type : HTTP_POST,
			data : {
				queryType : et.evenue.constants.TRANS_QUERY_TYPE.TRANSACTION_SUMMARY,
				transactionId : transactionId
			}
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result) {
			if (et.handleException(p_result)) {
				deferred.resolve(p_result.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			deferred.reject();
		});

		return deferred.promise();
	};

	et.evenue.getOrganizationChannelSettings = function(channelId, orgId) {
		//et.rootCtx + '/rs/v1.0/iac/settings/{type}/{channelId}/{orgId}'
		var svr = {
			type : et.constants.HTTP_GET,
			url : et.rootCtx + '/rs/controlsettings/ORG_CHANNEL/{channelId}/{orgId}'.replace("{channelId}", channelId).replace("{orgId}", orgId)
		};
		var promise = et.ajax(svr);

		return promise;
	};
	et.evenue.sendForgotPasswordEmail = function(userName) {
		var svr = {
			type : et.constants.HTTP_POST,
			url : et.rootCtx + '/rs/account/{dbId}/oauth/forgotPasswordEmail'.replace('{dbId}', 'default'),
			contentType : 'text/plain',
			data : userName
		};
		var promise = et.ajax(svr);

		return promise;
	};
	et.evenue.verifyToken = function(encryptedParams) {
		var svr = {
			type : et.constants.HTTP_POST,
			url : et.rootCtx + '/rs/account/{dbId}/oauth/verifyToken'.replace('{dbId}', 'default'),
			contentType : 'text/plain',
			data : encryptedParams
		};
		var promise = et.ajax(svr);

		return promise;
	};

	et.evenue.getOrg = function(clientId, p_orgId) {
		var deferred = $.Deferred();
		var url = BASE_ORGANIZATION_URL;
		url += p_orgId;
		var svr = {
			type : et.constants.HTTP_GET,
			url : url
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.values.organization);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	et.evenue.getTermsAndConditions = function(channelId) {
		var deferred = $.Deferred();
		var url = BASE_CONSUMER_CHANNEL.replace("{channelId}", channelId);
		var svr = {
			url : url,
			type : "GET"
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();

	};

	/**
	 * Common methods for item pricing team
	 * Need to move to a team level common.js
	 */

	et.evenue.ItemPricing = {};

	//Get the display name for an item

	et.evenue.ItemPricing.getDisplayItemName = function(item) {
		var displayName = "";

		//Check for name in internet params
		if (item.internetName != null && item.internetName[et.locale] && item.internetName[et.locale].value1) {
			displayName = item.internetName[et.locale].value1;
		} else {
			//Check for item name
			if (item.name != null) {
				if (item.name[et.locale] && item.name[et.locale].value1) {
					displayName = item.name[et.locale].value1;
				}
			}
		}

		return displayName;

	};

	et.evenue.ItemPricing.filterEvent = function(item, testKeyword) {
		//Check for name in internet params
//		if (item.internetName != null && item.internetName[et.locale] && item.internetName[et.locale].value1 &&
//				item.name != null && item.name[et.locale] && item.name[et.locale].value1) {
//			testKeyword = testKeyword.replace('*', '');
//			testKeyword = testKeyword.replace('?', '');
//			if( item.internetName[et.locale].value1.indexOf(testKeyword) < 0 && item.name[et.locale].value1.indexOf(testKeyword) > -1){
//				return null;
//			}
//		}

		return item;
	};

	/** display the waiting page with specified title and message*/
	et.showWaitPage = function(p_title, p_message) {
		var docHeight = $(document).height();
		var docWidth = $(document).width();

		var waitDiv;
		if (et._waitDiv) {
			waitDiv = et._waitDiv;
			waitDiv.find("#wait_title").html(p_title);
			waitDiv.find("#wait_msg").html(p_message);
		} else {
			waitDiv = $("<div>").load("global/view/wait.html", function() {
				waitDiv.find("#wait_title").html(p_title);
				waitDiv.find("#wait_msg").html(p_message);
			});
		}

		$("body").append(waitDiv);
		$('html, body').css({
			'overflow' : 'hidden',
			'height' : '100%'
		});
		et._waitDiv = waitDiv;
	};

	/** dismiss the waiting page*/
	et.dismissWaitPage = function() {
		if (et._waitDiv)
			et._waitDiv.remove();
		$('html, body').css({
			'overflow' : 'auto',
			'height' : 'auto'
		});
	};
	/**
	 * Format name as it appears on credit card for Payment.
	 *
	 * @author Dennis Trinh
	 * @since 8.0
	 */
	et.evenue.formatCreditCardNameForPayment = function(name) {
		var formattedName = {
			first : 'NOREAL',
			last : 'NAME'
		};
		if (name) {
			name.trim();
			var index = name.search(',');
			if (index !== -1) {
				formattedName.last = name.slice(0, index);
				formattedName.first = name.slice(index + 2);
			} else {
				index = name.search(' ');
				if (index !== -1) {
					formattedName.first = name.slice(0, index);
					formattedName.last = name.slice(index + 1);
				} else {
					formattedName.first = name;
				}
			}
		}
		return formattedName;
	};

	/*
	 * Displays delete yes/no lightbox
	 * @param entity - entity to be deleted
	 * @method - function that will be executed if user clicks yes button
	 */
	et.evenue.displayDeleteLightBox = function(entity, method) {
		var msg = et.replaceVariableInString(et.err['3000024'], et.evenue.constants.VAR_THING, entity);
		$.lightBoxYesNo({
			header : i18n.delete_hdg,
			message : msg
		}, method);
	};

	/*
	 * Formats person's name including title and suffix
	 */
	et.evenue.formatFullName = function(person) {
		var result = '';
		result += person.title || '';
		if (result.length > 0)
			result += ' ';
		result += et.formatName(person.name, et.locale);
		result += person.suffix ? (', ' + person.suffix) : '';
		return result;
	};

	/*
	 * Returns Channel
	 * @param channelId - id of the channel
	 */
	et.evenue.getChannel = function(channelId) {
		var deferred = $.Deferred();
		var clientId = et.evenue.clientId ? et.evenue.clientId : null;
		var svr = {

			url : CHANNEL_RS.replace("{clientId}", clientId) + channelId,
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result) {
			if (et.handleException(p_result)) {
				deferred.resolve(p_result.values["channel"]);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};

	et.evenue.itemType = {
		SINGLE : "S",
		FIXED_COMBO : "F",
		FLEX_COMBO : "X",
		MISC : "M"
	};

	// Use this method to sort an array by a property name, e.g. sort line items by drive year like so:
	// et.op.sortArray(m_lineItems, { sortBy: "driveYear", sortOrder: "desc" });
	et.evenue.sortArray = function(arrayToSort, options) {
		arrayToSort.sort(function(a, b) {
			if (options.sortOrder == et.evenue.constants.SORT_ORDER.DESC) {
				return a[options.sortBy] < b[options.sortBy] ? 1 : (a[options.sortBy] > b[options.sortBy] ? -1 : 0);
			} else {
				return a[options.sortBy] > b[options.sortBy] ? 1 : (a[options.sortBy] < b[options.sortBy] ? -1 : 0);
			}
		});
	};

	/*
	 * @method: find an item in a list of items by key.id
	 * If an item can't be found, log a console warning.
	 */
	et.evenue.getItemByKeyId = function(list, id) {
		var filteredList = $.grep(list, function(n, i) {
			return n.key.id == id;
		});
		if (filteredList.length > 0) {
			return filteredList[0];
		} else {
			console.log("Warning: Item with ID = " + id + " does not exist!");
			return {};
		}
	};

    /*
     * This can be mixed into the JQuery validation form rules object for validating a form.
     * @author: Christoph Samitsch
     */
	et.evenue.getJQueryValidationDefaults = function () {
	    return {
	        highlight: function (element) {
	            if ($(element).hasClass("datepicker")) {
	                $(element).closest(".date").parent().addClass('has-error');
	            } else {
	                $(element).parent().addClass('has-error');
	            }
	        },
	        errorPlacement: function (error, element) {
	            if ($(element).hasClass("datepicker")) {
	                $(element).closest(".date").parent().append(error);
	            } else {
	                $(element).parent().append(error);
	            }
	        },
	        success: function (element) {
	            if ($(element).hasClass("datepicker")) {
	                $(element).closest(".date").parent().removeClass('has-error');
	            } else {
	                $(element).parent().removeClass('has-error');
	            }
	            $(element).remove();
	        },
	        rules: {}
	    };
	};

	/*
	 * Checks if a string variable is a JSON string
	 * @author Christoph Samitsch
	 * @param {str} The string value that we need to perform the check on.
	 */
	et.evenue.isJSONString = function(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	};

	et.evenue.getDeserializedIACControlSettings = function(settings, resourceKey) {
		var jsonObject = {};
		if (settings.resources[resourceKey] === undefined || settings.resources[resourceKey] == "" || settings.resources[resourceKey] == null) {
			return {};
		} else {
			if (et.evenue.isJSONString(settings.resources[resourceKey])) {
				return JSON.parse(settings.resources[resourceKey]);
			} else {
				return {};
			}
		}
	};

	/*
	 * Clears all elements of an array without clearing its properties.
	 * This can be used for clearing a rivets object where the elements need to be cleared
	 * but the bindings need to be persisted.
	 * @author Christoph Samitsch
	 * @param {Object} The array to clear
	 */
	et.evenue.clearArray = function(arrayToClear) {
		while (arrayToClear.length) {
			arrayToClear.pop();
		}
	};

	/*
	 * Copy an array into a rivets array object so the original bindings won't get lost.
	 * @author: Christoph Samitsch
	 */
	et.evenue.copyRivetsArray = function(sourceArray, targetArray) {
		if ($.isArray(sourceArray) && $.isArray(targetArray)) {
			et.evenue.clearArray(targetArray);
			$.each(sourceArray, function(idx, item) {
				targetArray.push($.extend(true, {}, item));
			});
		} else {
			console.log("Both parameters need to be an array.");
		}
	};

	/*
	 * Checks if a string variable is null or empty
	 * @author Christoph Samitsch
	 * @param {string} The string value that we need to perform the check on.
	 */
	et.evenue.isNullOrEmpty = function(value) {
		return (value === null || value === "" || value === undefined);
	};

	/*
	 * Checks if a string variable is null or empty or if it only contains white spaces
	 * @author Christoph Samitsch
	 * @param {string} The string value that we need to perform the check on.
	 */
	et.evenue.isNullOrWhitespace = function(value) {
		return (value === null || value.trim() === "" || value === undefined);
	};

	/*
	 * Iterate through a list of donation line items and calculate out the total pledged and donated amount.
	 * @author: Christoph Samitsch
	 */
	et.evenue.getTotalPledgedAndDonated = function(lineItems) {
		var totalPledged = 0;
		var totalDonated = 0;
		var totalScheduled = 0;
		var totalBalance = 0;
		$.each(lineItems, function(idx, item) {
			totalPledged += item.pledgeAmount;
			totalDonated += item.paymentAmount;
			totalScheduled += item.scheduledAmount;
			var itemBalance = item.pledgeAmount - item.paymentAmount - item.scheduledAmount;
			if (itemBalance < 0) {
				itemBalance = 0;
			}
			totalBalance += itemBalance;
		});
		return {
			totalPledged : totalPledged,
			totalDonated : totalDonated,
			totalScheduled : totalScheduled,
			totalBalance : totalBalance
		}
	};

	/*
	 * Map payment region payment data into an object that can be passed in for submitting a donation with payments.
	 * @author Christoph Samitsch
	 * @param {account} The account to use for the account holder name and billing address.
	 * @param {paymentRegion} The payment region.
	 * @param {option} Right now, this is only isStoredPayment (whether or not a stored payment method is being used).
	 * @param {acctInfoRegion} The account info region.
	 */
	et.evenue.getMappedPaymentData = function(donorId, account, paymentRegion, options, acctInfoRegion) {
		var paymentRegionData = paymentRegion.getData();
		var payment = $.extend(true, {}, paymentRegionData);
		payment.paymentDate = new Date();
		// TODO: right now default billing address and contact info is being set up in the backend
		var accountHolder;
		if (account) {
			if (account.accountType == et.evenue.constants.ORGANIZATION_ACC_TYPE) {
				payment.billingAddress = account.orgAddresses ? account.orgAddresses[0] : '';
			} else if (acctInfoRegion && acctInfoRegion.getSelectedBillingAddress()) {
				// If there is a selected billing address
				payment.billingAddress = acctInfoRegion.getSelectedBillingAddress();
			} else {
				payment.billingAddress = account.contacts && account.contacts[0].addresses ? account.contacts[0].addresses[0] : '';
			}
			accountHolder = account.contacts[0];
		} else {
			accountHolder = m_guestAcctRegion.getAccount().contacts[0];
		}
		payment.accountId = donorId;
		switch (payment.paymentType) {
		case et.evenue.constants.PAYMENT_TYPE.CREDIT_CARD:
			payment.storePayment = (paymentRegion.shouldStorePayment() || paymentRegion.isStoredCreditCardExpired());
            payment.creditCard = {};
            payment.creditCardCVV = paymentRegionData.creditCard.securityCode;
            payment.creditCardNumberLastFourDigits = et.evenue.getLastFour(paymentRegionData.creditCard.cardNumber);

            payment.creditCard = payment.accountCreditCard;
			payment.bankAccount = null;
			break;
		case et.evenue.constants.PAYMENT_TYPE.ECHECK:
			payment.storePayment = paymentRegion.shouldStorePayment();
			payment.bankAccount = {};
			if (payment.storePayment) {
				payment.bankAccount.preferredFl = payment.echeck.preferredFl;
				payment.bankAccount.nickName = payment.echeck.nickName;
			}
			if (options.isStoredPayment) {
				payment.bankAccount.token = payment.echeck.token;
				payment.bankAccount.firstName = payment.echeck.firstName;
				payment.bankAccount.lastName = payment.echeck.lastName;
				payment.bankAccount.type = payment.echeck.type;
				payment.bankAccount.accountNumber = payment.echeck.accountNumber;
				payment.bankAccount.instName = payment.echeck.instName;
				payment.bankAccount.routingNumber = payment.echeck.routingNumber;
			} else {
				payment.bankAccount.firstName = payment.echeck.firstName;
				payment.bankAccount.lastName = payment.echeck.lastName;
				payment.bankAccount.type = payment.echeck.type;
				payment.bankAccount.accountNumber = payment.echeck.accountNumber;
				payment.bankAccount.instName = payment.echeck.instName;
				payment.bankAccount.routingNumber = payment.echeck.routingNumber;
			}
			payment.creditCard = null;
			break;
		}
		payment.paycodeId = payment.payCode.key.id;
		return payment;
	};

	/*
	 * Submit a donation
	 * @author Christoph Samitsch
	 * @param {Object} The transaction to submit
	 */
	et.evenue.submitDonation = function(transaction) {
		var deferred = $.Deferred();
		var svr = {
			url : et.evenue.urls.TRANSACTION_BASE_URL + et.evenue.resourceNames.CONSUMER_TRANS_DONATION_URL,
			type : et.constants.HTTP_POST,
			data : transaction
		};
		et.ajax(svr).done(function(response) {
			if (et.handleException(response)) {
				deferred.resolve(response.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * Get cart to access a patron's donations that are not submitted yet.
	 * @author Christoph Samitsch
	 * @param {cartId} The ID for the cart object that one needs to retrieve
	 */
	et.evenue.getDonationItemsFromCart = function(cartId) {
		var deferred = $.Deferred();
		if (cartId) {
			var svr = {
				url : et.evenue.urls.CART_BASE_URL + cartId + "?view=detail",
				type : et.constants.HTTP_GET
			};
			et.ajax(svr).done(function(response) {
				if (et.handleException(response)) {
					deferred.resolve(response.data);
				} else {
					deferred.reject();
				}
			}).fail(function() {
				deferred.reject();
			});
		} else {
			deferred.resolve();
		}
		return deferred.promise();
	};

	/*
	 * Store donation items in a patron's cart.
	 * @author Christoph Samitsch
	 * @param {donationRequest} The donation items one wants to add to the cart
	 */
	et.evenue.saveDonationItemsToCart = function(donationRequest) {
		var deferred = $.Deferred();
		var svr = {
			url : et.evenue.urls.CART_BASE_URL + "?action=NS",
			type : et.constants.HTTP_POST,
			data : donationRequest
		};
		et.ajax(svr).done(function(response) {
			if (et.handleException(response)) {
				deferred.resolve(response.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};

	/*
	 * Delete a patron's cart.
	 * @author Christoph Samitsch
	 * @param {cartId} The ID of the cart to be deleted.
	 */
	et.evenue.deleteCartByCartId = function(cartId) {
		var deferred = $.Deferred();
		var svr = {
			url : et.evenue.urls.CART_BASE_URL + cartId,
			type : et.constants.HTTP_DELETE
		};
		et.ajax(svr).done(function(response) {
			if (et.handleException(response)) {
				deferred.resolve(response.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};


	/**
	 * Load a donor's donation line items by account ID
	 * @author Christoph Samitsch
	 */
	et.evenue.loadConsumerDonations = function(userCtx) {
		var deferred = $.Deferred();
		$.when(et.evenue.buildResourceUrl("", et.evenue.constants.CONSUMER_DONATIONS_URL.replace("{accountId}", userCtx.accountId), false)).done(function(url) {
			var svr = {
				type : et.constants.HTTP_GET,
				url : url + "?channelId=" + userCtx.selectedChannelId
			};
			et.ajax(svr).done(function(result) {
				if (et.handleException(result)) {
					deferred.resolve(result.values);
				} else {
					deferred.reject();
				}
			}).fail(function() {
				deferred.reject();
			});
		});
		return deferred.promise();
	};

	/**
	 * Load a donor's gifts by account ID
	 * @author Jeremy Geaga
	 */
	et.evenue.getConsumerGifts = function(userCtx) {
		var deferred = $.Deferred();
		$.when(et.evenue.buildResourceUrl("", et.evenue.constants.CONSUMER_DONORGIFT_URL.replace("{accountId}", userCtx.accountId), false)).done(function(url) {
			var svr = {
				type : et.constants.HTTP_GET,
				url : url
			};
			et.ajax(svr).done(function(result) {
				if (et.handleException(result)) {
					deferred.resolve(result.values);
				} else {
					deferred.reject();
				}
			}).fail(function() {
				deferred.reject();
			});
		});
		return deferred.promise();
	};

	/**
	 * Load a donor's payment schedule by account ID and payment schedule ID
	 * @author Christoph Samitsch
	*/
	et.evenue.getPaymentScheduleForAccount = function(queryParams) {
		var deferred = $.Deferred();
		var url = "";
		et.evenue.buildResourceUrl(et.evenue.constants.FUND_NAME_SPACE, et.evenue.resourceNames.GET_PAYMENT_SCHEDULE, true).done(function(resourceUrl) {
			url = resourceUrl;
		});
		var svr = {
			type : et.constants.HTTP_GET,
			url : url + "?" + $.param(queryParams)
		};
		et.ajax(svr).done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.values);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			deferred.reject();
		});
		return deferred.promise();
	};

	et.evenue.getConsumerAllocations = function(selectedChannelId) {
		var deferred = $.Deferred();
		$.when(et.evenue.buildResourceUrl(et.evenue.constants.FUND_NAME_SPACE, et.evenue.resourceNames.CONSUMER_ALLOCS_MMD_URL)).done(function(url) {
			var svr = {
				url : url + "&channelId=" + selectedChannelId,
			};
			et.ajax(svr).done(function(response) {
				//deferred.resolve(response.values.allocationList);
				deferred.resolve(response.values.allocationPage.list);
			}).fail(function() {
				et.handleFailure();
			});
		});
		return deferred.promise();
	};

	/**
	 * This web service retrieves the alocations from FundrasingResource
	 * @param channelId - the channelId
	 * @param path - the allocation path, could be either DH (Donation History) or MMD (Manage Donations) or none
	 * @param pageSectionCd - the pageSection to retrieve the allocations for, possible values are: <br/>
	 *     - DH : donation history
	 *     - MMD : manage my donations
	 *     - MAD : My assigned donor
	 *     - RD : Renew donations
	 */
	et.evenue.getConsumerAllocationsDetail = function (channelId, path, pageSectionCd) {
        var deferred = $.Deferred();

        $.when(et.evenue.buildResourceUrl(et.evenue.constants.FUND_NAME_SPACE, et.evenue.resourceNames.ALLOCS)).done(function (url) {
            if(pageSectionCd != null && pageSectionCd != ""){
                url += "&pageSection=" + pageSectionCd;
            }

            if(path){
                url += "&path=" + path;
            }

            if(channelId){
                url += "&channelId=" + channelId;
            }

            var svr = {
                url: url
            };
            et.ajax(svr).done(function (response) {
                deferred.resolve(response.values);
            }).fail(function () {
                et.handleFailure();
            });
        });

        return deferred.promise();
    };


	/*
	 *	 ██████╗ █████╗ ██████╗ ████████╗    ████████╗██████╗  █████╗ ███╗   ██╗███████╗
	 *	██╔════╝██╔══██╗██╔══██╗╚══██╔══╝    ╚══██╔══╝██╔══██╗██╔══██╗████╗  ██║██╔════╝
	 *	██║     ███████║██████╔╝   ██║          ██║   ██████╔╝███████║██╔██╗ ██║███████╗
	 *	██║     ██╔══██║██╔══██╗   ██║          ██║   ██╔══██╗██╔══██║██║╚██╗██║╚════██║
	 *	╚██████╗██║  ██║██║  ██║   ██║          ██║   ██║  ██║██║  ██║██║ ╚████║███████║
	 *	 ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝          ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚══════╝
	 *
	 *                HERE BEGINS THE CART TRANSACTION TEAM'S SECTION
	 */

	/*
	 * Returns cart from server
	 */
	et.evenue.getCart = function(userContext) {
		var deferred = $.Deferred();

		var svr = {
			url : et.evenue.getCartUrl(userContext) + "?view=detail"
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result) {
			if (p_result.status.cd != 0) {
				deferred.reject();
			} else {
				deferred.resolve(p_result);
			}
		}).fail(function() {
			deferred.reject();
		});

		return deferred.promise();
	};

	/*
	 * Gets the Cart id from the URL or the user context
	 */
	et.evenue.getCartId = function(userContext) {
		var cartId = et.getPageParameter("cartId");
		if (!cartId) {
			return userContext ? userContext.cartId : null;
		}
		return cartId;
	};
	/*
	 * Gets the Organization id from the URL or the user context
	 */
	et.evenue.getOrganizationId = function(userContext) {
		var organizationId = et.getPageParameter("organizationId");
		if (!organizationId) {
			return userContext ? userContext.selectedOrganizationId : null;
		}
		return organizationId;
	};
	/*
	 * Retrieves the API URL for Cart operations (POST, PUT, DELETE, GET)
	 */
	et.evenue.getCartUrl = function(userContext) {
		var cartUrl = et.evenue.constants.URL.cart;
		cartUrl = cartUrl.replace("{organizationId}", et.evenue.getOrganizationId(userContext));
		cartUrl = cartUrl.replace("{cartId}", et.evenue.getCartId(userContext));
		return cartUrl;
	};

	/*
	 * Retrieves the API URL for Cart Line operations (POST, PUT, DELETE, GET)
	 */
	et.evenue.getCartLineUrl = function(userContext, lineId) {
		var cartLineUrl = et.evenue.constants.URL.cartLine;
		cartUrl = cartUrl.replace("{organizationId}", et.evenue.getOrganizationId(userContext));
		cartUrl = cartUrl.replace("{cartId}", et.evenue.getCartId(userContext));
		cartUrl = cartUrl.replace("{lineId}", lineId);
		return cartLineUrl;
	};

	/*
	 * Updates cart information
	 */
	et.evenue.updateCart = function(userContext) {
		var deferred = $.Deferred();

		var svr = {
			url : et.evenue.getCartUrl(userContext),
			type : HTTP_PUT,
			data : {
				cartRequest : {}
			}
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result) {
			var response = p_result.data;
			deferred.resolve(response);
		}).fail(function() {
			deferred.reject();
		});

		return deferred.promise();
	};

	/*
	 * Updates specific lines of the cart
	 */
	et.evenue.updateCartLines = function(userContext, lineUpdateRequests) {
		var deferred = $.Deferred();

		var cartRequest = {
			id : et.evenue.getCartId(userContext),
			lineUpdateRequests : lineUpdateRequests
		};

		var svr = {
			url : et.evenue.getCartUrl(userContext),
			type : et.constants.HTTP_PATCH,
			data : cartRequest
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result) {
			deferred.resolve(p_result);
		}).fail(function() {
			deferred.resolve("error");
		});

		return deferred.promise();
	};

	/*
	 * Function for seats display.
	 */
	et.evenue.createSeatsLine = function(seats) {
		//iterates through the seats
		var seatCounter = 0;
		var seatsString = "";
		$.each(seats, function(index, seat) {
			if (seatCounter > 0)
				seatsString += ",";
			seatsString += seat.seatId;
			seatCounter += 1;
		});
		return seatsString;
	};

	/*
	 * Remove a set of cart lines from specific cart
	 */
	et.evenue.removeCartLines = function(userContext, lineRemoveRequests) {
		var deferred = $.Deferred();

		var cartRequest = {
			id : et.evenue.getCartId(userContext),
			lineDeleteRequests : lineRemoveRequests
		};

		var svr = {
			url : et.evenue.getCartUrl(userContext),
			type : et.constants.HTTP_PATCH,
			data : cartRequest
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result) {
			deferred.resolve(p_result);
		}).fail(function() {
			deferred.resolve("error");
		});

		return deferred.promise();
	};

	/*
	 * Removes entire cart by calling the WS
	 */
	et.evenue.removeEntireCart = function(userContext) {
		var deferred = $.Deferred();

		var svr = {
			url : et.evenue.getCartUrl(userContext),
			type : HTTP_DELETE
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(p_result) {
			//TODO check if userContext is being refreshed
			userContext.cartId = null;
			deferred.resolve(p_result.status.cd);
		}).fail(function() {
			deferred.reject();
		});

		return deferred.promise();
	};

	/**
	 * Remove entire cart info from the DOM
	 */
	et.evenue.clearEntireCart = function(userContext) {
		$.when(et.evenue.removeEntireCart(userContext)).done(function(result) {
			if (result === 0) {
				$(".shopcart-items").html("");
				$(".shopcart-total-row").html("");
				$(".shopcart-summary").remove();
				$("#shopcart-timer").text("");
				$("#shopcart-container-mobile").html("");
				et.displayMessagePanel({
					msgs : [i18n.msg_shopcart_error_delete_no_items],
					type : et.constants.WARNING
				});
			} else {
				et.displayMessagePanel({
					msgs : [i18n.msg_shopcart_error_delete_entire_cart],
					type : et.constants.ERROR
				});
			}
		});
	};

	/*
	 * Get current cart and start timer based on evenue time
	 */
	et.evenue.CartTimer = function(userContext) {
		var timerDiv = $("#cart_text");
		var p_count = 0;
		var p_cartId = 0;
		var m_patronId = -1;

		var cartId = et.evenue.getCartId(userContext);

		// if there is no cart id present, then we do not need to display the timer
		if (!cartId) {
			return;
		}

		//Private function to start the cart timer and keep track of the remaining time
		var startTimer = function() {
			p_count = p_count - 1000;

			var minutes = Math.floor(p_count / 60000);
			var seconds = Math.floor((p_count % 60000) / 1000);
			if (minutes < 10) {
				minutes = "0" + minutes;
			}
			if (seconds < 10) {
				seconds = "0" + seconds;
			}
			if (p_count === 120000) {
				//TODO: Add alert for near timout
			}
			timerDiv.text(minutes + ":" + seconds);
			if (p_count <= 1000) {
				clearInterval(counter);
				//et.evenue.clearEntireCart(userContext);
			}

		};

		var svr = {
			url : et.evenue.getCartUrl(userContext),
			type : HTTP_GET
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(data) {
			if (data.status.cd == 0) {
				var remainingTime = data.data.secondsUntilTimeout * 1000;
				if (remainingTime > 0) {
					p_count = remainingTime;
					p_cartId = cartId;
					counter = setInterval(startTimer, 1000);
					return counter;
				} else {
					//et.evenue.clearEntireCart(userContext);
				}
			}
		}).fail(function(data) {
			et.handleFailure(data);
		});

	};

	/*
	 *   -------------------------------------------------
	 *   | HERE ENDS THE CART TRANSACTION TEAM'S SECTION |
	 *   -------------------------------------------------
	 */

	/*
	 * Returns Account resource settings
	 */
	et.evenue.getAccountSettings = function() {
		var deferred = $.Deferred();
		var svr = {
			type : et.constants.HTTP_GET,
			url : et.evenue.urls.BASE_ACCOUNT_URL + '/settings'
		};

		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});

		return deferred.promise();
	};

	et.evenue.createPatronCodeSource = function(codes){
  	  var customerCodes = [];
	  for (var index = 0; index < codes.length; index++) {
    	  var sourceCode = codes[index];
    	  var newCode = {
    	    key : {
    	     id : sourceCode.key.id
    	    },
    	    name : {}
    	  };
    	  newCode.name[et.locale] = sourceCode.description && sourceCode.description[et.locale] ? sourceCode.description[et.locale]
    	     : sourceCode.name[et.locale];
    	  customerCodes.push(newCode);
	  }
	  return customerCodes;
	};

	/**
	 * Call service to get consumer feedback settings
	 */
	et.evenue.getConsumerFeedbackParams = function() {
		var deferred = $.Deferred();
		var CONSUMER_FEEDBACK_URL = et.rootCtx + "/rs/common/consumerfeedback";
		var svr = {
			type : et.constants.HTTP_GET,
			url : CONSUMER_FEEDBACK_URL
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.data);
			} else {
				deferred.reject();
			}
		}).fail(function(data) {
			et.handleFailure(data);
		});
		return deferred.promise();
	};

	/**
	 * Call service to save activity Consumer Feedback
	 */
	et.evenue.createTouchPoint = function(touchpoint) {
		var deferred = $.Deferred();
		var svr = {
			url : TOUCHPOINT_URL,
			type : "POST",
			data : touchpoint
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
		});
		return deferred.promise();
	};

	et.evenue.services = {
		getAccountPriorityPoints : function(accountId) {
			var deferred = $.Deferred();
			var svr = {
				url : et.rootCtx + "/rs/accounts/" + accountId + "/prioritypoints",
				type : et.constants.HTTP_GET
			};
			var request = et.ajax(svr);
			request.done(function(result) {
				if (et.handleException(result)) {
					deferred.resolve(result);
				} else {
					deferred.reject();
				}
			}).fail(function() {
				et.handleFailure();
			});

			return deferred.promise();
		},
		getPriorityPointsControlSettings : function() {
			var deferred = $.Deferred();
			var svr = {
				url : et.rootCtx + '/rs/priorityPointsConsumerDetails',
				type : et.constants.HTTP_GET
			};
			var request = et.ajax(svr);
			request.done(function(result) {
				if (et.handleException(result)) {
					deferred.resolve(result);
				} else {
					deferred.reject();
				}
			}).fail(function() {
				et.handleFailure();
			});

			return deferred.promise();
		},
		getPriorityPointsProgramById : function(orgId, programId) {
			var deferred = $.Deferred();
			var svr = {
				url : et.rootCtx + '/rs/v1.0/fundraising/' + orgId + '/priorityPointPrograms/' + programId,
				type : et.constants.HTTP_GET
			};
			var request = et.ajax(svr);
			request.done(function(result) {
				if (et.handleException(result)) {
					deferred.resolve(result);
				} else {
					deferred.reject();
				}
			}).fail(function() {
				et.handleFailure();
			});

			return deferred.promise();
		},
		getRankingByAccountIdProgramId : function(accountId, programId) {
        	var deferred = $.Deferred();
        	
        	var svr = {
                url: et.rootCtx + '/rs/rankingprograms/' + programId + '/accounts/' + accountId,
                type: et.constants.HTTP_GET
            };
            var jqxhr = et.ajax(svr);
            jqxhr.done(function(result) {
                if (et.handleException(result)) {
                    deferred.resolve(result.data);
                } else {
                    deferred.reject();
                }
            }).fail(function() {
                et.handleFailure();
            });
            
            return deferred.promise();
        },        
        getSnapshotRankingsByAccountIdProgramId : function(accountId, priorityPointsProgramId) {
        	var deferred = $.Deferred();
        	
        	var svr = {
                url: et.rootCtx + '/rs/rankingprograms/prioritypointsprogram/' + priorityPointsProgramId 
                	+ '/accounts/' + accountId + '/snapshots',
                type: et.constants.HTTP_GET
            };
            var jqxhr = et.ajax(svr);
            jqxhr.done(function(result) {
                if (et.handleException(result)) {
                    deferred.resolve(result.data);
                } else {
                    deferred.reject();
                }
            }).fail(function() {
                et.handleFailure();
            });
            
            return deferred.promise();
        },       
        getPriorityPointsSnapshots : function(orgId, programId, accountId) {
			var deferred, svr, request;
			deferred = $.Deferred();
			svr = {
				url : et.rootCtx + '/rs/v1.0/fundraising/' + orgId + '/priorityPointSnapshot/' + programId + '/' + accountId,
				type : et.constants.HTTP_GET
			};
			request = et.ajax(svr);
			request.done(function(result) {
				if (et.handleException(result)) {
					deferred.resolve(result);
				} else {
					deferred.reject();
				}
			}).fail(function() {
				et.handleFailure();
			});

            return deferred.promise();
        },
        getPac7CartTimer: function(siteId){
            var deferred = $.Deferred();
            var svr = {
                url: '/app/ws/cart/timer?siteId=' + et.siteId,
                type: et.constants.HTTP_GET
            };
            var request = et.ajax(svr);
            request.done(function(result) {
                if (!et.handleException(result)) {
                    deferred.resolve(result);
                } else {
                    deferred.reject();
                }
            }).fail(function() {
                et.handleFailure();
            });

            return deferred.promise();
        },
        clearPac7Cart: function(p_linkId, p_siteId, p_patronId, p_cartId){
		    var defer = $.Deferred();
	        $.ajax(
	        {
	            url : "/app/ws/payments/" + p_patronId + "/cart?siteId=" + p_siteId + "&linkID=" + p_linkId,
	            type : "GET",
	            contentType : "application/json"
	        }).done(function(data)
	        {
	            defer.resolve(data);
			}).fail(function(data) {
				defer.reject(data);
			});

			return defer.promise();
		},
        clearEvenueShoppingCart : function(cartId)
        {
            var deferred = $.Deferred();
            var params =
            {
                "cartNum" : cartId,
                "remove" : "all",
                "after" : "stay",
                "url" : window.location.protocol + "//" + document.domain + "/#MyAccount"
            };
            var svr =
            {
                url : "/cgi-bin/ncommerce3/SEUpdateCart?linkID=" + et.evLinkId,
                data : params,
                type : "GET"
            };

            var jqxhr = et.ajax(svr);
            jqxhr.done(function(data)
            {
                deferred.resolve();
            });

            return deferred.promise();
        },
		getControlSetting : function(channelId, orgId, type) {
			var deferred = $.Deferred();
			var SERVICE_IAC_CONTROL_SETTINGS_URL = et.rootCtx + "/rs/controlsettings/{type}/{channelId}/{orgId}";
			var url = SERVICE_IAC_CONTROL_SETTINGS_URL.replace("{channelId}", channelId).replace("{orgId}", orgId).replace("{type}", type);
			var svr = {
				type : et.constants.HTTP_GET,
				url : url
			};
			var jqxhr = et.ajax(svr);
			jqxhr.done(function(result) {
				if (et.handleException(result)) {
					deferred.resolve(result);
				} else {
					deferred.reject();
				}
			}).fail(function(err) {
				et.handleFailure(err);
				deferred.reject();
			});

			return deferred.promise();
		},
		getSpecialEventsCategories : function(iProfileCd, logon) {
			var deferred = $.Deferred();
			var url = et.rootCtx + "/rs/special_event_category?orgId=" + et.cache.userCtx.selectedOrganizationId + "&detail=true&iprofilecd=" + iProfileCd + "&islogon=" + logon;

      var svr = {
          url: url
      };
      var jqxhr = et.ajax(svr);
      jqxhr.done(function (result) {
          if (et.handleException(result)) {
              deferred.resolve(result);
          } else {
              deferred.reject();
          }
      }).fail(function () {
          et.handleFailure();
          deferred.reject();
      });
      return deferred.promise();
		},
		getDonorInfo: function(donorId){
			var deferred = $.Deferred();
			var donorSvr = {
				url: et.evenue.urls.BASE_DONOR_URL.replace("{accountId}", donorId),
				type : et.constants.HTTP_GET
			}
			et.ajax(donorSvr).done(function(response){
				if (et.handleException(response)) {
					deferred.resolve(response);
				} else {
					deferred.reject(response);
				}
			}).fail(function(e){
				deferred.reject(e);
			});
			return deferred.promise();
		},
		updateDonorCategories: function(orgId, donorId, donorCategories, startDates, endDates){
			var deferred = $.Deferred();
			var svr = {
				url: et.evenue.urls.BASE_DONOR_URL.replace("{accountId}", donorId) + "/CTGS",
				type : et.constants.HTTP_PATCH,
				data : {
					key: {
						dbId: orgId,
						orgId: orgId,
						id: donorId
					},
					categories: donorCategories,
					categoryStartDates: startDates,
					categoryEndDates: endDates
				}
			};
			et.ajax(svr).done(function(response){
				deferred.resolve(response);
			}).fail(function(e){
				deferred.reject(e);
			});
			return deferred.promise();
		}
	};

	et.evenue.showLoadingModal = function(){
        if(loadingModalCounter == 0){
        	loadLoadingModalListeners();
            if(!$("#loading-modal").length>0){
                $("#loading-modal").modal({
                    keyboard:false,
                    backdrop: 'static'
                });
            }
            else{
                loadingModalCounter += 1;
                $('#loading-modal').modal('show');
            }
        }
        else{
            loadingModalCounter += 1;
        }

	};

	et.evenue.hideLoadingModal = function() {
		if (loadingModalCounter > 1) {
			loadingModalCounter -= 1;
		} else {
			loadingModalCounter = 0;
			$('#loading-modal').modal('hide');
		}
	};

	et.showLoadingModal = et.evenue.showLoadingModal;
	et.hideLoadingModal = et.evenue.hideLoadingModal;

    var loadLoadingModalListeners = function(){
    	$("body").on("shown.bs.modal", "#loading-modal", function(e){
    		$(".modal-backdrop").css("background-color", "transparent");
    	});

    	$("body").on("hidden.bs.modal", "#loading-modal", function(e){
    		$(".modal-backdrop").css("background-color", "#000000");
    	});
    };

	/*
	 * get the standard phone format
	 * @returns {Object} predefined phone format
	 */
	et.evenue.getPhoneFormats = function() {
		return [{
			value : 1,
			name : "(nnn) nnn-nnnn"
		}, {
			value : 2,
			name : "nnn-nnn-nnnn"
		}, {
			value : 3,
			name : "nnn/nnn-nnnn"
		}, {
			value : 0,
			name : i18n.do_not_format
		}];
	};

	/*
	 * Formats a 10 digits phone
	 * @returns new phone formatted
	 */
	et.evenue.getNewFormattedPhone = function(numberInput, type) {
		var phoneNumber = numberInput.val();
		if ((/^\d+$/).test(phoneNumber) && phoneNumber.length == 10) {
			var formatSelected = et.getValueFromListByField(et.evenue.getPhoneFormats(), "value", type);
			var newPhoneFormat = formatSelected.name;
			for (var i = 0; i < 10; i++) {
				newPhoneFormat = newPhoneFormat.replace('n', phoneNumber[i]);
			}
			numberInput.val(newPhoneFormat);
		}
	};

	/*
	 * Updates donor related data of account into donor table
	 * @param - donor model
	 * @updateType - INFO, CATGS, MSHPS
	 */
	et.evenue.updateDonor = function(donor, updateType) {
		var deferred = $.Deferred();
		var svr = {
			url : et.evenue.urls.BASE_DONOR_URL.replace("{accountId}", donor.key.id) + '/' + updateType,
			type : "PATCH",
			data : donor
		};
		var jqxhr = et.ajax(svr);
		jqxhr.done(function(result) {
			if (et.handleException(result)) {
				deferred.resolve(result.data);
			} else {
				deferred.reject();
			}
		}).fail(function() {
			et.handleFailure();
			deferred.reject();
		});
		return deferred.promise();
	};

	/**
	 * here we are redefined the logic to redirect the user to the login page
	 * @type {et.op.goToLoginPage|*}
	 */
	et.evenue.goToLoginPage = function() {
		window.location = "SignIn?siteId=" + et.siteId;
	};
	et.goToLoginPage = et.evenue.goToLoginPage;


	et.evenue.goToErrorPage = function (jqXHR) {
	    if((location.pathname.substring(location.pathname.lastIndexOf("/") + 1)!==et.constants.ERROR_PAGE)){
	    if(jqXHR){
		    document.write(JSON.parse(jqXHR.responseText).status.mgs[0]);
		}else{
		    window.location="ErrorPage?errorMsg=" + et.err['20242'];//"This site is not available.  Please contact the box office if you feel you have reached this message in error."
		}
		}
        //window.location = "ErrorPage?errorCd="+JSON.parse(jqXHR.responseText).status.cd +"&errorMsg="+JSON.parse(jqXHR.responseText).status.mgs[0];
    };

	et.goToErrorPage =et.evenue.goToErrorPage;

	et.evenue.loadPac7CartTimerRegion = function(presenter, accountId){
		cartTimer = presenter.regions["CartTimerRegion"];
		cartTimer.go($("#pac7-timer-container"), {
			patronId : accountId,
			siteId : et.siteId
		});
	};

	/*
	 * Gets the email used on the sign in (username email)
	 */
	et.evenue.getSenderEmail = function(account, guest) {
		if (account && account.authInfo) {
			return account.authInfo[et.evenue.constants.AUTH_INFO_SALT + et.cache.userCtx.channelName.toUpperCase()];
		}else if(guest){
			return guest.getAccount().preferredEmail;
		}else if(account.preferredEmail){
			return account.preferredEmail;
		} else {
			return "";
		}
	};

	et.evenue.refreshLoginWidget = function(userContext) {
		if (userContext.logon) {
			$("#hdr_sign_out").show();
			$("#login-widget-logged-container").show();
			$("#login-widget-not-logged-container").hide();
		} else {
			$("#hdr_sign_out").hide();
			$("#login-widget-logged-container").hide();
			$("#login-widget-not-logged-container").show();
		}
	};

	et.evenue.updateWelcomeMessage = function(accountId){
		$.when(et.evenue.getAccountData(accountId)).done(function(accountInfo){
			var primaryContact = et.evenue.getContactById(accountInfo.contacts, accountInfo.primaryContactId);
			var name;
			if (accountInfo.accountType === 'I') {
				name = et.evenue.formatFullName(primaryContact);
			} else {
				name = accountInfo.orgName;
			}
			$("#login-widget-welcome-msg").html(i18n.lbl_login_widget_welcome_msg.replace("{0}", name));
		});
	};
	et.evenue.checkForJumpCookie = function()
	{
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
        	    var key = cookie.split("=");
        	    if(key[0] == " pool.jump.status" && key[1] == "jumped")
        	    {
        	    	    return true;
        	    } 
		}
        return false;
	};
	et.evenue.handleJumpPool = function() {
		
		et.evenue.deleteSessionCookies();
		var svr = {
				url : et.evenue.constants.PATRON_URL + "pacSsoLogout"
			};
			et.ajax(svr).done(function() {
				et.cache.myaccount = null;
				$("#hdr_sign_out").hide();
				$("#hdr_sign_in").show();
				if (et.cache.userContext)
					et.cache.userContext.logon = false;
				var svr = {
						url : "/app/ws/authentication/logout",
						data : {
							"siteId" : et.evLinkId
						},
						type : HTTP_POST
					};
					var jqxhr = et.ajax(svr);
					jqxhr.done(function() {
						
					});
			});
			

	};
	et.evenue.deleteSessionCookies = function() {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if( name == " SESSION_ID" 
            		|| name == " old_session_id" 
            		|| name == " active.pool"
            		|| name == " pool.jump.status")
            	{
            		document.cookie = name + "=;expires=-1;path=/";
            	}
        }
        $.removeCookie("SESSION_ID", { path: '/' });
        $.removeCookie("old_session_id", { path: '/' });
        $.removeCookie("active.pool", { path: '/' });
        $.removeCookie("pool.jump.status", { path: '/' });
    };
    
    Date.prototype.toISOString = Date.prototype.toISODateTimeString;

    //DISC-31
    et.evenue.pac_divloaded = function (r_id, e_id) {
        var ev_deffered = $.Deferred();

        var mRegion = setInterval(function () {
            if ($.trim($("#" + r_id).html()) !== "") {
                if (e_id != undefined) {
                    if ($.trim($("#" + e_id).html()) !== "") {
                        clearInterval(mRegion);
                        ev_deffered.resolve();
                    }
                } else {
                    clearInterval(mRegion);
                    ev_deffered.resolve();
                }
            }
        }, 100);
        return ev_deffered.promise();
    };

	//DISC-519 - GTM module
	var loadExtLib = function (url, callback) {
		var script = document.createElement("script")
		script.type = "text/javascript";
	
		if (script.readyState){  //IE
			script.onreadystatechange = function(){
				if (script.readyState == "loaded" ||
						script.readyState == "complete"){
					script.onreadystatechange = null;
					callback();
				} 
			};
			
	
		} else {  //Others
			script.onload = function(){
				callback();
			};
			script.onerror = function() {
				console.log('FP LOADING ERROR');
				sendToLoggly('Evenue FP module loading error', {logType: "debug", file:'payment.js'});
			}
		}
	
		script.src = url;
		document.getElementsByTagName("head")[0].appendChild(script);
	}
	
	var isDevEnviroment = /^(dev|qfnq|qfns|auto)$/g.test(sessionStorage.getItem("global_env"));

    // DISC-1431 (init global media path)
    et.mediaPath = isDevEnviroment
        ? 'https://s3.us-west-2.amazonaws.com/media.qa.webdev/common/legacy/scripts'
        : 'https://pac-media.s3-us-west-2.amazonaws.com/common/legacy/scripts';
	
	var gtmModulePath = et.mediaPath + "/pixels/gtmDatalayerModule.js";
	// DISC-1905 (apply timestamp)
	loadExtLib(gtmModulePath + '?timestamp='+new Date().valueOf(), function () {});
	


  //ACCT-1351 redirect USI function
	et.evenue.showLoadingPage = function(customContent) {
		var content = (window.i18n && i18n.loading) || "Loading...";
		if (customContent) {
			content = customContent;
		} 
		if ( $("#alert-loading").length ) {
			$("#alert-loading").html(content);
			$("#alert-loading").show();
		}
		
	};

	et.evenue.hideLoadingPage = function() {
		if ( $("#alert-loading").length ) {
			$("#alert-loading").hide();
		}
	};
	
    et.evenue.getUSIFeatureFlags = function(){  
        //start ACCT-1301 Redirect to USI (ACCT-1286 : Ignore redirect on ev14 for EOS server tool) 
        if(window.location.hostname != "ev14.evenue.net" ){
          var usi_siteId = et.getPageParameter(et.evenue.constants.SITE_ID) || et.siteId ;
          var usi_linkId = et.evLinkId || usi_siteId.replace('ev_','');
          var zkpConfigUSI = JSON.parse(sessionStorage.getItem("zkpConfig_"+usi_linkId)) || {};
          et.evenue.showLoadingPage();
          //start ACCT-1285 if sessionStorage is not available call API
          if (!zkpConfigUSI || JSON.stringify(zkpConfigUSI) === '{}') {
            function makeRequest() {
            	var deferred = $.Deferred();
            	var usiAccessible  = false;
            	var usiEmbed = false;
            	var usiFeatureFlags = {"accessible": false, "embed":false};
            	et.ajax({
            	    url: '/pac-api/catalog/evenueconfig',
            	    beforeSend: function(request) {
            	        request.setRequestHeader("siteId", usi_siteId); 
            	    },
            	    timeout:5000,
            	    error:function(data, status, error){ 
        	    		//return false because API is having issues and it timeout or anything else
        	    		et.evenue.hideLoadingPage();
      					deferred.resolve(usiFeatureFlags);  
            	    }
            	}).done(function(result) { 
            		zkpConfigUSI = {};
            		if(typeof result !== "undefined"){
            			sessionStorage.setItem('zkpConfig_'+usi_linkId, JSON.stringify(result));
            			zkpConfigUSI = result || {}; 
            		}
            		usiAccessible  = zkpConfigUSI["feature.evenuenext.usi.accessible"] || false;
            		usiEmbed  = true; //make this varible false to load legacy log in and ignore USI popup - ACCT-1621
            		usiFeatureFlags = {"accessible": usiAccessible, "embed":usiEmbed};
            		et.evenue.hideLoadingPage();
  					deferred.resolve(usiFeatureFlags); 
    			}).fail(function() {
    				//return false because API is having issues and it failed
    				et.evenue.hideLoadingPage();
  					deferred.resolve(usiFeatureFlags); 
    			});
            	
            	return deferred.promise();
            }
             return makeRequest();
          }else{ 
			var usiAccessible  = zkpConfigUSI["feature.evenuenext.usi.accessible"] || false;
    		var usiEmbed  = true; //make this varible false to load legacy log in and ignore USI popup - ACCT-1621
    		var usiFeatureFlags = {"accessible": usiAccessible, "embed":usiEmbed};
			et.evenue.hideLoadingPage();
        	return usiFeatureFlags; 
          }
          
        }else{
			et.evenue.hideLoadingModal();
        	return {"accessible": false, "embed":false};
        } //end ACCT-1301 
    };

	et.evenue.getMyAccountv2FeatureFlagZKP = function(){  
          //start ACCT-1285 if sessionStorage is not available call API
		  var usi_siteId = et.getPageParameter(et.evenue.constants.SITE_ID) || et.siteId ;
          var usi_linkId = et.evLinkId || usi_siteId.replace('ev_','');
          if(sessionStorage.getItem("ev_myaccountv2_"+usi_linkId) === null || sessionStorage.getItem("ev_myaccountv2_"+usi_linkId) == ''){
            function makezkpMyAccountv2Request() {
            	var deferred = $.Deferred();
            	var zkpMyAccountV2Enabled  = false;
            	et.ajax({
            	    url: '/pac-api/accounts/myaccountv2',
            	    beforeSend: function(request) {
            	        request.setRequestHeader("siteid", usi_siteId); 
            	    },
            	    timeout:5000,
            	    error:function(){ 
        	    		//return false because API is having issues and it timeout or anything else
      					deferred.resolve(zkpMyAccountV2Enabled);  
            	    }
            	}).done(function(data) { 
            		zkpMyAccountV2Enabled = false;
            		if(typeof data !== "undefined" && typeof data.result !== "undefined" && typeof data.result.myaccountv2 !== "undefined"){
            			sessionStorage.setItem("ev_myaccountv2_"+usi_linkId,  data.result.myaccountv2);
            			
						window.ev_myaccountv2 = {
							status:data.result.myaccountv2
						}

						zkpMyAccountV2Enabled = data.result.myaccountv2 === "true"; 
            		}
  					deferred.resolve(zkpMyAccountV2Enabled); 
    			}).fail(function() {
  					deferred.resolve(zkpMyAccountV2Enabled); 
    			});
            	
            	return deferred.promise();
            }
             return makezkpMyAccountv2Request();
          }else{ 
			var zkpMyAccountV2Enabled = sessionStorage.getItem("ev_myaccountv2_"+usi_linkId) === "true";
        	return zkpMyAccountV2Enabled; 
          }
    };
    
    et.evenue.USIRedeirect = function(){    
      var isShibbolethEnabled = sessionStorage.getItem("ev_sh_$(usi_linkId)") === "true";
      const usi_queryString = window.location.search;
      const usi_urlParams = new URLSearchParams(usi_queryString);
      var usi_target_url = window.location.pathname+"?"+usi_urlParams;
      var usi_shopperContext = et.getPageParameter("shopperContext") || "";
      var usi_linkSource = et.getPageParameter("shopperContext") || "";
      var usi_s_ctx = '';
	  //ACCT-2061
	  var isManageDonationFlow = usi_target_url.indexOf("ManageMyDonations") != -1;

      if (usi_shopperContext == 'ST') {
        usi_s_ctx = 'ST'
      } else if(usi_linkSource == 'SR') {
        usi_s_ctx = 'SR'
      } else if(isManageDonationFlow){
		usi_s_ctx = 'MD'
     } 
      
      var usi_userFlow = usi_s_ctx ? '&uf='+usi_s_ctx : "";
      var usi_userType =  "";

      if(isShibbolethEnabled){
        if(usi_s_ctx === "ST"){
          usi_userType =  "&ui=SHO";
        }else if(usi_s_ctx === "SR"){
          usi_userType =  "&ui=SHC";
        }else{
          usi_userType =  "&ui=SH";
        }
      }
      var usi_encoded_Url = usi_target_url ? encodeURIComponent(usi_target_url) : "";
      var usi_continueUrl = usi_encoded_Url ? '&continue='+usi_encoded_Url : ""; 
      window.location.href ='/signin?'+usi_userFlow+usi_userType+usi_continueUrl; 
  }; 
     
	//TF-502 : send GA4 shibboleth login event if sessionStorage flag available
	if (window.sessionStorage.getItem('signedInViaShibboleth') !== null && window.sessionStorage.getItem('signedInViaShibboleth') === "true"){
		var urlPathFilter = window.location.pathname;
        var ga4_siteId = et.getPageParameter(et.evenue.constants.SITE_ID) || et.siteId;
        var pageNameGa4 = urlPathFilter.replace("/www/"+ga4_siteId+"/ss/ev/","");
		var gaObj = {
			event: "login",
			destination: pageNameGa4,
			method: "shibboleth",
			status : "success",
			process: "login"
		};

		var getIsStudent = function() {
			var deferred = $.Deferred(); 
			
			var xhr = new XMLHttpRequest();
			xhr.withCredentials = true;
			xhr.open("GET", "/pac-api/auth/authz");
			xhr.onload = function () {
				if (xhr.status === 200) {
					try {
						var response = JSON.parse(xhr.responseText);
						if (response.result) {
							deferred.resolve(response.result.student);
						} else {
							deferred.resolve(undefined);
						}
					} catch (e) {
						deferred.resolve(undefined);
					}
				} else {
					deferred.resolve(undefined);
				}
			};
			xhr.onerror = function() {  
				deferred.resolve(undefined);
			 };
			xhr.send();  
			
			return deferred.promise();
		};

		var pushToDataLayer = function(gaObj){
			window.sessionStorage.removeItem('signedInViaShibboleth');
			et.evenue.waitForGlobalVar(et.evenue.dataLayerLoadedPredicate, 4000).then(function () {
				dataLayer.push(gaObj);
			}).catch(function (e) {
				console.log("error async data_layer_loaded shibboleth login event - common.js"+ e);
				// eventhough data_layer_loaded didn't show up in 5sec  we will still send the event 
				dataLayer.push(gaObj);
			});
		}; 
		
		$.when(getIsStudent()).done(function(student) { 
			if (student !== undefined) {
				gaObj.is_student = student;
			}; 
			pushToDataLayer(gaObj);
		}).fail(function (e) {
			pushToDataLayer(gaObj)
		});
	}
    
})();
